import moment from 'moment'
import { formatDate, formatDateTime } from 'utils/moment'

export enum UserType {
  Admin = 0,
  Researcher = 1,
  User = 2,
}

export const userTypeTexts = {
  [UserType.Admin]: '管理者',
  [UserType.Researcher]: '研究者',
  [UserType.User]: '一般ユーザー',
}

export default class User {
  id: string
  name: string
  email: string
  type: UserType
  isEmailVerified: boolean
  readingWordListId: string | null
  numberOfMovies: number
  numberOfVerifiedMovies: number
  isDeleted: boolean
  createdAt: moment.Moment
  updatedAt: moment.Moment

  get isEditor(): boolean {
    return this.type !== UserType.User
  }

  get userTypeText(): string {
    return userTypeTexts[this.type]
  }

  get createdDate(): string {
    return formatDateTime(this.createdAt)
  }

  get updatedDate(): string {
    return formatDateTime(this.updatedAt)
  }

  constructor(
    id: string,
    name: string,
    email: string,
    type: UserType,
    isEmailVerified: boolean,
    readingWordListId: string | null,
    numberOfMovies: number,
    numberOfVerifiedMovies: number,
    isDeleted: boolean,
    createdAt: moment.Moment,
    updatedAt: moment.Moment,
  ) {
    this.id = id
    this.name = name
    this.email = email
    this.type = type
    this.isEmailVerified = isEmailVerified
    this.readingWordListId = readingWordListId
    this.numberOfMovies = numberOfMovies
    this.numberOfVerifiedMovies = numberOfVerifiedMovies
    this.isDeleted = isDeleted
    this.createdAt = createdAt
    this.updatedAt = updatedAt
  }

  static loadAuth(firebaseUser: firebase.User, data: any): User | null {
    const { uid, email, emailVerified } = firebaseUser
    return this.load(uid, { ...data, email }, emailVerified)
  }

  static load(uid: string, data: any, emailVerified = false): User | null {
    const { name, email, type, readingWordListId, createdAt, updatedAt } = data || {}
    let { numberOfMovies, numberOfVerifiedMovies, isDeleted } = data || {}

    if (!name || typeof name !== 'string') {
      return null
    }

    if (!email || typeof email !== 'string') {
      return null
    }

    if (type == null || typeof type !== 'number' || type < 0 || type > 2) {
      return null
    }

    if (!numberOfMovies || typeof numberOfMovies !== 'number') {
      numberOfMovies = 0
    }

    if (!numberOfVerifiedMovies || typeof numberOfVerifiedMovies !== 'number') {
      numberOfVerifiedMovies = 0
    }

    if (!isDeleted || typeof isDeleted !== 'boolean') {
      isDeleted = false
    }

    if (!createdAt || typeof createdAt !== 'number') {
      return null
    }
    const createdDate = moment.unix(createdAt)

    if (!updatedAt || typeof updatedAt !== 'number') {
      return null
    }
    const updatedDate = moment.unix(updatedAt)

    return new User(
      uid,
      name,
      email,
      type,
      emailVerified,
      readingWordListId,
      numberOfMovies,
      numberOfVerifiedMovies,
      isDeleted,
      createdDate,
      updatedDate,
    )
  }

  get documentData(): object {
    return {
      name: this.name,
      email: this.email,
      type: this.type,
      numberOfMovies: this.numberOfMovies,
      numberOfVerifiedMovies: this.numberOfVerifiedMovies,
      isDeleted: this.isDeleted,
      createdAt: this.createdAt.unix(),
      updatedAt: this.updatedAt.unix(),
    }
  }

  get csvData(): string[] {
    return [
      this.id,
      this.name,
      this.email,
      this.userTypeText,
      `${this.numberOfMovies}`,
      `${this.numberOfVerifiedMovies}`,
      this.createdDate,
    ]
  }

  static get csvHeader(): string[] {
    return ['ID', '名前', 'Email', '権限', '投稿数', '投稿確認数', '登録日時']
  }
}
