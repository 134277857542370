import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { AuthState } from 'redux/modules/auth'
import Landing from './Landing'

export interface LandingStoreStates {
  auth: AuthState
}

function mapStateToProps(appState: AppState): LandingStoreStates {
  return { auth: appState.auth }
}

export default connect(mapStateToProps)(Landing)
