import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  Container,
  CssBaseline,
  Typography,
  WithStyles,
  withStyles,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Toolbar,
  Button,
  Hidden,
  Grid,
} from '@material-ui/core'

import Movie from 'redux/models/movie'
import { VerificationActions, VerificationStoreStates } from '.'
import styles from './styles'
import Modal from './Modal'
import { CSVLink } from 'react-csv'

type OwnProps = RouteComponentProps & WithStyles<typeof styles>

type VerificationProps = OwnProps & VerificationStoreStates & VerificationActions

type VerificationStates = {
  activeMovie: Movie | null
}

class Verification extends React.Component<VerificationProps, VerificationStates> {
  constructor(props: VerificationProps) {
    super(props)
    this.state = {
      activeMovie: null,
    }

    // 未判定の動画リストの読み込み
    props.load()
  }

  onVeify(isValid: boolean) {
    const { activeMovie } = this.state
    if (!activeMovie) return

    this.props.verify({
      item: activeMovie,
      isValid,
    })
  }

  render = (): React.ReactNode => {
    const { activeMovie } = this.state
    const { classes, movies, cancel, clear } = this.props

    const csvData = movies.items.map(item => item.csvData)
    csvData.unshift(Movie.csvHeader)

    const items = movies.items.filter(item => !item.isVerified && !item.isCancelled)

    return (
      <Container>
        <CssBaseline />

        <Paper className={classes.paper}>
          <Toolbar>
            <Grid item xs>
              <Typography className={classes.title} variant="h6">
                動画像リスト
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="contained" component="label" color="primary">
                <CSVLink
                  className={classes.buttonCSV}
                  filename="movies.csv"
                  target="_blank"
                  data={csvData}
                />
                CSV エクスポート
              </Button>
            </Grid>
          </Toolbar>
          <TableContainer>
            <Table size="small" aria-label="word list">
              <TableHead>
                <TableRow>
                  <TableCell>発話ワード</TableCell>
                  <Hidden smDown>
                    <TableCell>ユーザ</TableCell>
                    <TableCell>投稿日時</TableCell>
                  </Hidden>
                  <TableCell align="right">検証</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map(row => (
                  <TableRow key={row.id}>
                    <Hidden mdUp>
                      <TableCell component="td" scope="row">
                        <Typography component="p" className={classes.identifier}>
                          {row.createdDate}
                        </Typography>
                        {row.word}
                      </TableCell>
                    </Hidden>

                    <Hidden smDown>
                      <TableCell component="td" scope="row">
                        {row.word}
                        <Typography component="span" className={classes.identifier}>
                          &emsp;{row.readingWordId}
                        </Typography>
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {row.userName}
                        <Typography component="span" className={classes.identifier}>
                          &emsp;{row.userId}
                        </Typography>
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {row.createdDate}
                      </TableCell>
                    </Hidden>
                    <TableCell
                      component="td"
                      scope="row"
                      align="right"
                      className={classes.verificationCell}>
                      <Button
                        variant="contained"
                        component="label"
                        color="primary"
                        onClick={() => this.setState({ activeMovie: row })}>
                        動画像を検証
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        {activeMovie && (
          <Modal
            item={activeMovie}
            movies={movies}
            onVerify={isValid => this.onVeify(isValid)}
            onCancel={cancel}
            onClose={() => {
              clear()
              this.setState({ activeMovie: null })
            }}
          />
        )}
      </Container>
    )
  }
}

export default withStyles(styles)(Verification)
