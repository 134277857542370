import { createStyles, Theme } from '@material-ui/core'

export default (theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    name: {
      fontWeight: 'bold',
    },
    button: {
      marginLeft: theme.spacing(2),
    },

    title: {
      flex: '1 1 100%',
    },
    verificationCell: {
      width: 150,
    },
    identifier: {
      fontSize: 10,
      color: theme.palette.grey[500],
    },
    buttonCSV: {
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
  })
