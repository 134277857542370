import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  Button,
  Container,
  CssBaseline,
  TextField,
  Typography,
  WithStyles,
  withStyles,
  Paper,
  CircularProgress,
} from '@material-ui/core'

import { ChangePasswordActions, ChangePasswordStoreStates } from '.'
import styles from './styles'

type OwnProps = RouteComponentProps & WithStyles<typeof styles>

type ChangePasswordProps = OwnProps & ChangePasswordStoreStates & ChangePasswordActions

interface ChangePasswordStates {
  currentPassword: string
  newPassword: string
  confirmationPassword: string
  // validation
  isEmpty: boolean
  isMatchPassword: boolean
}

type ChangePasswordItem = Pick<
  ChangePasswordStates,
  'currentPassword' | 'newPassword' | 'confirmationPassword'
>

class ChangePassword extends React.Component<ChangePasswordProps, ChangePasswordStates> {
  constructor(props: ChangePasswordProps) {
    super(props)
    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmationPassword: '',
      isEmpty: true,
      isMatchPassword: true,
    }
  }

  componentDidUpdate(prevProps: ChangePasswordProps) {
    const { history, auth } = this.props
    const { changingPassword, changePasswordError } = auth

    if (!prevProps.auth.changingPassword || changingPassword || changePasswordError) return

    // パスワード変更完了
    alert('パスワードの変更が完了しました')
    history.goBack()
  }

  onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const { newPassword, currentPassword, isEmpty, isMatchPassword } = this.state

    if (isEmpty || !isMatchPassword) return

    this.props.changePassword({ newPassword, currentPassword })
  }

  onChangeValue(key: keyof ChangePasswordItem, value: string) {
    const state = { ...this.state }
    state[key] = value
    state.isEmpty = Object.values(state).includes('')
    state.isMatchPassword = state.newPassword === state.confirmationPassword
    this.setState(state)
  }

  render = (): React.ReactNode => {
    const { classes, auth } = this.props
    const { changingPassword, changePasswordError, user } = auth
    const {
      newPassword,
      currentPassword,
      confirmationPassword,
      isEmpty,
      isMatchPassword,
    } = this.state

    if (!user) return <></>

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Paper className={classes.paper}>
          <Typography align="center" color="textPrimary" variant="h5">
            パスワードの変更
          </Typography>

          {changePasswordError && (
            <Typography align="center" color="error" component="h1" variant="h6">
              {changePasswordError}
            </Typography>
          )}

          <form className={classes.form} noValidate onSubmit={e => this.onSubmit(e)}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="現在のパスワード"
              autoComplete="password"
              type="password"
              autoFocus
              value={currentPassword}
              onChange={({ target }) => this.onChangeValue('currentPassword', target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="新しいパスワード"
              type="password"
              value={newPassword}
              onChange={({ target }) => this.onChangeValue('newPassword', target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="新しいパスワード（確認用）"
              type="password"
              value={confirmationPassword}
              error={!isMatchPassword}
              helperText={!isMatchPassword && 'パスワードが一致しません'}
              onChange={({ target }) => this.onChangeValue('confirmationPassword', target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isEmpty || changingPassword}>
              {changingPassword ? <CircularProgress size={20} /> : '変更する'}
            </Button>
          </form>
        </Paper>
      </Container>
    )
  }
}

export default withStyles(styles)(ChangePassword)
