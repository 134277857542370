import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { actions as authActions, AuthState, ChangeEmailPayload } from 'redux/modules/auth'
import ChangeEmail from './ChangeEmail'

export interface ChangeEmailActions {
  changeEmail: (payload: ChangeEmailPayload) => Action<ChangeEmailPayload>
}

export interface ChangeEmailStoreStates {
  auth: AuthState
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): ChangeEmailActions {
  return {
    changeEmail: payload => dispatch(authActions.changeEmail(payload)),
  }
}

function mapStateToProps(appState: AppState): ChangeEmailStoreStates {
  return { auth: appState.auth }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeEmail)
