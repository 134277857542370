import contributionSample from 'resources/contribution_sample.jpg'
import demo1 from 'resources/demonstration/demo_1.png'
import demo2 from 'resources/demonstration/demo_2.png'
import demo3 from 'resources/demonstration/demo_3.png'
import demo4 from 'resources/demonstration/demo_4.png'
import demo5 from 'resources/demonstration/demo_5.png'
import demo6 from 'resources/demonstration/demo_6.png'
import demo7 from 'resources/demonstration/demo_7.png'
import demo8 from 'resources/demonstration/demo_8.png'

export const demonstrationImages = [demo1, demo2, demo3, demo4, demo5, demo6, demo7, demo8]

export default {
  contributionSample,
}
