import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  Container,
  CssBaseline,
  WithStyles,
  withStyles,
  Paper,
  Toolbar,
  Button,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  IconButton,
} from '@material-ui/core'
import { Edit as EditIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons'

import { ListActions, ListStoreStates } from '.'
import styles from './styles'

type OwnProps = WithStyles<typeof styles>

type ListProps = OwnProps & ListStoreStates & ListActions

type ListStates = {
  expandedId: string | null
}

class List extends React.Component<ListProps, ListStates> {
  constructor(props: ListProps) {
    super(props)

    this.state = {
      expandedId: null,
    }

    // お知らせ一覧の読み込み
    props.load()
  }

  onChangeExpansionPanel(notificationId: string) {
    const { expandedId } = this.state
    this.setState({
      expandedId: notificationId === expandedId ? null : notificationId,
    })
  }

  render = (): React.ReactNode => {
    const { expandedId } = this.state
    const { classes, auth, notifications } = this.props
    const isEditor = auth.user?.isEditor || false

    return (
      <Container>
        <CssBaseline />

        <Paper className={classes.paper}>
          <ul className={classes.list}>
            <li>
              クチヨミ
              <Typography variant="body2" component="p" className={classes.listItem}>
                九州工業大学 齊藤剛史研究室が提供する機械読唇技術に関するサービスです。
              </Typography>
            </li>
            <li>
              読唇デモ（25単語）
              <Typography variant="body2" component="p" className={classes.listItem}>
                日本語25単語を対象とした読唇技術のデモを体験できます。
                デモは機械読唇技術の研究成果を紹介することを目的としています。
              </Typography>
            </li>
            <li>
              発話シーン投稿
              <Typography variant="body2" component="p" className={classes.listItem}>
                認識精度を向上するために発話シーン投稿の協力をお願いしています。
              </Typography>
            </li>
          </ul>
        </Paper>

        <Paper className={classes.paper}>
          {isEditor && (
            <Toolbar>
              <Grid item xs>
                <Typography className={classes.paperTitle}>お知らせ</Typography>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                className={classes.addButton}
                component={RouterLink}
                to="/notifications/new">
                新規追加
              </Button>
            </Toolbar>
          )}

          {notifications.items.length === 0 && (
            <Typography variant="h6">現在お知らせはありません</Typography>
          )}

          {notifications.items.map(row => (
            <ExpansionPanel
              key={row.id}
              expanded={expandedId === row.id}
              onChange={() => this.onChangeExpansionPanel(row.id)}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container alignItems="center">
                  {isEditor && (
                    <RouterLink to={`/notifications/${row.id}/edit`}>
                      <IconButton aria-label="edit" className={classes.editButton}>
                        <EditIcon />
                      </IconButton>
                    </RouterLink>
                  )}
                  <Typography className={classes.date}>{row.updatedDate}</Typography>
                  <Typography className={classes.title}>{row.title}</Typography>
                </Grid>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>{row.content}</Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        </Paper>
      </Container>
    )
  }
}

export default withStyles(styles)(List)
