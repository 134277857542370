import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  Container,
  CssBaseline,
  Typography,
  WithStyles,
  withStyles,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Toolbar,
  Button,
  Grid,
  Tooltip,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core'
import { CSVLink } from 'react-csv'

import { WordsActions, WordsStoreStates } from '.'
import styles from './styles'

type OwnProps = WithStyles<typeof styles> & RouteComponentProps<{ listId: string }>

type WordsProps = OwnProps & WordsStoreStates & WordsActions

type WordsStates = {
  isOpenDefaultDialog: boolean
  isOpenDemoDialog: boolean
}

class Words extends React.Component<WordsProps, WordsStates> {
  constructor(props: WordsProps) {
    super(props)

    this.state = {
      isOpenDefaultDialog: false,
      isOpenDemoDialog: false,
    }

    // 発話ワードリストの読み込み
    const { loadWords, match } = props
    loadWords(match.params.listId)
  }

  onInput(file: File | null | undefined) {
    if (!file) return

    const { readingWords, updateList } = this.props
    const { list } = readingWords.words || {}
    if (!list) return

    updateList({ list, file })
  }

  closeDialog() {
    this.setState({
      isOpenDefaultDialog: false,
      isOpenDemoDialog: false,
    })
  }

  render = (): React.ReactNode => {
    const { isOpenDefaultDialog, isOpenDemoDialog } = this.state
    const { classes, readingWords, setDefaultList, setDemoList } = this.props
    const { words, update } = readingWords

    if (!words) return <></>

    const { loading, isSucceeded, items, list } = words
    const csvData = items.map(item => [item.id, item.text, item.weight])

    if (loading) return <></>

    if (!isSucceeded || !list)
      return <Typography color="secondary">エラーが発生しました</Typography>

    return (
      <Container>
        <CssBaseline />

        <Paper className={classes.paper}>
          <Toolbar>
            <Grid item xs>
              <Typography className={classes.title} variant="h6">
                {list.title}
                <Button
                  size="small"
                  component="label"
                  disabled={list.isDefault}
                  classes={{ disabled: classes.buttonDefault }}
                  className={classes.button}
                  onClick={() => !list.isDefault && this.setState({ isOpenDefaultDialog: true })}>
                  デフォルト
                </Button>
                <Button
                  size="small"
                  component="label"
                  disabled={list.canPredict}
                  classes={{ disabled: classes.buttonDemo }}
                  className={classes.button}
                  onClick={() => !list.canPredict && this.setState({ isOpenDemoDialog: true })}>
                  デモ
                </Button>
              </Typography>
            </Grid>
            {update.isSucceeded && (
              <Typography color="primary" variant="subtitle1">
                リストを更新しました
              </Typography>
            )}
            {update.isSucceeded === false && (
              <Typography color="secondary" variant="subtitle1">
                エラーが発生しました
              </Typography>
            )}
            <Tooltip title="テキストファイルから発話ワードを更新します">
              <Button
                variant="contained"
                component="label"
                color="secondary"
                disabled={update.processing}
                className={classes.buttonUpdate}>
                {update.processing ? <CircularProgress size={20} /> : '内容を書き換える'}
                <input
                  type="file"
                  accept=".csv"
                  style={{ display: 'none' }}
                  onChange={e => this.onInput(e.target.files?.item(0))}
                />
              </Button>
            </Tooltip>
          </Toolbar>
          <TableContainer>
            <Table size="small" aria-label="word list">
              <TableHead>
                <TableRow>
                  <TableCell>言葉</TableCell>
                  <TableCell align="right">重み&nbsp;(0.0~1.0)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items
                  .filter(item => item.isEnabled)
                  .map(row => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.text}
                      </TableCell>
                      <TableCell align="right">{row.weight}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Toolbar>
            <Grid item xs />
            <Grid item>
              <Button variant="contained" component="label" color="primary">
                <CSVLink
                  className={classes.buttonCSV}
                  filename={`${list.title}.csv`}
                  target="_blank"
                  data={csvData}
                />
                CSV エクスポート
              </Button>
            </Grid>
          </Toolbar>
        </Paper>

        <Dialog
          open={isOpenDefaultDialog || isOpenDemoDialog}
          onClose={() => this.closeDialog()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{'確認'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              このリストを
              {isOpenDefaultDialog ? 'デフォルトに設定しますか？' : 'デモで使用しますか？'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.closeDialog()} color="primary">
              キャンセル
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                this.closeDialog()
                isOpenDefaultDialog ? setDefaultList(list) : setDemoList(list)
              }}>
              はい
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    )
  }
}

export default withStyles(styles)(Words)
