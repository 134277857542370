import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import Movie from 'redux/models/movie'
import ReadingWord from 'redux/models/readingWord'
import ReadingWordList from 'redux/models/readingWordList'
import ReadingResult from 'redux/models/ReadingResult'

// Actions
export const LOAD = 'lip-reading/movies/LOAD'
export const SYNC_MOVIES = 'lip-reading/movies/SYNC_MOVIES'
export const CLEAR_VERIFYING_ITEM = 'lip-reading/movies/CLEAR_VERIFYING_ITEM'
export const VERIFY_ITEM = 'lip-reading/movies/VERIFY_ITEM'
export const VERIFY_ITEM_SUCCESS = 'lip-reading/movies/VERIFY_ITEM_SUCCESS'
export const VERIFY_ITEM_FAILURE = 'lip-reading/movies/VERIFY_ITEM_FAILURE'
export const CANCEL_VERIFIED_ITEM = 'lip-reading/movies/CANCEL_VERIFIED_ITEM'
export const CANCEL_VERIFIED_ITEM_SUCCESS = 'lip-reading/movies/CANCEL_VERIFIED_ITEM_SUCCESS'
export const CANCEL_VERIFIED_ITEM_FAILURE = 'lip-reading/movies/CANCEL_VERIFIED_ITEM_FAILURE'
export const CONTRIBUTE_MOVIE = 'lip-reading/movies/CONTRIBUTE_MOVIE'
export const CONTRIBUTE_MOVIE_SUCCESS = 'lip-reading/movies/CONTRIBUTE_MOVIE_SUCCESS'
export const CONTRIBUTE_MOVIE_FAILURE = 'lip-reading/movies/CONTRIBUTE_MOVIE_FAILURE'
export const CLEAR_CONTRIBUTION = 'lip-reading/movies/CLEAR_CONTRIBUTION'
export const UPLOAD_DEMONSTRATION_MOVIE = 'lip-reading/movies/UPLOAD_DEMONSTRATION_MOVIE'
export const UPLOAD_DEMONSTRATION_MOVIE_COMPLETION =
  'lip-reading/movies/UPLOAD_DEMONSTRATION_MOVIE_COMPLETION'
export const CANCEL_UPLOADED_MOVIE = 'lip-reading/movies/CANCEL_UPLOADED_MOVIE'
export const CANCEL_UPLOADED_MOVIE_COMPLETION =
  'lip-reading/movies/CANCEL_UPLOADED_MOVIE_COMPLETION'

// Action Creators
const actionCreator = actionCreatorFactory()

export type VerifyPayload = {
  item: Movie
  isValid: boolean
}

export type ContributePayload = {
  list: ReadingWordList
  word: ReadingWord
  file: File | Blob
}

export const actions = {
  load: actionCreator(LOAD),
  sync: actionCreator<Movie[]>(SYNC_MOVIES),
  clearVerifying: actionCreator(CLEAR_VERIFYING_ITEM),
  verify: actionCreator<VerifyPayload>(VERIFY_ITEM),
  succeededToVerify: actionCreator(VERIFY_ITEM_SUCCESS),
  failedToVerify: actionCreator(VERIFY_ITEM_FAILURE),
  cancelToVerify: actionCreator(CANCEL_VERIFIED_ITEM),
  succeededToCancel: actionCreator(CANCEL_VERIFIED_ITEM_SUCCESS),
  failedToCancel: actionCreator(CANCEL_VERIFIED_ITEM_FAILURE),
  contribute: actionCreator<ContributePayload>(CONTRIBUTE_MOVIE),
  succeededToContribute: actionCreator(CONTRIBUTE_MOVIE_SUCCESS),
  failedToContribute: actionCreator<string>(CONTRIBUTE_MOVIE_FAILURE),
  clearContribution: actionCreator(CLEAR_CONTRIBUTION),
  uploadDemonstrationMovie: actionCreator<File | Blob>(UPLOAD_DEMONSTRATION_MOVIE),
  completedToUploadDemonstrationMovie: actionCreator<ReadingResult>(
    UPLOAD_DEMONSTRATION_MOVIE_COMPLETION,
  ),
  cancelUploadedMovie: actionCreator<Movie>(CANCEL_UPLOADED_MOVIE),
  completedToCancelUploadedMovie: actionCreator<boolean>(CANCEL_UPLOADED_MOVIE_COMPLETION),
}

// Reducer
export interface MoviesState {
  loading: boolean
  loaded: boolean
  items: Movie[]
  verification: {
    item: Movie | null
    verifing: boolean
    verificationError: boolean
    canceling: boolean
    cancelError: boolean
  }
  contribution: {
    word: ReadingWord | null
    uploading: boolean
    error: string | null
  }
  demonstration: {
    uploading: boolean
    result: ReadingResult | null
  }
  cancelMovie: {
    canceling: boolean
    error: boolean
  }
}

const initialState: () => MoviesState = () => ({
  loading: false,
  loaded: false,
  items: [],
  verification: {
    item: null,
    verifing: false,
    verificationError: false,
    canceling: false,
    cancelError: false,
  },
  contribution: {
    word: null,
    uploading: false,
    error: null,
  },
  demonstration: {
    uploading: false,
    result: null,
  },
  cancelMovie: {
    canceling: false,
    error: false,
  },
})

export default reducerWithInitialState(initialState())
  .case(actions.load, state => ({
    ...state,
    loading: true,
  }))
  .case(actions.sync, (state, items) => ({
    ...state,
    loading: false,
    loaded: true,
    items,
  }))
  .case(actions.clearVerifying, state => ({
    ...state,
    verification: {
      item: null,
      verifing: false,
      verificationError: false,
      canceling: false,
      cancelError: false,
    },
  }))
  .case(actions.verify, (state, payload) => ({
    ...state,
    verification: {
      item: payload.item,
      verifing: true,
      verificationError: false,
      canceling: false,
      cancelError: false,
    },
  }))
  .case(actions.succeededToVerify, state => {
    const current = state.verification
    return {
      ...state,
      verification: {
        ...current,
        verifing: false,
        verificationError: false,
      },
    }
  })
  .case(actions.failedToVerify, state => {
    const current = state.verification
    return {
      ...state,
      verification: {
        ...current,
        verifing: false,
        verificationError: true,
      },
    }
  })
  .case(actions.cancelToVerify, state => {
    const current = state.verification
    return {
      ...state,
      verification: {
        ...current,
        canceling: true,
        cancelError: false,
      },
    }
  })
  .case(actions.succeededToCancel, state => {
    const current = state.verification
    return {
      ...state,
      verification: {
        ...current,
        item: null,
        canceling: false,
        cancelError: false,
      },
    }
  })
  .case(actions.failedToCancel, state => {
    const current = state.verification
    return {
      ...state,
      verification: {
        ...current,
        canceling: false,
        cancelError: true,
      },
    }
  })
  .case(actions.contribute, (state, payload) => ({
    ...state,
    contribution: {
      word: payload.word,
      uploading: true,
      error: null,
    },
  }))
  .case(actions.succeededToContribute, state => ({
    ...state,
    contribution: {
      ...state.contribution,
      uploading: false,
      error: null,
    },
  }))
  .case(actions.failedToContribute, (state, error) => ({
    ...state,
    contribution: {
      ...state.contribution,
      uploading: false,
      error,
    },
  }))
  .case(actions.clearContribution, state => ({
    ...state,
    contribution: {
      word: null,
      uploading: false,
      error: null,
    },
  }))
  .case(actions.uploadDemonstrationMovie, state => ({
    ...state,
    demonstration: {
      uploading: true,
      result: null,
    },
  }))
  .case(actions.completedToUploadDemonstrationMovie, (state, result) => ({
    ...state,
    demonstration: {
      uploading: false,
      result,
    },
  }))
  .case(actions.cancelUploadedMovie, state => ({
    ...state,
    cancelMovie: {
      canceling: true,
      error: false,
    },
  }))
  .case(actions.completedToCancelUploadedMovie, (state, error) => ({
    ...state,
    cancelMovie: {
      canceling: false,
      error,
    },
  }))
