import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { AuthState } from 'redux/modules/auth'
import { actions as notificationsActions, NotificationsState } from 'redux/modules/notifications'
import List from './List'

export interface ListActions {
  load: () => Action<void>
}

export interface ListStoreStates {
  auth: AuthState
  notifications: NotificationsState
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): ListActions {
  return {
    load: () => dispatch(notificationsActions.load()),
  }
}

function mapStateToProps(appState: AppState): ListStoreStates {
  return { auth: appState.auth, notifications: appState.notifications }
}

export default connect(mapStateToProps, mapDispatchToProps)(List)
