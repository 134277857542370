import { createStyles, Theme } from '@material-ui/core'

export default (theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  })
