import { createStyles, Theme } from '@material-ui/core'

export default (theme: Theme) =>
  createStyles({
    container: {
      width: '70%',
      minHeight: '100%',
      margin: '0 auto',
      [theme.breakpoints.down('sm')]: {
        width: '90%',
      },
    },
    title: {
      marginBottom: theme.spacing(3),
    },
    list: {
      fontSize: theme.typography.h6.fontSize,
      fontWeight: 'bold',
    },
    listItem: {
      fontSize: theme.typography.h6.fontSize,
      margin: theme.spacing(1, 0, 3),
    },
  })
