import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import Screen from './DemonstrationManual'

export interface DemonstrationManualActions {
  //
}

export interface DemonstrationManualStoreStates {
  //
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): DemonstrationManualActions {
  return {
    //
  }
}

function mapStateToProps(appState: AppState): DemonstrationManualStoreStates {
  return {
    //
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen)
