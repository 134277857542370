import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { actions as authActions, AuthState, SignUpPayload } from 'redux/modules/auth'
import Signup from './Signup'

export interface SignupActions {
  signUp: (payload: SignUpPayload) => Action<SignUpPayload>
}

export interface SignupStoreStates {
  auth: AuthState
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): SignupActions {
  return {
    signUp: (payload): Action<SignUpPayload> => dispatch(authActions.signUp(payload)),
  }
}

function mapStateToProps(appState: AppState): SignupStoreStates {
  return { auth: appState.auth }
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup)
