import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  Button,
  Container,
  CssBaseline,
  Typography,
  WithStyles,
  withStyles,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormControl,
  Select,
  CircularProgress,
} from '@material-ui/core'

import { DetailActions, DetailStoreStates } from '.'
import styles from './styles'
import { Email as EmailIcon, VideoLibrary as VideoLibraryIcon } from '@material-ui/icons'
import { UserType, userTypeTexts } from 'redux/models/user'

type OwnProps = RouteComponentProps<{ userId: string }> & WithStyles<typeof styles>

type DetailProps = OwnProps & DetailStoreStates & DetailActions

type DetailStates = {}

class Detail extends React.Component<DetailProps, DetailStates> {
  constructor(props: DetailProps) {
    super(props)
    this.state = {}

    // ユーザリストの読み込み
    if (!props.users.loaded) props.loadUsers()

    // 発話リストの読み込み
    if (!props.readingWords.list.loaded) props.loadWordLists()
  }

  componentDidUpdate = (prevProps: DetailProps) => {
    const prevData = prevProps.users.deletion
    const data = this.props.users.deletion
    if (!prevData.deleting || data.deleting) return

    if (data.isSucceeded) {
      this.props.history.push('/users')
    } else {
      window.alert('エラーが発生したため削除できませんでした')
    }
  }

  onDeleteUser = () => {
    const { match, users, deleteUser } = this.props
    const user = users.items.find((item) => item.id === match.params.userId)
    if (!user) return
    if (window.confirm('このユーザーを削除してもよろしいですか?')) deleteUser(user)
  }

  render = (): React.ReactNode => {
    const { match, classes, readingWords, users, changeUserType, changeWordList } = this.props

    if (users.loading || !users.loaded) return <></>

    const user = users.items.find((item) => item.id === match.params.userId)

    if (!user) return <Typography>ユーザが存在しません</Typography>

    return (
      <Container>
        <CssBaseline />

        <Paper className={classes.paper}>
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid container item alignItems="baseline" spacing={2}>
              <Grid item>
                <Typography className={classes.name} variant="h3">
                  {user.name}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.identifier} component="span" variant="h6">
                  {user.id}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <EmailIcon />
                  </ListItemIcon>
                  <ListItemText primary={user.email} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <VideoLibraryIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      user.numberOfMovies > 0
                        ? `${user.numberOfMovies}本（検証済：${user.numberOfVerifiedMovies}本）`
                        : `${user.numberOfMovies}本`
                    }
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Paper>

        <Paper className={classes.paper}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item md sm={12} xs={12}>
              <Typography variant="h6">ユーザの権限</Typography>
            </Grid>
            {users.userTypeChanging && (
              <Grid item>
                <CircularProgress size={20} />
              </Grid>
            )}
            <Grid container item md={4} sm={12} xs={12} justify="flex-end">
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  native
                  value={user.type}
                  disabled={users.userTypeChanging}
                  onChange={(e) =>
                    changeUserType({ user, type: parseInt(e.target.value as string) })
                  }>
                  <option value={UserType.Admin}>{userTypeTexts[UserType.Admin]}</option>
                  <option value={UserType.Researcher}>{userTypeTexts[UserType.Researcher]}</option>
                  <option value={UserType.User}>{userTypeTexts[UserType.User]}</option>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>

        {readingWords.list.loaded && (
          <Paper className={classes.paper}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item md sm={12} xs={12}>
                <Typography variant="h6">使用するリスト</Typography>
              </Grid>
              {users.wordListChanging && (
                <Grid item>
                  <CircularProgress size={20} />
                </Grid>
              )}
              <Grid container item md={4} sm={12} xs={12} justify="flex-end">
                <FormControl variant="outlined" className={classes.formControl}>
                  <Select
                    native
                    value={user.readingWordListId || ''}
                    disabled={users.wordListChanging}
                    onChange={(e) => changeWordList({ user, listId: e.target.value as string })}>
                    <option value="">デフォルト</option>
                    {readingWords.list.items.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.title}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        )}

        <Paper className={classes.paper}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item md sm={12} xs={12}>
              <Typography variant="h6">ユーザーの削除</Typography>
            </Grid>
            {users.deletion.deleting && (
              <Grid item>
                <CircularProgress size={20} />
              </Grid>
            )}
            <Grid container item md={2} sm={12} xs={12} justify="flex-end">
              <Button
                size="large"
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={() => this.onDeleteUser()}>
                削除
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    )
  }
}

export default withStyles(styles)(Detail)
