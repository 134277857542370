import React from 'react'
import {
  WithStyles,
  withStyles,
  Button,
  Theme,
  createStyles,
  Typography,
  Grid,
  Paper,
  Modal,
} from '@material-ui/core'
import Movie from 'redux/models/movie'

const styles = (theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'scroll',
    },
    paper: {
      padding: theme.spacing(4),
    },
    video: {},
    success: {
      color: theme.palette.success.main,
    },
  })

type VerificationModalProps = WithStyles<typeof styles> & {
  item: Movie
  onClose: () => void
}

function VerificationModal(props: VerificationModalProps) {
  const { classes, item, onClose } = props

  return (
    <Modal aria-labelledby="simple-modal-title" className={classes.modal} open onClose={onClose}>
      <Paper className={classes.paper}>
        <Grid container direction="column" alignItems="center" spacing={3}>
          <Grid item>
            <Typography variant="h4" id="simple-modal-title">
              {item.word}
            </Typography>
          </Grid>
          <Grid item>
            <video
              width={Math.min(window.screen.width * 0.7, 500)}
              height={Math.min(window.screen.height * 0.7, 500)}
              className={classes.video}
              src={item.fileURL}
              controls
            />
          </Grid>
          <Grid item>
            <a href={item.browserURL} target="blank">
              動画が見れない時はこちら
            </a>
          </Grid>
          <Grid item>
            <Button variant="outlined" component="label" onClick={onClose}>
              閉じる
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  )
}

export default withStyles(styles)(VerificationModal)
