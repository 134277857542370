import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { actions as authActions, AuthState, ChangePasswordPayload } from 'redux/modules/auth'
import ChangePassword from './ChangePassword'

export interface ChangePasswordActions {
  changePassword: (payload: ChangePasswordPayload) => Action<ChangePasswordPayload>
}

export interface ChangePasswordStoreStates {
  auth: AuthState
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): ChangePasswordActions {
  return {
    changePassword: payload => dispatch(authActions.changePassword(payload)),
  }
}

function mapStateToProps(appState: AppState): ChangePasswordStoreStates {
  return { auth: appState.auth }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
