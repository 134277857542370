import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  Container,
  CssBaseline,
  Typography,
  WithStyles,
  withStyles,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Toolbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Hidden,
  TableBody,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
} from '@material-ui/core'
import {
  Email as EmailIcon,
  VideoLibrary as VideoLibraryIcon,
  VpnKey as VpnKeyIcon,
} from '@material-ui/icons'

import Movie from 'redux/models/movie'
import Modal from './Modal'
import { InfoStoreStates, InfoActions } from '.'
import styles from './styles'

type InfoStates = {
  showDeleteAccountModal: boolean
  password: string
  activeMovie: Movie | null
}

type OwnProps = RouteComponentProps & WithStyles<typeof styles>

type InfoProps = OwnProps & InfoStoreStates & InfoActions

class Info extends React.Component<InfoProps, InfoStates> {
  constructor(props: InfoProps) {
    super(props)

    this.state = {
      showDeleteAccountModal: false,
      password: '',
      activeMovie: null,
    }

    props.load()
  }

  componentDidUpdate = (prevProps: InfoProps) => {
    const prevData = prevProps.auth
    const data = this.props.auth
    if (!prevData.accountDeleting || data.accountDeleting) return

    if (data.deleteAccountError) {
      window.alert(data.deleteAccountError)
    }
  }

  onDeleteMovie = (movie: Movie) => {
    if (!window.confirm('この動画を削除してもよろしいですか?')) return
    this.props.cancelMovie(movie)
  }

  onModalClose = () => {
    this.setState({
      showDeleteAccountModal: false,
      password: '',
    })
  }

  onDeleteAccount = () => {
    const { password } = this.state
    const { deleteAccount } = this.props
    if (!window.confirm('アカウントを削除してもよろしいですか?')) return

    this.onModalClose()
    deleteAccount(password)
  }

  render = (): React.ReactNode => {
    const { showDeleteAccountModal, password, activeMovie } = this.state
    const { history, classes, auth, movies } = this.props
    const { user } = auth

    if (!user) return <></>

    const items = movies.items.filter(
      (item) => item.userId === user.id && !item.isVerified && !item.isCancelled,
    )

    return (
      <Container>
        <CssBaseline />

        <Paper className={classes.paper}>
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid container item alignItems="baseline" spacing={2}>
              <Grid item>
                <Typography className={classes.name} variant="h3">
                  {user.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <EmailIcon />
                  </ListItemIcon>
                  <ListItemText primary={user.email} />
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    onClick={() => history.push('/mypage/changeEmail')}>
                    変更
                  </Button>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <VpnKeyIcon />
                  </ListItemIcon>
                  <ListItemText primary="＊＊＊＊＊＊＊＊＊＊＊＊＊" />
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    onClick={() => history.push('/mypage/changePassword')}>
                    変更
                  </Button>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <VideoLibraryIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      user.numberOfMovies > 0
                        ? `${user.numberOfMovies}本（検証済：${user.numberOfVerifiedMovies}本）`
                        : `${user.numberOfMovies}本`
                    }
                  />
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Paper>

        <Paper className={classes.paper}>
          <Toolbar>
            <Grid item xs>
              <Typography className={classes.title} variant="h6">
                発話シーン投稿履歴
              </Typography>
            </Grid>
          </Toolbar>
          <TableContainer>
            <Table size="small" aria-label="word list">
              <TableHead>
                <TableRow>
                  <TableCell>発話ワード</TableCell>
                  <Hidden smDown>
                    <TableCell>投稿日時</TableCell>
                    <TableCell>状態</TableCell>
                    <TableCell>視聴</TableCell>
                    <TableCell>削除</TableCell>
                  </Hidden>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((row) => (
                  <TableRow key={row.id}>
                    <Hidden mdUp>
                      <TableCell component="td" scope="row">
                        <Typography component="p" className={classes.identifier}>
                          {row.createdDate}
                        </Typography>
                        {row.word}
                        <Typography component="p" className={classes.identifier}>
                          <Button
                            variant="contained"
                            component="label"
                            color="primary"
                            onClick={() => this.setState({ activeMovie: row })}>
                            動画を見る
                          </Button>
                        </Typography>
                      </TableCell>
                    </Hidden>

                    <Hidden smDown>
                      <TableCell component="td" scope="row">
                        {row.word}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {row.createdDate}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {row.status}
                      </TableCell>
                      <TableCell component="td" scope="row" className={classes.verificationCell}>
                        <Button
                          variant="contained"
                          component="label"
                          color="primary"
                          onClick={() => this.setState({ activeMovie: row })}>
                          動画を見る
                        </Button>
                      </TableCell>
                      <TableCell component="td" scope="row" className={classes.verificationCell}>
                        <Button
                          variant="contained"
                          component="label"
                          color="secondary"
                          onClick={() => this.onDeleteMovie(row)}>
                          削除
                        </Button>
                      </TableCell>
                    </Hidden>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <Paper className={classes.paper}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item md sm={12} xs={12}>
              <Typography variant="h6">アカウントの削除</Typography>
            </Grid>
            {auth.accountDeleting && (
              <Grid item>
                <CircularProgress size={20} />
              </Grid>
            )}
            <Grid container item md={2} sm={12} xs={12} justify="flex-end">
              <Button
                size="large"
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={() => this.setState({ showDeleteAccountModal: true })}>
                削除
              </Button>
            </Grid>
          </Grid>
        </Paper>

        <Dialog
          open={showDeleteAccountModal}
          onClose={() => this.onModalClose()}
          aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">アカウントの削除</DialogTitle>
          <DialogContent>
            <DialogContentText>
              アカウントを削除するには現在のパスワードを入力してください
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              type="password"
              fullWidth
              value={password}
              onChange={(e) => this.setState({ password: e.target.value })}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.onModalClose()} color="primary">
              キャンセル
            </Button>
            <Button onClick={() => this.onDeleteAccount()} color="secondary">
              削除
            </Button>
          </DialogActions>
        </Dialog>

        {activeMovie && (
          <Modal item={activeMovie} onClose={() => this.setState({ activeMovie: null })} />
        )}
      </Container>
    )
  }
}

export default withStyles(styles)(Info)
