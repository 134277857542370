import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { AuthState } from 'redux/modules/auth'
import PublicTemplate from './PublicTemplate'

export interface PublicTemplateStoreStates {
  auth: AuthState
}

function mapStateToProps(appState: AppState): PublicTemplateStoreStates {
  return { auth: appState.auth }
}

export default connect(mapStateToProps, {})(PublicTemplate)
