import React from 'react'
import {
  withStyles,
  WithStyles,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import { DrawerProps } from '@material-ui/core/Drawer'
// import { Home as HomeIcon } from '@material-ui/icons'
import { Omit } from '@material-ui/types'

import { navigationStyles } from './styles'
import NavigatorItem from './NavigatorItem'
import items from './navigationItems'

interface NavigatorProps extends Omit<DrawerProps, 'classes'>, WithStyles<typeof navigationStyles> {
  isEditor: boolean
  onMenuClick?: () => void
}

function Navigator(props: NavigatorProps) {
  const { classes, isEditor, onMenuClick, ...other } = props

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem className={classes.itemCategory}>
          <img className={classes.logo} src="/images/logo_light.png" alt="logo" />
        </ListItem>

        {/* <NavigatorItem
          title="ダッシュボード"
          icon={<HomeIcon />}
          to="/"
          className={classes.itemCategory}
          onClick={() => onMenuClick && onMenuClick()}
        /> */}

        {items.map(
          ({ id, editorOnly, children }) =>
            (!editorOnly || isEditor) && (
              <React.Fragment key={id}>
                <ListItem className={classes.categoryHeader}>
                  <ListItemText
                    classes={{
                      primary: classes.categoryHeaderPrimary,
                    }}>
                    {id}
                  </ListItemText>
                </ListItem>
                {children.map(({ id: childId, icon, path, pattern }) => (
                  <NavigatorItem
                    key={childId}
                    title={childId}
                    icon={icon}
                    to={path}
                    pattern={pattern}
                    onClick={() => onMenuClick && onMenuClick()}
                  />
                ))}
                <Divider className={classes.divider} />
              </React.Fragment>
            ),
        )}
      </List>
    </Drawer>
  )
}

export default withStyles(navigationStyles)(Navigator)
