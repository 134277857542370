import React from 'react'
import clsx from 'clsx'
import {
  WithStyles,
  withStyles,
  Paper,
  Button,
  Grid,
  createStyles,
  Theme,
  Typography,
} from '@material-ui/core'

import 'video.js/dist/video-js.css'
import 'webrtc-adapter'
import 'videojs-record/dist/css/videojs.record.css'
import 'videojs-record/dist/videojs.record.js'

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      margin: `${theme.spacing(2)}px 0`,
      padding: theme.spacing(1),
    },
    hidden: {
      display: 'none',
    },
    video: {
      width: 640,
      maxWidth: '100%',
      height: 480,
      background: 'black',
    },
    button: {
      //
    },
  })

type VideoRecorderProps = WithStyles<typeof styles> & {
  display: boolean
  onPost: (data: File) => void
}

type VideoRecorderStates = {
  recorder: MediaRecorder | null
  isDeviceReady: boolean
  deviceError: boolean
  recording: boolean
  converting: boolean
  error: boolean
  data: File | null
}

class VideoRecorder extends React.Component<VideoRecorderProps, VideoRecorderStates> {
  video?: HTMLVideoElement

  constructor(props: VideoRecorderProps) {
    super(props)
    this.state = {
      recorder: null,
      isDeviceReady: false,
      deviceError: false,
      recording: false,
      converting: false,
      error: false,
      data: null,
    }
  }

  componentWillUnmount() {
    if (this.video?.srcObject) {
      const src = this.video.srcObject as MediaStream
      src.getTracks().forEach(t => t.stop())
    }
    this.video = undefined
  }

  onRecord() {
    const src = this.video?.srcObject as MediaStream | null
    if (!src) return

    const rec = new MediaRecorder(src)
    const chunks: Blob[] = []

    rec.ondataavailable = e => chunks.push(e.data)

    rec.onstop = async () => {
      await this.onStop(chunks)
    }

    rec.start()

    this.setState({
      recorder: rec,
      recording: true,
    })
  }

  async onStop(chunks: Blob[]) {
    this.setState({
      recorder: null,
      recording: false,
      converting: true,
    })

    if (this.video?.srcObject) {
      const src = this.video.srcObject as MediaStream
      src.getTracks().forEach(t => t.stop())
      this.video.srcObject = null
    }

    const webCamData = new Blob(chunks)
    const name = 'record.webm'
    const file = new File([webCamData], name, { type: 'video/webm' })

    this.setState({
      converting: false,
      data: file,
    })
  }

  async onClear() {
    if (this.video) {
      const src = await navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      this.video.srcObject = src
      await this.video.play()
    }

    this.setState({
      recording: false,
      error: false,
      data: null,
    })
  }

  render = (): React.ReactNode => {
    const { recorder, isDeviceReady, deviceError, recording, converting, error, data } = this.state
    const { classes, display, onPost } = this.props

    let title: string
    let action: (() => void) | undefined
    let canPreview = false

    if (!isDeviceReady) {
      title = 'カメラを起動'
      action = async () => {
        if (!this.video) return
        const src = await navigator.mediaDevices.getUserMedia({ video: true, audio: true })
        this.video.srcObject = src
        await this.video.play()
        this.setState({ isDeviceReady: true })
      }
    } else if (recording) {
      title = '録画終了'
      action = () => {
        recorder?.stop()
      }
    } else if (converting) {
      title = '動画変換中'
    } else if (data) {
      title = '投稿する'
      action = () => {
        onPost(data)
      }
      canPreview = true
    } else {
      title = '録画開始'
      action = () => {
        this.onRecord()
      }
    }

    return (
      <Paper className={clsx(classes.paper, !display && classes.hidden)}>
        <Grid container direction="column" alignItems="center" spacing={3}>
          <Grid item>
            <video
              ref={node => {
                if (!node || this.video) return
                this.video = node
              }}
              src={(data && URL.createObjectURL(data)) || undefined}
              className={classes.video}
              playsInline
              controls={!!data}
              width={1280}
              height={960}
            />
          </Grid>
          {(deviceError || error) && (
            <Grid item>
              <Typography color="secondary">カメラが見つかりません</Typography>
            </Grid>
          )}
          <Grid container item justify="center" spacing={3}>
            <Grid item>
              <Button
                variant="outlined"
                component="label"
                color="primary"
                onClick={action}
                disabled={converting}
                className={classes.button}>
                {title}
              </Button>
            </Grid>
            {canPreview && (
              <Grid item>
                <Button
                  variant="outlined"
                  component="label"
                  color="secondary"
                  onClick={() => this.onClear()}
                  className={classes.button}>
                  撮り直す
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
    )
  }
}

export default withStyles(styles)(VideoRecorder)
