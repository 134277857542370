import { channel } from 'redux-saga'
import { put, fork, takeEvery, take, SelectEffect, select } from 'redux-saga/effects'
import moment from 'moment'
import firebase from 'firebase/app'

import firebaseApp from 'utils/firebaseApp'
import * as Words from 'redux/modules/readingWords'
import ReadingWordList, { toWeight } from 'redux/models/readingWordList'
import ReadingWord, { ReadingWordField } from 'redux/models/readingWord'
import CSVReader, { ReadingWordsOutput } from 'utils/csvReader'
import { AppState } from 'redux/store'
import { AuthState } from 'redux/modules/auth'

type LoadWordsAction = ReturnType<typeof Words.actions.loadWords>
type AddListAction = ReturnType<typeof Words.actions.addList>
type UpdateListAction = ReturnType<typeof Words.actions.updateList>
type SetDefaultListAction = ReturnType<typeof Words.actions.setDefaultList>
type SetDemoListAction = ReturnType<typeof Words.actions.setDemoList>
type LoadContributionWordAction = ReturnType<typeof Words.actions.loadContributionWord>

const redirectChannel = channel()
const collectionRef = firebaseApp.firestore().collection('readingWordLists')
const wordsRef = (listId: string) =>
  firebaseApp.firestore().collection(`readingWordLists/${listId}/words`)

const selectState = <T>(selector: (s: AppState) => T): SelectEffect => {
  return select(selector)
}

const isSame = (obj: any, field: ReadingWordField): boolean => {
  return (
    obj.text === field.text &&
    obj.weight === field.weight &&
    obj.index === field.index &&
    obj.indexByWeight === field.indexByWeight &&
    obj.isEnabled === field.isEnabled
  )
}

// 重みの選出
const biasList = Array(10)
  .fill(0)
  .flatMap((_, i) => Array(i + 1).fill(i + 1))
const selectWeight = () => toWeight(biasList[Math.floor(Math.random() * biasList.length)] / 10)

// words のリスナー解除関数
let wordUnsubscribe: (() => void) | null = null
let demonstrationWordUnsubscribe: (() => void) | null = null

// 投稿用のリスト取得
const getListForContribution = async (
  listId: string | null,
): Promise<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> | null> => {
  let listSnapshot: firebase.firestore.DocumentSnapshot<
    firebase.firestore.DocumentData
  > | null = null

  if (listId) {
    listSnapshot = await collectionRef.doc(listId).get()
  }

  if (!listSnapshot || !listSnapshot.exists) {
    // なければデフォルトのやつを使用
    listSnapshot = (await collectionRef.where('isDefault', '==', true).get()).docs[0]
  }

  if (!listSnapshot || !listSnapshot.exists) {
    console.log('ReadingWordListId not found')
    return null
  }

  return listSnapshot
}

// SAGA ------------------------

// /readingWordLists の同期
const loadList = function*() {
  yield take(Words.LOAD_LIST)

  collectionRef.onSnapshot(snapshot => {
    const items: ReadingWordList[] = []
    snapshot.docs.forEach(doc => {
      const item = ReadingWordList.load(doc.id, doc.data())
      if (!item) return
      items.push(item)
    })
    redirectChannel.put(Words.actions.syncLists(items))
  })
}

// リストの words の同期
const loadWords = function*(action: LoadWordsAction) {
  wordUnsubscribe && wordUnsubscribe()

  const listSnapshot: firebase.firestore.DocumentSnapshot<
    firebase.firestore.DocumentData
  > | null = yield getListForContribution(action.payload)

  if (!listSnapshot) {
    yield put(Words.actions.syncWords(null))
    return
  }

  wordUnsubscribe = listSnapshot.ref.onSnapshot(
    async snapshot => {
      const list = ReadingWordList.load(snapshot.id, snapshot.data())

      if (!list) {
        console.log('ReadingWordListId not found')
        redirectChannel.put(Words.actions.syncWords(null))
        return
      }

      const wordsSnapshot = await wordsRef(list.id).get()
      let items: ReadingWord[] = []
      wordsSnapshot.docs.forEach(doc => {
        const item = ReadingWord.load(doc.id, doc.data())
        if (!item) return
        items.push(item)
      })
      items = items.sort((a, b) => a.index - b.index)

      redirectChannel.put(Words.actions.syncWords({ list, items }))
    },
    err => {
      console.log(err)
      redirectChannel.put(Words.actions.syncWords(null))
    },
  )
}

const unloadWords = function() {
  wordUnsubscribe && wordUnsubscribe()
}

// 読唇デモ用のワード一覧を同期
const loadDemonstrationWords = function*() {
  demonstrationWordUnsubscribe && demonstrationWordUnsubscribe()

  const listSnapshot: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> = (yield collectionRef
    .where('canPredict', '==', true)
    .get()).docs[0]

  if (!listSnapshot || !listSnapshot.exists) {
    console.log('Demo list not found')
    yield put(Words.actions.syncDemonstrationWords([]))
    return
  }

  demonstrationWordUnsubscribe = listSnapshot.ref.onSnapshot(
    async snapshot => {
      const list = ReadingWordList.load(snapshot.id, snapshot.data())

      if (!list) {
        console.log('Demo list not found')
        redirectChannel.put(Words.actions.syncDemonstrationWords([]))
        return
      }

      const wordsSnapshot = await wordsRef(list.id).get()
      let items: ReadingWord[] = []
      wordsSnapshot.docs.forEach(doc => {
        const item = ReadingWord.load(doc.id, doc.data())
        if (!item) return
        items.push(item)
      })
      items = items.sort((a, b) => a.index - b.index)

      redirectChannel.put(Words.actions.syncDemonstrationWords(items))
    },
    err => {
      console.log(err)
      redirectChannel.put(Words.actions.syncDemonstrationWords([]))
    },
  )

  yield put(Words.actions.syncDemonstrationWords([]))
}

const addList = function*(action: AddListAction) {
  try {
    const state: Words.ReadingWordsState = yield selectState(s => s.readingWords)
    const { items } = state.list

    const { title, fields, weightCounts } = yield CSVReader.readAsReadingWords(action.payload)

    const listRef = collectionRef.doc()
    let batch = firebaseApp.firestore().batch()
    batch.set(listRef, {
      title,
      weightCounts,
      isDefault: items.length === 0,
      canPredict: false,
      updatedAt: moment().unix(),
    })
    const listWordRef = wordsRef(listRef.id)

    let index = 1
    for (const field of fields) {
      index += 1
      const wordRef = listWordRef.doc()
      batch.set(wordRef, field)

      if (index === 500) {
        yield batch.commit()
        batch = firebaseApp.firestore().batch()
        index = 0
      }
    }
    yield batch.commit()

    yield put(Words.actions.succeededToAddList())
  } catch (err) {
    console.log(err)
    yield put(Words.actions.failedToAddList())
  }
}

const updateList = function*(action: UpdateListAction) {
  try {
    const { list, file } = action.payload

    const { title, fields, weightCounts }: ReadingWordsOutput = yield CSVReader.readAsReadingWords(
      file,
    )

    // ワードの更新
    const listRef = wordsRef(list.id)
    const listSnapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = yield listRef.get()

    let batchCount = 0
    let batch = firebaseApp.firestore().batch()

    for (const doc of listSnapshot.docs) {
      const word = doc.data()
      const field = fields.find(field => field.text === word.text)
      if (!field) {
        batch.update(doc.ref, { isEnabled: false })
      } else if (!word.isEnabled) {
        batch.update(doc.ref, { ...field, isEnabled: true })
      } else if (!isSame(word, field)) {
        batch.update(doc.ref, field)
      }

      batchCount += 1
      if (batchCount === 500) {
        yield batch.commit()
        batch = firebaseApp.firestore().batch()
        batchCount = 0
      }
    }

    for (const field of fields) {
      const word = listSnapshot.docs.find(doc => doc.data().text === field.text)
      if (word) continue

      const doc = listRef.doc()
      batch.set(doc, field)

      batchCount += 1
      if (batchCount === 500) {
        yield batch.commit()
        batch = firebaseApp.firestore().batch()
        batchCount = 0
      }
    }
    yield batch.commit()

    // 概要の更新
    // loadWords の subscribe を正常機能させるために概要は最後に更新
    yield collectionRef.doc(list.id).update({
      title,
      weightCounts,
      updatedAt: moment().unix(),
    })

    yield put(Words.actions.succeededToUpdateList())
  } catch (err) {
    console.log(err)
    yield put(Words.actions.failedToUpdateList())
  }
}

const setDefaultList = function*(action: SetDefaultListAction) {
  const list = action.payload
  const lists = yield collectionRef.get()
  const batch = firebaseApp.firestore().batch()

  for (const doc of lists.docs) {
    batch.update(doc.ref, { isDefault: doc.id === list.id })
  }

  yield batch.commit()

  yield put(Words.actions.completedToSetDefaultList())
}

const setDemoList = function*(action: SetDemoListAction) {
  const list = action.payload
  const lists = yield collectionRef.get()
  const batch = firebaseApp.firestore().batch()

  for (const doc of lists.docs) {
    batch.update(doc.ref, { canPredict: doc.id === list.id })
  }

  yield batch.commit()

  yield put(Words.actions.completedToSetDemoList())
}

const loadContributionWord = function*(action: LoadContributionWordAction) {
  const authState: AuthState = yield selectState(s => s.auth)
  const wordsState: Words.ReadingWordsState = yield selectState(s => s.readingWords)

  const listSnapshot: firebase.firestore.DocumentSnapshot<
    firebase.firestore.DocumentData
  > | null = yield getListForContribution(authState.user?.readingWordListId || null)

  const list = listSnapshot && ReadingWordList.load(listSnapshot.id, listSnapshot.data())

  if (!list) {
    yield put(Words.actions.completedToLoadContributionWord(null))
    return
  }

  let word: ReadingWord | null = null
  let index = 0

  if (list.isDefault) {
    // ランダム選出

    let weight = selectWeight()

    while (!word && weight <= 1.0) {
      const numberOfWords = list.weigthCounts[weight]
      const index = Math.floor(Math.random() * numberOfWords)

      const wordRef = (yield wordsRef(list.id)
        .where('isEnabled', '==', true)
        .where('weight', '==', weight)
        .where('indexByWeight', '==', index)
        .limit(1)
        .get()).docs[0]

      word = wordRef && ReadingWord.load(wordRef.id, wordRef.data())

      weight = toWeight(Math.round((weight + 0.1) * 10) / 10)
    }
  } else {
    // index 順に選出

    const { data } = wordsState.contribution
    const currentIndex = data && list.id === data.list.id ? data.index : -1 // 最初の読み込み or リストが変更 -> index をリセット (次で +1 するので -1)

    // 次の index
    index = action.payload === 'next' ? currentIndex + 1 : currentIndex - 1
    index = Math.min(list.numberOfWords - 1, Math.max(0, index))

    const wordRef = (yield wordsRef(list.id)
      .where('isEnabled', '==', true)
      .where('index', '==', index)
      .limit(1)
      .get()).docs[0]

    word = wordRef && ReadingWord.load(wordRef.id, wordRef.data())
  }

  const data: Words.ContributionData | null = word && {
    list,
    word,
    index,
  }

  yield put(Words.actions.completedToLoadContributionWord(data))
}

export default function* dataSaga() {
  yield fork(loadList)
  yield takeEvery(Words.LOAD_WORDS, loadWords)
  yield takeEvery(Words.UNLOAD_WORDS, unloadWords)
  yield takeEvery(Words.LOAD_DEMONSTRATION_WORDS, loadDemonstrationWords)
  yield takeEvery(Words.ADD_LIST, addList)
  yield takeEvery(Words.UPDATE_LIST, updateList)
  yield takeEvery(Words.SET_DEFAULT_LIST, setDefaultList)
  yield takeEvery(Words.SET_DEMO_LIST, setDemoList)
  yield takeEvery(Words.LOAD_CONTRIBUTION_WORD, loadContributionWord)

  while (true) {
    const action = yield take(redirectChannel)
    yield put(action)
  }
}
