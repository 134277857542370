import React from 'react'
import { Redirect } from 'react-router-dom'
import { CssBaseline, WithStyles, withStyles, Hidden } from '@material-ui/core'

import { AppTemplateActions, AppTemplateStoreStates } from '.'
import { drawerWidth, templateStyles } from './styles'

import Copyright from 'components/Copyright'
import EmailVerification from './EmailVerification'
import Navigator from './Navigator'
import Header from './Header'

type AppTemplateProps = React.PropsWithChildren<
  WithStyles<typeof templateStyles> & AppTemplateStoreStates & AppTemplateActions
>

type AppTemplateStates = {
  isMenuOpen: boolean
}

class AppTemplate extends React.Component<AppTemplateProps, AppTemplateStates> {
  constructor(props: AppTemplateProps) {
    super(props)
    this.state = {
      isMenuOpen: false,
    }
  }

  onHandleDrawerToggle() {
    this.setState({ isMenuOpen: !this.state.isMenuOpen })
  }

  render() {
    const { isMenuOpen } = this.state
    const { classes, children, auth, logOut, sendVerificationEmail } = this.props
    const { user } = auth
    const isEditor = user?.isEditor || false

    if (!user) {
      return <Redirect to="/login" />
    }

    if (!user.isEmailVerified) {
      return (
        <EmailVerification
          auth={auth}
          onResend={() => sendVerificationEmail()}
          onLogOut={() => logOut()}
        />
      )
    }

    return (
      <div className={classes.root}>
        <CssBaseline />
        <nav className={classes.drawer}>
          <Hidden smUp implementation="js">
            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={isMenuOpen}
              onClose={() => this.onHandleDrawerToggle()}
              isEditor={isEditor}
              onMenuClick={() => this.onHandleDrawerToggle()}
            />
          </Hidden>
          <Hidden xsDown implementation="css">
            <Navigator isEditor={isEditor} PaperProps={{ style: { width: drawerWidth } }} />
          </Hidden>
        </nav>
        <div className={classes.app}>
          <Header
            name={user.name}
            onDrawerToggle={() => this.onHandleDrawerToggle()}
            onLogOut={() => logOut()}
          />
          <main className={classes.main}>{children}</main>
          <footer className={classes.footer}>
            <Copyright />
          </footer>
        </div>
      </div>
    )
  }
}

export default withStyles(templateStyles)(AppTemplate)
