import { createStyles, Theme } from '@material-ui/core'

export default (theme: Theme) =>
  createStyles({
    paper: {
      marginBottom: theme.spacing(2),
      backgroundColor: theme.palette.grey[50],
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    userTypeLabel: {
      fontSize: 11,
      color: theme.palette.primary.dark,
    },
    myselfLabel: {
      fontWeight: 'bold',
      fontSize: 11,
      color: theme.palette.error.dark,
    },
    identifier: {
      fontSize: 10,
      color: theme.palette.grey[500],
    },
    buttonCSV: {
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    deleteButton: {
      margin: theme.spacing(2),
    },
  })
