import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  Button,
  Container,
  CssBaseline,
  TextField,
  Typography,
  WithStyles,
  withStyles,
  Paper,
  CircularProgress,
} from '@material-ui/core'

import { InquiryActions, InquiryStoreStates } from '.'
import styles from './styles'

type OwnProps = RouteComponentProps & WithStyles<typeof styles>

type InquiryProps = OwnProps & InquiryStoreStates & InquiryActions

interface InquiryStates {
  title: string
  content: string
  // validation
  isEmpty: boolean
}

type InquiryItem = Pick<InquiryStates, 'title' | 'content'>

class Inquiry extends React.Component<InquiryProps, InquiryStates> {
  constructor(props: InquiryProps) {
    super(props)
    this.state = {
      title: '',
      content: '',
      isEmpty: true,
    }
  }

  componentDidUpdate(prevProps: InquiryProps) {
    const { inquiry } = this.props
    if (!prevProps.inquiry.sendingInquiry || !inquiry.isSent) return

    setTimeout(() => {
      this.setState({
        title: '',
        content: '',
        isEmpty: true,
      })
    }, 100)
  }

  onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const { title, content, isEmpty } = this.state

    if (isEmpty) return

    this.props.sendInquiry({ title, content })
  }

  onChangeValue(key: keyof InquiryItem, value: string) {
    const state = { ...this.state }
    state[key] = value
    state.isEmpty = Object.values(state).includes('')
    this.setState(state)
  }

  render = (): React.ReactNode => {
    const { classes, inquiry } = this.props
    const { sendingInquiry, isSent } = inquiry
    const { title, content, isEmpty } = this.state

    return (
      <Container component="main" maxWidth="sm">
        <CssBaseline />
        <Paper className={classes.paper}>
          <Typography align="center" color="textPrimary" variant="h5">
            お問い合わせ
          </Typography>

          {isSent === false && (
            <Typography align="center" color="error" component="h1" variant="h6">
              送信できませんでした
            </Typography>
          )}

          {isSent && (
            <Typography align="center" color="primary" component="h1" variant="h6">
              送信しました
            </Typography>
          )}

          <form className={classes.form} noValidate onSubmit={e => this.onSubmit(e)}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="件名"
              autoFocus
              value={title}
              onChange={({ target }) => this.onChangeValue('title', target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              multiline
              label="お問い合わせ内容"
              value={content}
              onChange={({ target }) => this.onChangeValue('content', target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isEmpty || sendingInquiry}>
              {sendingInquiry ? <CircularProgress color="inherit" size={20} /> : '送信する'}
            </Button>
          </form>
        </Paper>
      </Container>
    )
  }
}

export default withStyles(styles)(Inquiry)
