/* eslint @typescript-eslint/no-non-null-assertion: 0 */

export const APP_NAME = 'クチヨミ'
export const APP_URL = process.env.REACT_APP_APP_URL!

// firebase
export const firebase = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY!,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN!,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL!,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID!,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET!,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID!,
  appId: process.env.REACT_APP_FIREBASE_APP_ID!,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID!,
}
