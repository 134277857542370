import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Typography,
  WithStyles,
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core'
import { AuthState } from 'redux/modules/auth'

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    button: {
      width: 100,
      marginBottom: theme.spacing(1),
    },
  })

type OwnProps = WithStyles<typeof styles> & {
  auth: AuthState
  onResend: () => void
  onLogOut: () => void
}

const EmailVerification: React.FC<OwnProps> = (props: OwnProps) => {
  const { classes, auth, onResend, onLogOut } = props
  const { user, sendingEmail, loggingOut, logOutError } = auth

  if (!user) {
    return <Redirect to="/login" />
  }

  const isNewUser = user.createdAt === user.updatedAt

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box className={classes.paper} textAlign="center">
        <Typography component="h1" variant="h4">
          こんにちは、{user.name} さん。
        </Typography>
        <Box mt={2}>
          <Typography color="textPrimary" component="p">
            {user.email}
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography color="textSecondary" component="p">
            登録メールアドレスに確認メールを送信しました。
          </Typography>
          <Typography color="textSecondary" component="p">
            メール内のリンクを開いて本登録を完了してください。
          </Typography>
        </Box>
        <Box mt={3}>
          <Typography color="textSecondary" component="p">
            メールが届きませんか？
          </Typography>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            disabled={sendingEmail}
            onClick={() => onResend()}>
            再送信
          </Button>
          {sendingEmail && (
            <Typography color="secondary" component="p">
              メールを再送信しました。
            </Typography>
          )}
        </Box>
        {isNewUser && (
          <Box mt={3}>
            <Typography color="textSecondary" component="p">
              メールアドレスを間違えた場合は再度サインアップする必要があります。
            </Typography>
            <Button
              className={classes.button}
              variant="contained"
              color="secondary"
              disabled={loggingOut}
              onClick={() => onLogOut()}>
              ログアウト
            </Button>
            {logOutError && (
              <>
                <Typography color="secondary" component="p">
                  {logOutError}
                </Typography>
                <Typography color="secondary" component="p">
                  解決できない場合は Cookie の削除などを行ってください。
                </Typography>
              </>
            )}
          </Box>
        )}
        {!isNewUser && (
          <Box mt={3}>
            <Typography color="textSecondary" component="p">
              メールアドレスを間違えた場合は，前のメールアドレスに届くメール内のリンクを開いて，ログイン用メールアドレスをリセットしてください。
            </Typography>
          </Box>
        )}
      </Box>
    </Container>
  )
}

export default withStyles(styles)(EmailVerification)
