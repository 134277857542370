import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import {
  actions as wordsActions,
  ReadingWordsState,
  UpdateListPayload,
} from 'redux/modules/readingWords'
import ReadingWordList from 'redux/models/readingWordList'
import Words from './Words'

export interface WordsActions {
  loadWords: (listId: string) => Action<string | null>
  updateList: (payload: UpdateListPayload) => Action<UpdateListPayload>
  setDefaultList: (list: ReadingWordList) => Action<ReadingWordList>
  setDemoList: (list: ReadingWordList) => Action<ReadingWordList>
}

export interface WordsStoreStates {
  readingWords: ReadingWordsState
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): WordsActions {
  return {
    loadWords: listId => dispatch(wordsActions.loadWords(listId)),
    updateList: payload => dispatch(wordsActions.updateList(payload)),
    setDefaultList: list => dispatch(wordsActions.setDefaultList(list)),
    setDemoList: list => dispatch(wordsActions.setDemoList(list)),
  }
}

function mapStateToProps(appState: AppState): WordsStoreStates {
  return { readingWords: appState.readingWords }
}

export default connect(mapStateToProps, mapDispatchToProps)(Words)
