import React from 'react'
import clsx from 'clsx'
import { Link as RouterLink, withRouter, RouteComponentProps } from 'react-router-dom'
import { withStyles, WithStyles, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'

import { navigationStyles } from './styles'

type NavigatorItemProps = RouteComponentProps &
  WithStyles<typeof navigationStyles> & {
    title: string
    icon: React.ReactElement
    to: string
    pattern: RegExp
    className?: string
    onClick: () => void
  }

function NavigatorItem(props: NavigatorItemProps) {
  const { location, classes, title, icon, to, pattern, className, onClick } = props
  const isActive = !!location.pathname.match(pattern)

  const hasScheme = to.includes('http')

  return (
    <ListItem
      button
      component={hasScheme ? 'a' : RouterLink}
      to={to}
      href={to}
      target={hasScheme ? '_blank' : '_self'}
      className={clsx(className, classes.item, isActive && classes.itemActiveItem)}
      onClick={onClick}>
      <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
      <ListItemText
        classes={{
          primary: classes.itemPrimary,
        }}>
        {title}
      </ListItemText>
    </ListItem>
  )
}

export default withRouter(withStyles(navigationStyles)(NavigatorItem))
