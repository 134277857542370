import { createStyles, Theme } from '@material-ui/core'

export default (theme: Theme) =>
  createStyles({
    paper: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1),
    },
    listTitle: {
      fontWeight: 'bold',
      maxWidth: 600,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    title: {
      fontWeight: 'bold',
    },
    button: {
      margin: theme.spacing(2),
    },
    warning: {
      marginTop: theme.spacing(2),
    },
    image: {
      marginLeft: '5%',
      width: '90%',
    },
    list: {
      maxWidth: 600,
    },
  })
