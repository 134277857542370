import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { actions as authActions, AuthState } from 'redux/modules/auth'
import { actions as moviesActions, MoviesState } from 'redux/modules/movies'
import Movie from 'redux/models/movie'
import Info from './Info'

export interface InfoActions {
  load: () => Action<void>
  cancelMovie: (movie: Movie) => Action<Movie>
  deleteAccount: (password: string) => Action<string>
}

export interface InfoStoreStates {
  auth: AuthState
  movies: MoviesState
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): InfoActions {
  return {
    load: () => dispatch(moviesActions.load()),
    cancelMovie: movie => dispatch(moviesActions.cancelUploadedMovie(movie)),
    deleteAccount: password => dispatch(authActions.deleteAccount(password)),
  }
}

function mapStateToProps(appState: AppState): InfoStoreStates {
  return {
    auth: appState.auth,
    movies: appState.movies,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Info)
