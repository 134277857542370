import React from 'react'
import { RouteComponentProps, withRouter, Link as RouterLink } from 'react-router-dom'
import { withStyles, WithStyles, AppBar, Grid, Toolbar } from '@material-ui/core'

import { headerStyles } from './styles'

type HeaderProps = RouteComponentProps &
  WithStyles<typeof headerStyles> & {
    isLoggedIn: boolean
  }

function Header(props: HeaderProps) {
  const { location, classes } = props

  const isTop = location.pathname === '/'

  return isTop ? (
    <></>
  ) : (
    <AppBar className={classes.container} position="sticky" elevation={2}>
      <Toolbar>
        <Grid container alignItems="center">
          <Grid item xs>
            <RouterLink to="/">
              <img className={classes.logo} src="/images/logo_light.png" alt="クチヨミ" />
            </RouterLink>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default withRouter(withStyles(headerStyles)(Header))
