import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  Container,
  CssBaseline,
  WithStyles,
  withStyles,
  Paper,
  Toolbar,
  Button,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
} from '@material-ui/core'
import {
  ExpandMore as ExpandMoreIcon,
  People as PeopleIcon,
  VideoLibrary as VideoLibraryIcon,
} from '@material-ui/icons'

import { Board } from 'components'
import { DashboardActions, DashboardStoreStates } from '.'
import styles from './styles'

type OwnProps = WithStyles<typeof styles>

type DashboardProps = OwnProps & DashboardStoreStates & DashboardActions

type DashboardStates = {
  expandedId: string | null
}

class Dashboard extends React.Component<DashboardProps, DashboardStates> {
  constructor(props: DashboardProps) {
    super(props)

    this.state = {
      expandedId: null,
    }

    const { notifications, users, loadNotifications, loadUsers } = props

    // お知らせ一覧の読み込み
    if (!notifications.loading && !notifications.loaded) loadNotifications()
    // ユーザ一覧の読み込み
    if (!users.loading && !users.loaded) loadUsers()
  }

  onChangeExpansionPanel(notificationId: string) {
    const { expandedId } = this.state
    this.setState({
      expandedId: notificationId === expandedId ? null : notificationId,
    })
  }

  render = (): React.ReactNode => {
    const { expandedId } = this.state
    const { classes, notifications, users } = this.props

    const numberOfMovies = users.items.reduce((count, user) => count + user.numberOfMovies, 0)

    return (
      <Container>
        <CssBaseline />

        <Paper className={classes.paper}>
          <Toolbar>
            <Grid item xs>
              <Typography variant="h4">最新のお知らせ</Typography>
            </Grid>
            <Grid>
              <Button variant="outlined" color="primary" component={RouterLink} to="/notifications">
                全てのお知らせを見る
              </Button>
            </Grid>
          </Toolbar>

          {notifications.items.length === 0 && (
            <Typography variant="h6">現在お知らせはありません</Typography>
          )}

          {notifications.items.slice(0, 3).map(row => (
            <ExpansionPanel
              key={row.id}
              expanded={expandedId === row.id}
              onChange={() => this.onChangeExpansionPanel(row.id)}>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container alignItems="center">
                  <Typography className={classes.date}>{row.updatedDate}</Typography>
                  <Typography className={classes.title}>{row.title}</Typography>
                </Grid>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Typography>{row.content}</Typography>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        </Paper>

        <Paper className={classes.paper}>
          <Toolbar>
            <Grid item xs>
              <Typography variant="h4">各種データ</Typography>
            </Grid>
          </Toolbar>

          <Grid container>
            <Grid item>
              <Board
                title="ユーザー数"
                description={`${users.items.length} 人`}
                icon={<PeopleIcon style={{ fontSize: 50 }} />}
              />
            </Grid>
            <Grid item>
              <Board
                title="投稿数"
                description={`${numberOfMovies}`}
                icon={<VideoLibraryIcon style={{ fontSize: 50 }} />}
              />
            </Grid>
          </Grid>
        </Paper>
      </Container>
    )
  }
}

export default withStyles(styles)(Dashboard)
