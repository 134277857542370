import { createStyles, Theme } from '@material-ui/core'

export default (theme: Theme) =>
  createStyles({
    paper: {
      marginBottom: theme.spacing(2),
      backgroundColor: theme.palette.grey[50],
    },
    paperTitle: {
      fontWeight: 'bold',
    },
    list: {
      margin: theme.spacing(2),
      padding: theme.spacing(2),
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    listItem: {
      margin: theme.spacing(1, 0, 3),
    },
    date: {
      width: 200,
    },
    title: {
      fontWeight: 'bold',
    },
    addButton: {
      margin: `0 ${theme.spacing(1)}px`,
    },
    editButton: {
      color: theme.palette.success.main,
      marginRight: theme.spacing(2),
    },
  })
