import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { AuthState } from 'redux/modules/auth'
import { actions as notificationsActions, NotificationsState } from 'redux/modules/notifications'
import { actions as usersActions, UsersState } from 'redux/modules/users'
import Dashboard from './Dashboard'

export interface DashboardActions {
  loadNotifications: () => Action<void>
  loadUsers: () => Action<void>
}

export interface DashboardStoreStates {
  auth: AuthState
  notifications: NotificationsState
  users: UsersState
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): DashboardActions {
  return {
    loadNotifications: () => dispatch(notificationsActions.load()),
    loadUsers: () => dispatch(usersActions.load()),
  }
}

function mapStateToProps(appState: AppState): DashboardStoreStates {
  return {
    auth: appState.auth,
    notifications: appState.notifications,
    users: appState.users,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
