import React from 'react'
import { RouteComponentProps, Link as RouterLink, withRouter } from 'react-router-dom'
import { CssBaseline, WithStyles, withStyles, Grid, Link, Button } from '@material-ui/core'

import { PublicTemplateStoreStates } from '.'
import { templateStyles } from './styles'

import Copyright from 'components/Copyright'
import Header from './Header'

type PublicTemplateProps = RouteComponentProps &
  React.PropsWithChildren<WithStyles<typeof templateStyles> & PublicTemplateStoreStates> & {
    showLinks?: boolean
  }

class PublicTemplate extends React.Component<PublicTemplateProps> {
  render() {
    const { history, location, classes, children, auth, showLinks } = this.props
    const { user } = auth
    const isLoggedIn = user != null
    const isTop = location.pathname === '/'

    return (
      <div className={classes.root}>
        <CssBaseline />
        <div className={classes.app}>
          <Header isLoggedIn={isLoggedIn} />
          <main className={classes.main}>{children}</main>
          <footer className={classes.footer}>
            <Grid container direction="column" alignItems="center">
              {!isTop && (
                <Grid item>
                  <Button
                    variant="contained"
                    size="large"
                    className={classes.button}
                    onClick={() => history.push(isLoggedIn ? '/notifications' : '/login')}>
                    {isLoggedIn ? 'ホームへ' : '使ってみる'}
                  </Button>
                </Grid>
              )}
              {showLinks && (
                <Grid item container className={classes.links} spacing={2}>
                  <Grid item sm xs={12} className={classes.linkContainer}>
                    <Link
                      variant="subtitle1"
                      component={RouterLink}
                      to="/"
                      className={classes.link}>
                      TOP
                    </Link>
                  </Grid>
                  <Grid item sm xs={12} className={classes.linkContainer}>
                    <Link
                      variant="subtitle1"
                      component={RouterLink}
                      to="/terms"
                      className={classes.link}>
                      利用規約
                    </Link>
                  </Grid>
                  <Grid item sm xs={12} className={classes.linkContainer}>
                    <Link
                      variant="subtitle1"
                      component={RouterLink}
                      to="/privacy_policy"
                      className={classes.link}>
                      プライバシーポリシー
                    </Link>
                  </Grid>
                </Grid>
              )}
              <Grid item>
                <Copyright />
              </Grid>
            </Grid>
          </footer>
        </div>
      </div>
    )
  }
}

export default withRouter(withStyles(templateStyles)(PublicTemplate))
