import moment from 'moment'
import { channel } from 'redux-saga'
import { put, fork, takeEvery, take, SelectEffect, select } from 'redux-saga/effects'

import firebase from 'utils/firebaseApp'
import { AppState } from 'redux/store'
import { AuthState } from 'redux/modules/auth'
import * as Notifications from 'redux/modules/notifications'
import Notification from 'redux/models/notification'

type AddAction = ReturnType<typeof Notifications.actions.add>
type LoadEditItemAction = ReturnType<typeof Notifications.actions.loadEditItem>
type UpdateAction = ReturnType<typeof Notifications.actions.update>

const redirectChannel = channel()
const collectionRef = firebase.firestore().collection('notifications')

const selectState = <T>(selector: (s: AppState) => T): SelectEffect => {
  return select(selector)
}

// notifications の同期
const load = function*() {
  yield take(Notifications.LOAD)

  collectionRef.where('isDeleted', '==', false).onSnapshot(snapshot => {
    let items: Notification[] = []
    snapshot.docs.forEach(doc => {
      const item = Notification.load(doc.id, doc.data())
      item && items.push(item)
    })
    items = items.sort((a, b) => b.updatedAt.unix() - a.updatedAt.unix()) // 降順にソート
    redirectChannel.put(Notifications.actions.sync(items))
  })
}

const addNotification = function*(action: AddAction) {
  const auth: AuthState = yield selectState(s => s.auth)
  const user = auth.user
  if (!user) return

  const fields = action.payload
  const timestamp = moment().unix()

  yield collectionRef.add({
    ...fields,
    userId: user.id,
    createdAt: timestamp,
    updatedAt: timestamp,
    isDeleted: false,
  })
}

const loadEditItem = function*(action: LoadEditItemAction) {
  const itemId = action.payload
  const item = yield collectionRef.doc(itemId).get()

  if (!item) {
    yield put(Notifications.actions.failedToLoadEditItem())
    return
  }

  const notification = Notification.load(itemId, item.data())

  notification
    ? yield put(Notifications.actions.succeededToLoadEditItem(notification))
    : yield put(Notifications.actions.failedToLoadEditItem())
}

const updateNotification = function*(action: UpdateAction) {
  const notifications: Notifications.NotificationsState = yield selectState(s => s.notifications)
  const { item } = notifications.edit
  const data = {
    ...action.payload,
    updatedAt: moment().unix(),
  }

  yield item && collectionRef.doc(item.id).update(data)

  yield put(Notifications.actions.updated())
}

const deleteNotification = function*() {
  const notifications: Notifications.NotificationsState = yield selectState(s => s.notifications)
  const { item } = notifications.edit

  yield item &&
    collectionRef.doc(item.id).update({
      isDeleted: true,
      updatedAt: moment().unix(),
    })

  yield put(Notifications.actions.deleted())
}

export default function* dataSaga() {
  yield fork(load)
  yield takeEvery(Notifications.ADD_ITEM, addNotification)
  yield takeEvery(Notifications.LOAD_EDIT_ITEM, loadEditItem)
  yield takeEvery(Notifications.UPDATE_ITEM, updateNotification)
  yield takeEvery(Notifications.DELETE_ITEM, deleteNotification)

  while (true) {
    const action = yield take(redirectChannel)
    yield put(action)
  }
}
