import { createStyles, Theme } from '@material-ui/core'
import * as colors from 'utils/color'

export const drawerWidth = 256

export const templateStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      minHeight: '100vh',
    },
    app: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    main: {
      flex: 1,
      padding: theme.spacing(3, 2),
    },
    footer: {
      padding: theme.spacing(1),
    },
    links: {
      maxWidth: 500,
      margin: '0 auto',
      marginBottom: theme.spacing(5),
    },
    linkContainer: {
      textAlign: 'center',
    },
    link: {
      color: theme.palette.grey[700],
    },
    button: {
      width: 200,
      height: 50,
      marginBottom: theme.spacing(3),
      backgroundColor: colors.red,
      color: colors.white,
      fontWeight: 'bold',
    },
  })

export const headerStyles = (theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(1, 0),
      backgroundColor: colors.red,
    },
    logo: {
      height: 50,
      [theme.breakpoints.down('sm')]: {
        width: 200,
        height: 'auto',
        marginLeft: 'calc((100% - 200px) / 2)',
      },
    },
    button: {
      backgroundColor: colors.white,
      color: colors.red,
      fontWeight: 'bold',
    },
  })
