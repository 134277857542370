import React from 'react'
import { RouteComponentProps, Redirect, Link as RouterLink } from 'react-router-dom'
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Link,
  TextField,
  Typography,
  WithStyles,
  withStyles,
  CircularProgress,
} from '@material-ui/core'

import Copyright from 'components/Copyright'

import { LoginActions, LoginStoreStates } from '.'
import styles from './styles'

type OwnProps = RouteComponentProps & WithStyles<typeof styles>

type LoginProps = OwnProps & LoginStoreStates & LoginActions

interface LoginStates {
  email: string
  password: string
}

class Login extends React.Component<LoginProps, LoginStates> {
  constructor(props: LoginProps) {
    super(props)
    this.state = {
      email: '',
      password: '',
    }
  }

  onChangeEmail(email: string) {
    this.setState({ email })
  }

  onChangePassword(password: string) {
    this.setState({ password })
  }

  onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const { email, password } = this.state
    this.props.logIn({ email, password })
  }

  render = (): React.ReactNode => {
    const { classes, auth } = this.props
    const { loggingIn, logInError, user } = auth

    if (user) {
      return <Redirect to="/notifications" />
    }

    return (
      <Container component="main">
        <CssBaseline />
        <div className={classes.paper}>
          <img className={classes.logo} src="/images/logo.png" alt="logo" />

          <Typography component="h2" variant="h6" align="center">
            クチヨミとは、九州工業大学齊藤剛史研究室が提供する機械読唇技術に関するサービスです。
          </Typography>
          <Typography component="h2" variant="h6" align="center">
            利用規約に同意された場合のみ利用できます。
          </Typography>

          <Button variant="outlined" color="primary" className={classes.termsButton}>
            <Link variant="subtitle1" component={RouterLink} to="/terms">
              利用規約
            </Link>
          </Button>

          <Container maxWidth="xs" className={classes.formContaienr}>
            {logInError && (
              <Typography color="error" variant="h6">
                {auth.logInError}
              </Typography>
            )}

            <form className={classes.form} noValidate onSubmit={e => this.onSubmit(e)}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Email Address"
                type="email"
                autoComplete="email"
                autoFocus
                onChange={({ target }) => this.onChangeEmail(target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Password"
                type="password"
                autoComplete="current-password"
                onChange={({ target }) => this.onChangePassword(target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={loggingIn}>
                {loggingIn ? <CircularProgress size={20} /> : 'ログイン'}
              </Button>
            </form>
            <Grid container>
              <Grid item xs>
                <Link to="/password_reset" component={RouterLink}>
                  パスワードをお忘れですか？
                </Link>
              </Grid>
              <Grid item>
                <Link to="/signup" component={RouterLink}>
                  アカウント作成
                </Link>
              </Grid>
            </Grid>
          </Container>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    )
  }
}

export default withStyles(styles)(Login)
