import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { actions as moviesActions, MoviesState, VerifyPayload } from 'redux/modules/movies'
import Verification from './Verification'

export interface VerificationActions {
  load: () => Action<void>
  verify: (payload: VerifyPayload) => Action<VerifyPayload>
  cancel: () => Action<void>
  clear: () => Action<void>
}

export interface VerificationStoreStates {
  movies: MoviesState
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): VerificationActions {
  return {
    load: () => dispatch(moviesActions.load()),
    verify: payload => dispatch(moviesActions.verify(payload)),
    cancel: () => dispatch(moviesActions.cancelToVerify()),
    clear: () => dispatch(moviesActions.clearVerifying()),
  }
}

function mapStateToProps(appState: AppState): VerificationStoreStates {
  return { movies: appState.movies }
}

export default connect(mapStateToProps, mapDispatchToProps)(Verification)
