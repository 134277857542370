import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'

// Actions
export const SEND_INQUIRY = 'lip-reading/inquiry/SEND_INQUIRY'
export const SEND_INQUIRY_COMPLETION = 'lip-reading/inquiry/SEND_INQUIRY_COMPLETION'

// Action Creators
const actionCreator = actionCreatorFactory()

export interface SendInquiryPayload {
  title: string
  content: string
}

export const actions = {
  sendInquiry: actionCreator<SendInquiryPayload>(SEND_INQUIRY),
  sentInquiry: actionCreator<boolean>(SEND_INQUIRY_COMPLETION),
}

// Reducer
export interface InquiryState {
  sendingInquiry: boolean
  isSent: boolean | null
}

const initialState: () => InquiryState = () => ({
  sendingInquiry: false,
  isSent: null,
})

export default reducerWithInitialState(initialState())
  .case(actions.sendInquiry, state => ({
    ...state,
    sendingInquiry: true,
    isSent: null,
  }))
  .case(actions.sentInquiry, (state, isSucceeded) => ({
    ...state,
    sendingInquiry: false,
    isSent: isSucceeded,
  }))
