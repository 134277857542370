import { createStyles, Theme } from '@material-ui/core'

export default (theme: Theme) =>
  createStyles({
    paper: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(3),
    },
    title: {
      fontWeight: 'bold',
      margin: '15px 0',
    },
    imageContainer: {
      margin: '15px 0',
    },
    image: {
      width: '100%',
      maxWidth: 600,
      maxHeight: 200,
      objectFit: 'contain',
    },
    video: {
      width: '100%',
      height: 300,
      maxWidth: 600,
      maxHeight: 300,
      marginBottom: 100,
    },
  })
