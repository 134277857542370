import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import {
  withStyles,
  WithStyles,
  AppBar,
  Avatar,
  Grid,
  Hidden,
  IconButton,
  Toolbar,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'

import items from './navigationItems'
import { headerStyles } from './styles'

type HeaderProps = RouteComponentProps &
  WithStyles<typeof headerStyles> & {
    name: string
    onDrawerToggle: () => void
    onLogOut: () => void
  }

function Header(props: HeaderProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const { location, classes, name, onDrawerToggle, onLogOut } = props

  let title = ''

  for (const { children } of items) {
    for (const { id, path } of children) {
      if (!location.pathname.includes(path)) continue
      title = id
      break
    }
  }

  return (
    <AppBar color="primary" position="sticky" elevation={0}>
      <Toolbar>
        <Grid container spacing={1} alignItems="center">
          <Hidden smUp>
            <Grid item xs>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                className={classes.menuButton}>
                <MenuIcon />
              </IconButton>
            </Grid>
          </Hidden>
          <Grid item>
            <Typography component="h1" variant="h4">
              {title}
            </Typography>
          </Grid>
          <Grid container item xs justify="flex-end">
            <IconButton
              color="inherit"
              className={classes.iconButtonAvatar}
              onClick={e => setAnchorEl(e.currentTarget)}>
              <Avatar>{name[0]}</Avatar>
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}>
              <MenuItem onClick={() => onLogOut()}>Logout</MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default withRouter(withStyles(headerStyles)(Header))
