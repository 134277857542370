import { createStyles, Theme } from '@material-ui/core'
import * as colors from '@material-ui/core/colors'

export default (theme: Theme) =>
  createStyles({
    paper: {
      marginBottom: theme.spacing(2),
    },
    title: {
      flex: '1 1 100%',
    },
    chipDefault: {
      margin: `0 ${theme.spacing(1)}px`,
      backgroundColor: colors.orange[500],
      color: colors.grey[50],
    },
    chipDemo: {
      margin: `0 ${theme.spacing(1)}px`,
      backgroundColor: colors.brown[500],
      color: colors.grey[50],
    },
  })
