import { createStyles, Theme } from '@material-ui/core'
import * as colors from 'utils/color'

export default (theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      minHeight: '100%',
    },
    logoContainer: {
      maxWidth: '90%',
    },
    logo: {
      width: '100%',
    },
    text: {
      marginBottom: theme.spacing(3),
    },
    button: {
      width: 200,
      height: 50,
      backgroundColor: colors.red,
      color: colors.white,
      fontWeight: 'bold',
    },
  })
