import React from 'react'
import clsx from 'clsx'
import { RouteComponentProps } from 'react-router-dom'
import {
  Container,
  CssBaseline,
  Typography,
  WithStyles,
  withStyles,
  Paper,
  Button,
  Grid,
} from '@material-ui/core'
import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  Refresh as RefreshIcon,
} from '@material-ui/icons'
import * as Device from 'react-device-detect'

import images from 'utils/images'
import VideoRecorder from 'components/VideoRecorder'
import { ContributionActions, ContributionStoreStates } from '.'
import styles from './styles'

type OwnProps = RouteComponentProps & WithStyles<typeof styles>

type ContributionProps = OwnProps & ContributionStoreStates & ContributionActions

type ContributionStates = {
  recording: boolean
  converting: boolean
}

class Contribution extends React.Component<ContributionProps, ContributionStates> {
  constructor(props: ContributionProps) {
    super(props)
    this.state = {
      recording: false,
      converting: false,
    }

    // 発話ワードの読み込み
    props.loadNextWord()
  }

  setPreviousWord() {
    this.setState({ recording: false })
    this.props.loadPreviousWord()
    this.props.clearContribution()
  }

  setNextWord() {
    this.setState({ recording: false })
    this.props.loadNextWord()
    this.props.clearContribution()
  }

  contributeFile(file: File | Blob | null | undefined) {
    const { list, word } = this.props.readingWords.contribution.data || {}
    if (!file || !list || !word) return

    this.props.contribute({ list, word, file })
  }

  render = (): React.ReactNode => {
    const { recording, converting } = this.state
    const { classes, movies, readingWords } = this.props
    const { loaded, data } = readingWords.contribution
    const { word: uploadsWord, uploading, error } = movies.contribution

    if (!loaded) return <></>

    if (!data) return <Typography color="secondary">エラーが発生しました</Typography>

    const { index, word, list } = data

    const changeWordButton = (type: 'random' | 'pre' | 'next') => {
      let disabled = uploading
      if (type === 'pre') disabled = disabled || index === 0
      if (type === 'next') disabled = disabled || index === list.numberOfWords - 1

      let icon = <RefreshIcon />
      if (type === 'pre') icon = <ArrowBackIcon />
      if (type === 'next') icon = <ArrowForwardIcon />

      let text = '他の言葉を選ぶ'
      if (type === 'pre') text = '前の言葉'
      if (type === 'next') text = '次の言葉'

      return (
        <Button
          component="label"
          color="primary"
          className={classes.button}
          disabled={disabled}
          onClick={() => (type === 'pre' ? this.setPreviousWord() : this.setNextWord())}>
          {icon}
          {text}
        </Button>
      )
    }

    const randomButton = changeWordButton('random')
    const previousButton = changeWordButton('pre')
    const nextButton = changeWordButton('next')

    const leftButton = list.isDefault ? <></> : previousButton
    const rightButton = list.isDefault ? randomButton : nextButton

    const isBrowser = Device.isBrowser && !Device.isIOS13 // iPadOS 対策
    const isMobile = Device.isMobile || Device.isIOS13 // iPadOS 対策

    return (
      <Container>
        <CssBaseline />

        <Paper className={classes.paper}>
          <Grid container direction="column" alignItems="center">
            <Typography align="left" className={classes.listTitle}>
              はじめに
            </Typography>
            <Grid item>
              <Typography variant="body2" align="center">
                認識精度を向上するために発話シーン投稿の協力をお願いしています。
              </Typography>
              <Typography variant="body2" align="center">
                ご協力いただける場合は、以下の言葉を発話したシーンの投稿をお願いします。
              </Typography>
            </Grid>
          </Grid>

          <Grid container direction="column" alignItems="center">
            <Typography align="left" className={classes.listTitle}>
              発話シーン投稿
            </Typography>
            <Grid item>
              {leftButton}
              {rightButton}
            </Grid>
            <Grid container item justify="center" alignItems="center">
              <Typography className={clsx(classes.title)} variant="h3" align="center">
                {word.text}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" align="center">
                とカメラに向かって話す動画像をアップロードしてください
              </Typography>
            </Grid>
            {isBrowser && !recording && (
              <Grid item>
                <Button
                  variant="outlined"
                  component="label"
                  color="primary"
                  disabled={uploading || converting}
                  onClick={() => this.setState({ recording: true })}
                  className={classes.button}>
                  動画像を撮影する
                </Button>
                <Button
                  variant="outlined"
                  component="label"
                  color="primary"
                  disabled={uploading || converting}
                  className={classes.button}>
                  ファイルから選択する
                  <input
                    type="file"
                    accept="video/*"
                    style={{ display: 'none' }}
                    onChange={async e => {
                      this.contributeFile(e.target.files?.item(0))
                    }}
                  />
                </Button>
              </Grid>
            )}
            {isBrowser && recording && (
              <VideoRecorder
                display={recording}
                onPost={data => {
                  this.setState({ recording: false })
                  this.contributeFile(data)
                }}
              />
            )}
            {isMobile && (
              <Grid item>
                <Button
                  variant="outlined"
                  component="label"
                  color="primary"
                  disabled={uploading || converting}
                  className={classes.button}>
                  投稿する
                  <input
                    type="file"
                    accept="video/*"
                    style={{ display: 'none' }}
                    onChange={e => this.contributeFile(e.target.files?.item(0))}
                  />
                </Button>
              </Grid>
            )}
            {converting && (
              <Grid item>
                <Typography color="primary">変換中...</Typography>
              </Grid>
            )}
            {uploading && (
              <Grid item>
                <Typography color="primary">アップロード中...</Typography>
              </Grid>
            )}
            {uploadsWord && !uploading && !error && (
              <Grid item>
                <Typography color="primary">アップロードが完了しました</Typography>
              </Grid>
            )}
            {error && (
              <Grid item>
                <Typography color="secondary">{error}</Typography>
              </Grid>
            )}
            <Grid item container direction="column" alignItems="center" className={classes.warning}>
              <Grid item>
                <Typography>以下の撮影例を参考にしてください。</Typography>
              </Grid>
              <Grid item>
                <img src={images.contributionSample} alt="sample" className={classes.image} />
              </Grid>
            </Grid>
            <Grid item>
              <ul className={classes.list}>
                <li>撮影中はカメラを動かさないでください。</li>
                <li>録画開始になってから口を動かし始めてください。</li>
                <li>発話開始の前後は口を閉じてください。</li>
                <li>顔は画面中央に位置するようにして発話してください。</li>
                <li>顔は画面中央を向いて発話してください。</li>
                <li>上の言葉を自然な発話速度・口の動きで発話してください。</li>
                <li>
                  早すぎる発話／ゆっくりすぎる発話、口を大きく開けすぎる発話／口の開きが小さい発話は誤認識の原因になります。
                </li>
                <li>
                  投稿されたシーンは正しい発話シーンであるかクチヨミチームで確認します。正しい発話シーンの場合、マイページの発話シーン本数が増えます。
                </li>
              </ul>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    )
  }
}

export default withStyles(styles)(Contribution)
