import moment from 'moment'
import { formatDateTime } from 'utils/moment'

export type MovieFields = {
  userId: string
  userName: string
  fileURL: string
  browserURL: string
  readingWordId: string
  word: string
  isValid: boolean
  isVerified: boolean
  isCancelled: boolean
  createdAt: number
}

export default class Movie {
  id: string
  userId: string
  userName: string
  fileURL: string
  browserURL: string
  readingWordId: string
  word: string
  isValid: boolean
  isVerified: boolean
  isCancelled: boolean
  createdAt: moment.Moment

  get status(): string {
    let status = '却下'
    if (this.isVerified && this.isValid) status = '承認'
    if (!this.isVerified) status = '未確認'
    if (this.isCancelled) status = '取下'
    return status
  }

  get createdDate(): string {
    return formatDateTime(this.createdAt)
  }

  constructor(
    id: string,
    userId: string,
    userName: string,
    fileURL: string,
    browserURL: string,
    readingWordId: string,
    word: string,
    isValid: boolean,
    isVerified: boolean,
    isCancelled: boolean,
    createdAt: moment.Moment,
  ) {
    this.id = id
    this.userId = userId
    this.userName = userName
    this.fileURL = fileURL
    this.browserURL = browserURL
    this.readingWordId = readingWordId
    this.word = word
    this.isValid = isValid
    this.isVerified = isVerified
    this.isCancelled = isCancelled
    this.createdAt = createdAt
  }

  static load(id: string, data: any): Movie | null {
    const { userId, userName, fileURL, browserURL, readingWordId, word, isVerified, createdAt } =
      data || {}
    let { isValid, isCancelled } = data || {}

    if (!userId || typeof userId !== 'string') {
      return null
    }

    if (!userName || typeof userName !== 'string') {
      return null
    }

    if (!fileURL || typeof fileURL !== 'string') {
      return null
    }

    if (!browserURL || typeof browserURL !== 'string') {
      return null
    }

    if (!readingWordId || typeof readingWordId !== 'string') {
      return null
    }

    if (!word || typeof word !== 'string') {
      return null
    }

    if (isValid == null || typeof isValid !== 'boolean') {
      isValid = false
    }

    if (isVerified == null || typeof isVerified !== 'boolean') {
      return null
    }

    if (isCancelled == null || typeof isVerified !== 'boolean') {
      isCancelled = false
    }

    if (!createdAt || typeof createdAt !== 'number') {
      return null
    }
    const createdDate = moment.unix(createdAt)

    return new Movie(
      id,
      userId,
      userName,
      fileURL,
      browserURL,
      readingWordId,
      word,
      isValid,
      isVerified,
      isCancelled,
      createdDate,
    )
  }

  get documentData(): MovieFields {
    return {
      userId: this.userId,
      userName: this.userName,
      fileURL: this.fileURL,
      browserURL: this.browserURL,
      readingWordId: this.readingWordId,
      word: this.word,
      isValid: this.isValid,
      isVerified: this.isVerified,
      isCancelled: this.isCancelled,
      createdAt: this.createdAt.unix(),
    }
  }

  get csvData(): string[] {
    return [
      this.id,
      this.userId,
      this.userName,
      this.readingWordId,
      this.word,
      this.createdDate,
      this.status,
    ]
  }

  static get csvHeader(): string[] {
    return ['ID', 'ユーザID', 'ユーザ名', 'ワードID', 'ワード', '投稿日時', '認証状態']
  }
}
