import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import {
  actions as wordsActions,
  ReadingWordsState,
  LoadContributionWordPayload,
} from 'redux/modules/readingWords'
import { actions as moviesActions, ContributePayload, MoviesState } from 'redux/modules/movies'
import Contribution from './Contribution'
import { AuthState } from 'redux/modules/auth'

export interface ContributionActions {
  loadNextWord: () => Action<LoadContributionWordPayload>
  loadPreviousWord: () => Action<LoadContributionWordPayload>
  contribute: (payload: ContributePayload) => Action<ContributePayload>
  clearContribution: () => Action<void>
}

export interface ContributionStoreStates {
  auth: AuthState
  movies: MoviesState
  readingWords: ReadingWordsState
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): ContributionActions {
  return {
    loadNextWord: () => dispatch(wordsActions.loadContributionWord('next')),
    loadPreviousWord: () => dispatch(wordsActions.loadContributionWord('previous')),
    contribute: payload => dispatch(moviesActions.contribute(payload)),
    clearContribution: () => dispatch(moviesActions.clearContribution()),
  }
}

function mapStateToProps(appState: AppState): ContributionStoreStates {
  return {
    auth: appState.auth,
    movies: appState.movies,
    readingWords: appState.readingWords,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contribution)
