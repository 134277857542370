import { channel } from 'redux-saga'
import { put, fork, takeEvery, take } from 'redux-saga/effects'
import moment from 'moment'
import firebase from 'firebase/app'

import firebaseApp from 'utils/firebaseApp'
import { APP_URL } from 'utils/constant'
import { convertAuthError } from 'utils/firebase'
import * as Auth from 'redux/modules/auth'
import User, { UserType } from 'redux/models/user'

type LogInAction = ReturnType<typeof Auth.actions.logIn>
type SignUpAction = ReturnType<typeof Auth.actions.signUp>
type ChangeEmailAction = ReturnType<typeof Auth.actions.changeEmail>
type ChangePasswordAction = ReturnType<typeof Auth.actions.changePassword>
type ResetPasswordAction = ReturnType<typeof Auth.actions.resetPassword>
type DeleteAccountAction = ReturnType<typeof Auth.actions.deleteAccount>

const redirectChannel = channel()

let userInfoUnsubscribe: (() => void) | null = null

const loadAuth = function* () {
  yield take(Auth.LOAD)

  // auth 情報読み込み
  firebaseApp.auth().onAuthStateChanged(async (firebaseUser) => {
    userInfoUnsubscribe && userInfoUnsubscribe()

    if (!firebaseUser) {
      redirectChannel.put(Auth.actions.syncAuth(null))
      return
    }

    userInfoUnsubscribe = firebaseApp
      .firestore()
      .doc(`users/${firebaseUser.uid}`)
      .onSnapshot((snapshot) => {
        const user = User.loadAuth(firebaseUser, snapshot.data())
        if (!user || user.isDeleted) {
          redirectChannel.put(Auth.actions.syncAuth(null))
        } else {
          redirectChannel.put(Auth.actions.syncAuth(user))
        }
      })
  })
}

const logIn = function* (action: LogInAction) {
  try {
    const { email, password } = action.payload

    const credential = yield firebaseApp.auth().signInWithEmailAndPassword(email, password)
    const firebaseUser: firebase.User = credential.user

    const userRef = yield firebaseApp.firestore().collection('users').doc(firebaseUser.uid).get()

    const user = User.loadAuth(firebaseUser, userRef.data())

    if (!user) {
      throw new Error()
    }

    yield put(Auth.actions.succeededToLogIn(user))
  } catch (e) {
    const message = (e.code && convertAuthError(e.code, 'login')) || 'ログインできませんでした。'
    yield put(Auth.actions.failedToLogIn(message))
  }
}

const logOut = function* () {
  try {
    yield firebaseApp.auth().signOut()
    yield put(Auth.actions.succeededToLogOut())
  } catch (e) {
    const message = 'なんらかの理由でログアウトできませんでした。'
    yield put(Auth.actions.failedToLogOut(message))
  }
}

const signUp = function* (action: SignUpAction) {
  try {
    const { name, email, password } = action.payload

    // ユーザ生成
    const credential = yield firebaseApp.auth().createUserWithEmailAndPassword(email, password)
    const firebaseUser: firebase.User = credential.user
    const date = moment()
    const user = new User(
      firebaseUser.uid,
      name,
      email,
      UserType.User,
      firebaseUser.emailVerified,
      null,
      0,
      0,
      false,
      date,
      date,
    )

    // メアド確認用のメールを送信
    yield credential.user.sendEmailVerification({ url: APP_URL })

    // ユーザ情報を firestore に登録
    yield firebaseApp.firestore().collection('users').doc(user.id).set(user.documentData)

    // メール送信
    try {
      yield firebaseApp.functions().httpsCallable('sendAccountCreatedMail')()
    } catch (e) {
      console.log(e)
    }

    yield put(Auth.actions.succeededToSignUp(user))
  } catch (e) {
    const message =
      (e.code && convertAuthError(e.code, 'signup')) || 'アカウントを作成できませんでした。'
    yield put(Auth.actions.failedToSignUp(message))
  }
}

const sendVerificationEmail = function* () {
  const user: firebase.User | null = firebaseApp.auth().currentUser
  if (user && !user.emailVerified) {
    yield user.sendEmailVerification({ url: APP_URL })
  }

  // 連続送信防止
  setTimeout(() => redirectChannel.put(Auth.actions.sentVerificationEmail()), 5000)
}

const changeEmail = function* (action: ChangeEmailAction) {
  const firebaseUser = firebaseApp.auth().currentUser
  if (!firebaseUser || !firebaseUser.email) return

  const { currentPassword, newEmail } = action.payload

  const credential = firebase.auth.EmailAuthProvider.credential(firebaseUser.email, currentPassword)

  try {
    // 再認証
    yield firebaseUser.reauthenticateWithCredential(credential)

    // Email 更新
    yield firebaseUser.updateEmail(newEmail)

    // 確認メール送信
    yield firebaseUser.sendEmailVerification({ url: APP_URL })

    // ユーザ情報を更新
    const userDoc = yield firebaseApp.firestore().doc(`users/${firebaseUser.uid}`).get()

    yield userDoc.ref.update({
      email: newEmail,
      updatedAt: moment().unix(),
    })

    const user = User.loadAuth(firebaseUser, userDoc.data())
    yield put(Auth.actions.syncAuth(user))

    // 完了アクション
    yield put(Auth.actions.succeededToChangeEmail())
  } catch (e) {
    const message =
      (e.code && convertAuthError(e.code, 'changeEmail')) ||
      'メールアドレスを更新できませんでした。'
    yield put(Auth.actions.failedToChangeEmail(message))
  }
}

const changePssword = function* (action: ChangePasswordAction) {
  const firebaseUser = firebaseApp.auth().currentUser
  if (!firebaseUser || !firebaseUser.email) return

  const { currentPassword, newPassword } = action.payload

  const credential = firebase.auth.EmailAuthProvider.credential(firebaseUser.email, currentPassword)

  try {
    // 再認証
    yield firebaseUser.reauthenticateWithCredential(credential)

    // Email 更新
    yield firebaseUser.updatePassword(newPassword)

    // ユーザ情報を更新
    const userDoc = yield firebaseApp.firestore().doc(`users/${firebaseUser.uid}`).get()

    yield userDoc.ref.update({ updatedAt: moment().unix() })

    const user = User.loadAuth(firebaseUser, userDoc.data())
    yield put(Auth.actions.syncAuth(user))

    // 完了アクション
    yield put(Auth.actions.succeededToChangePassword())
  } catch (e) {
    const message =
      (e.code && convertAuthError(e.code, 'changePassword')) || 'パスワードを更新できませんでした。'
    yield put(Auth.actions.failedToChangePassword(message))
  }
}

const resetPassword = function* (action: ResetPasswordAction) {
  yield firebaseApp.auth().sendPasswordResetEmail(action.payload, { url: APP_URL })

  // 連続送信防止
  setTimeout(() => redirectChannel.put(Auth.actions.completedToResetPassword()), 5000)
}

const deleteAccount = function* (action: DeleteAccountAction) {
  const password = action.payload
  const user = firebaseApp.auth().currentUser
  if (!user || !user.email) return

  try {
    const credential = firebase.auth.EmailAuthProvider.credential(user.email, password)

    // 再認証
    yield user.reauthenticateWithCredential(credential)

    // 削除
    yield firebaseApp.functions().httpsCallable('deleteAccount')({ userIds: [user.uid] })

    yield put(Auth.actions.completedToDeleteAccount(null))
  } catch (e) {
    const message =
      (e.code && convertAuthError(e.code, 'deleteAccount')) || 'アカウントを削除できませんでした。'
    console.error(message)
    yield put(Auth.actions.completedToDeleteAccount(message))
  }
}

export default function* dataSaga() {
  yield fork(loadAuth)

  yield takeEvery(Auth.LOGIN, logIn)
  yield takeEvery(Auth.LOGOUT, logOut)
  yield takeEvery(Auth.SIGNUP, signUp)
  yield takeEvery(Auth.SEND_VARIFICATION_EMAIL, sendVerificationEmail)
  yield takeEvery(Auth.CHANGE_EMAIL, changeEmail)
  yield takeEvery(Auth.CHANGE_PASSWORD, changePssword)
  yield takeEvery(Auth.RESET_PASSWORD, resetPassword)
  yield takeEvery(Auth.DELETE_ACCOUNT, deleteAccount)

  while (true) {
    const action = yield take(redirectChannel)
    yield put(action)
  }
}
