import React from 'react'
import {
  WithStyles,
  withStyles,
  Button,
  Theme,
  createStyles,
  Typography,
  Grid,
  Paper,
  Modal,
} from '@material-ui/core'
import Movie from 'redux/models/movie'
import { MoviesState } from 'redux/modules/movies'

const styles = (theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      padding: theme.spacing(4),
      overflow: 'scroll',
    },
    video: {},
    success: {
      color: theme.palette.success.main,
    },
  })

type VerificationModalProps = WithStyles<typeof styles> & {
  item: Movie
  movies: MoviesState
  onVerify: (isValid: boolean) => void
  onCancel: () => void
  onClose: () => void
}

function VerificationModal(props: VerificationModalProps) {
  const { classes, item, movies, onVerify, onCancel, onClose } = props
  const {
    item: verificationItem,
    verifing,
    verificationError,
    canceling,
    cancelError,
  } = movies.verification

  return (
    <Modal aria-labelledby="simple-modal-title" className={classes.modal} open onClose={onClose}>
      <Paper className={classes.paper}>
        <Grid container direction="column" alignItems="center" spacing={3}>
          <Grid item>
            <Typography variant="h4" id="simple-modal-title">
              {item.word}
            </Typography>
          </Grid>
          <Grid item>
            <video
              width={Math.min(window.screen.width * 0.7, 500)}
              height={Math.min(window.screen.height * 0.5, 500)}
              className={classes.video}
              src={item.fileURL}
              controls
            />
          </Grid>
          <Grid item>
            <a href={item.browserURL} target="blank">
              Google Drive で動画を見る
            </a>
          </Grid>
          {(!verificationItem || verifing) && (
            <Grid container item justify="center" spacing={3}>
              <Grid item>
                <Button
                  variant="outlined"
                  component="label"
                  color="secondary"
                  disabled={verifing}
                  onClick={() => onVerify(false)}>
                  無効にする
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  component="label"
                  color="primary"
                  disabled={verifing}
                  onClick={() => onVerify(true)}>
                  有効にする
                </Button>
              </Grid>
            </Grid>
          )}
          {verificationItem && !verifing && !verificationError && (
            <Grid container item justify="center" alignItems="center" spacing={3}>
              <Grid item>
                <Typography className={classes.success}>検証完了!</Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  component="label"
                  color="secondary"
                  disabled={canceling}
                  onClick={onCancel}>
                  検証を取り消す
                </Button>
              </Grid>
            </Grid>
          )}
          {verificationError && (
            <Grid>
              <Typography color="secondary">反映に失敗しました</Typography>
            </Grid>
          )}
          {cancelError && (
            <Grid>
              <Typography color="secondary">検証の取り消しに失敗しました</Typography>
            </Grid>
          )}
          <Grid item>
            <Button variant="outlined" component="label" onClick={onClose}>
              閉じる
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  )
}

export default withStyles(styles)(VerificationModal)
