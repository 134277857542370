import { createStyles, Theme } from '@material-ui/core'
import * as colors from '@material-ui/core/colors'

export default (theme: Theme) =>
  createStyles({
    paper: {
      marginBottom: theme.spacing(2),
    },
    title: {
      flex: '1 1 100%',
    },
    buttonUpdate: {
      margin: `0 ${theme.spacing(1)}px`,
    },
    button: {
      margin: `0 ${theme.spacing(1)}px`,
      color: colors.grey[500],
      backgroundColor: colors.grey[200],
      '&:hover': {
        backgroundColor: colors.grey[300],
      },
    },
    buttonDefault: {
      margin: `0 ${theme.spacing(1)}px`,
      backgroundColor: colors.orange[500],
      color: `${colors.grey[50]} !important`,
    },
    buttonDemo: {
      margin: `0 ${theme.spacing(1)}px`,
      backgroundColor: colors.brown[500],
      color: `${colors.grey[50]} !important`,
    },
    buttonCSV: {
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
  })
