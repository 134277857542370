import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { AuthState } from 'redux/modules/auth'
import {
  actions as notificationsActions,
  NotificationsState,
  Payload,
} from 'redux/modules/notifications'
import Edit from './Edit'

export interface EditActions {
  loadItem: (itemId: string) => Action<string>
  updateItem: (payload: Payload) => Action<Payload>
  deleteItem: () => Action<void>
}

export interface EditStoreStates {
  auth: AuthState
  notifications: NotificationsState
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): EditActions {
  return {
    loadItem: itemId => dispatch(notificationsActions.loadEditItem(itemId)),
    updateItem: payload => dispatch(notificationsActions.update(payload)),
    deleteItem: () => dispatch(notificationsActions.delete()),
  }
}

function mapStateToProps(appState: AppState): EditStoreStates {
  return { auth: appState.auth, notifications: appState.notifications }
}

export default connect(mapStateToProps, mapDispatchToProps)(Edit)
