import React, { ReactElement } from 'react'
import {
  Paper,
  WithStyles,
  withStyles,
  Theme,
  createStyles,
  Grid,
  Typography,
} from '@material-ui/core'

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      margin: theme.spacing(2),
      padding: theme.spacing(1),
      background: theme.palette.success.main,
      color: theme.palette.background.default,
      minWidth: 100,
    },
    title: {
      fontWeight: 'bold',
    },
  })

export type BoardProps = WithStyles<typeof styles> & {
  icon: ReactElement
  title: string
  description: string
}

function Board(props: React.PropsWithChildren<BoardProps>): React.ReactElement<BoardProps> {
  const { classes, icon, title, description } = props
  return (
    <Paper className={classes.paper}>
      <Grid container spacing={1} alignItems="center">
        <Grid item>{icon}</Grid>
        <Grid item xs container direction="column" alignItems="center" spacing={1}>
          <Grid item>
            <Typography variant="h5" className={classes.title}>
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography component="p" variant="h5">
              {description}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default withStyles(styles)(Board)
