import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  Container,
  CssBaseline,
  WithStyles,
  withStyles,
  Paper,
  Grid,
  Toolbar,
  FormControl,
  Select,
  Hidden,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TablePagination,
  Typography,
  Button,
  Checkbox,
} from '@material-ui/core'
import { CSVLink } from 'react-csv'
import { ListData, Order, UpdateListPayload } from 'redux/modules/users'
import User, { UserType } from 'redux/models/user'

import { ListActions, ListStoreStates } from '.'
import styles from './styles'

type OwnProps = RouteComponentProps & WithStyles<typeof styles>

type ListProps = OwnProps & ListStoreStates & ListActions

type ListStates = {
  selectedUsers: User[]
}

type ListHeader = {
  id: keyof ListData
  label: string
}

class List extends React.Component<ListProps, ListStates> {
  constructor(props: ListProps) {
    super(props)
    this.state = {
      selectedUsers: [],
    }

    // ユーザリストの読み込み
    if (!props.users.loaded) props.load()
  }

  componentDidUpdate = (prevProps: ListProps) => {
    const prevData = prevProps.users.deletion
    const data = this.props.users.deletion
    if (!prevData.deleting || data.deleting) return

    if (!data.isSucceeded) {
      window.alert('エラーが発生したため削除できませんでした')
    }
  }

  onSort(orderBy: keyof ListData, order?: Order) {
    const { users, updateList } = this.props
    const { list } = users

    const isAsc = list.orderBy === orderBy && list.order === 'asc'
    const page = 0

    const payload: UpdateListPayload = order
      ? { orderBy, order, page }
      : { orderBy, order: isAsc ? 'desc' : 'asc', page }

    updateList(payload)
  }

  onSelectAll(event: React.ChangeEvent<HTMLInputElement>) {
    const { users } = this.props
    const { listItems } = users.list
    this.setState({
      selectedUsers: event.target.checked ? listItems : [],
    })
  }

  onSelectCheckbox(user: User) {
    const { selectedUsers } = this.state
    if (!selectedUsers.find(u => u.id === user.id)) {
      this.setState({
        selectedUsers: selectedUsers.concat(user),
      })
    } else {
      this.setState({
        selectedUsers: selectedUsers.filter(u => u.id !== user.id),
      })
    }
  }

  onSelectRow(user: User) {
    const { history } = this.props
    history.push(`/users/${user.id}`)
  }

  onDeleteUsers = () => {
    const { selectedUsers } = this.state
    const { deleteUsers } = this.props
    const num = selectedUsers.length
    if (!window.confirm(`${num}人のユーザーを削除してもよろしいですか?`)) return

    deleteUsers(selectedUsers)
    this.setState({ selectedUsers: [] })
  }

  render = (): React.ReactNode => {
    const { selectedUsers } = this.state
    const { classes, auth, users, updateList } = this.props
    const { order, orderBy, sortedItems, listItems, page, rowsPerPage } = users.list

    const numSelected = selectedUsers.length
    const rowCount = listItems.length

    const csvData = users.items.map(item => item.csvData)
    csvData.unshift(User.csvHeader)

    if (users.loading) return <></>

    if (users.deletion.deleting)
      return (
        <Container>
          <CssBaseline />
          <Typography variant="h4" align="center">
            ユーザーを削除中
          </Typography>
          <Typography variant="body1" align="center">
            画面を離れずにお待ちください。
          </Typography>
        </Container>
      )

    const headCells: ListHeader[] = [
      { id: 'name', label: '名前' },
      { id: 'email', label: 'Email' },
      { id: 'createdAt', label: '登録日時' },
    ]

    return (
      <Container>
        <CssBaseline />

        <Paper className={classes.paper}>
          <Hidden smDown>
            <Toolbar>
              <Grid item xs />
              <Grid item>
                <Button variant="contained" component="label" color="primary">
                  <CSVLink
                    className={classes.buttonCSV}
                    filename="users.csv"
                    target="_blank"
                    data={csvData}
                  />
                  CSV エクスポート
                </Button>
              </Grid>
            </Toolbar>
          </Hidden>

          <Hidden mdUp>
            <Toolbar>
              <Grid item xs></Grid>
              <Grid>
                <FormControl size="small" variant="outlined" className={classes.formControl}>
                  <Select
                    native
                    value={`${orderBy}-${order}`}
                    onChange={e => {
                      const value = e.target.value as string
                      const [orderBy, order] = value.split('-')
                      this.onSort(orderBy as keyof ListData, order as Order)
                    }}>
                    <option value="name-asc">名前（昇順）</option>
                    <option value="name-desc">名前（降順）</option>
                    <option value="email-asc">Email（昇順）</option>
                    <option value="email-desc">Email（降順）</option>
                    <option value="createdAt-asc">登録日時（昇順）</option>
                    <option value="createdAt-desc">登録日時（降順）</option>
                  </Select>
                </FormControl>
              </Grid>
            </Toolbar>
          </Hidden>

          <TableContainer>
            <Table size="small">
              <TableHead>
                <Hidden mdUp>
                  <TableRow>
                    <TableCell>名前</TableCell>
                  </TableRow>
                </Hidden>
                <Hidden smDown>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={e => this.onSelectAll(e)}
                        inputProps={{ 'aria-label': 'select all users' }}
                      />
                    </TableCell>
                    {headCells.map(headCell => (
                      <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={() => this.onSort(headCell.id)}>
                          {headCell.label}
                          {orderBy === headCell.id ? (
                            <span className={classes.visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </Hidden>
              </TableHead>
              <TableBody>
                {listItems.map(user => {
                  const isItemSelected = !!selectedUsers.find(u => u.id === user.id)
                  const labelId = `enhanced-table-checkbox-${user.id}`

                  return (
                    <TableRow key={user.id} hover tabIndex={-1}>
                      <TableCell padding="checkbox" className="selectCheckbox">
                        <Checkbox
                          className="selectCheckbox"
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          onChange={() => this.onSelectCheckbox(user)}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row" onClick={() => this.onSelectRow(user)}>
                        {user.name}&emsp;
                        {user.type !== UserType.User && (
                          <Typography component="span" className={classes.userTypeLabel}>
                            [{user.userTypeText}]&emsp;
                          </Typography>
                        )}
                        {auth.user?.id === user.id && (
                          <Typography component="span" className={classes.myselfLabel}>
                            (You)
                          </Typography>
                        )}
                        <Hidden smDown>
                          <Typography className={classes.identifier}>{user.id}</Typography>
                        </Hidden>
                        <Hidden mdUp>
                          <Typography className={classes.identifier}>{user.email}</Typography>
                        </Hidden>
                      </TableCell>
                      <Hidden smDown>
                        <TableCell onClick={() => this.onSelectRow(user)}>{user.email}</TableCell>
                        <TableCell onClick={() => this.onSelectRow(user)}>
                          {user.createdDate}
                        </TableCell>
                      </Hidden>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid container direction="row">
            <Grid xs item>
              {numSelected > 0 && (
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.deleteButton}
                  onClick={() => this.onDeleteUsers()}>
                  選択したユーザーを削除
                </Button>
              )}
            </Grid>
            <Grid item>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={sortedItems.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={(e, page) => updateList({ page })}
                onChangeRowsPerPage={e => {
                  updateList({
                    page: 0,
                    rowsPerPage: parseInt(e.target.value) || 10,
                  })
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Container>
    )
  }
}

export default withStyles(styles)(List)
