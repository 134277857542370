import React from 'react'
import { RouteComponentProps, Redirect, Link as RouterLink } from 'react-router-dom'
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Link,
  TextField,
  Typography,
  WithStyles,
  withStyles,
  CircularProgress,
} from '@material-ui/core'

import Copyright from 'components/Copyright'

import { SignupActions, SignupStoreStates } from '.'
import styles from './styles'

type OwnProps = RouteComponentProps & WithStyles<typeof styles>

type SignupProps = OwnProps & SignupStoreStates & SignupActions

interface SignupStates {
  name: string
  email: string
  password: string
  confirmationPassword: string
  // validation
  isEmpty: boolean
  isMatchPassword: boolean
}

type SignupItem = Omit<SignupStates, 'isEmpty' | 'isMatchPassword'>

class Signup extends React.Component<SignupProps, SignupStates> {
  constructor(props: SignupProps) {
    super(props)
    this.state = {
      name: '',
      email: '',
      password: '',
      confirmationPassword: '',
      isEmpty: true,
      isMatchPassword: true,
    }
  }

  onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const { name, email, password, isEmpty, isMatchPassword } = this.state

    if (isEmpty || !isMatchPassword) return

    this.props.signUp({ name, email, password })
  }

  onChangeValue(key: keyof SignupItem, value: string) {
    const state = { ...this.state }
    state[key] = value
    state.isEmpty = Object.values(state).includes('')
    state.isMatchPassword = state.password === state.confirmationPassword
    this.setState(state)
  }

  render = (): React.ReactNode => {
    const { classes, auth } = this.props
    const { signingUp, signUpError, user } = auth
    const { name, email, password, confirmationPassword, isEmpty, isMatchPassword } = this.state

    if (user) {
      return <Redirect to="/notifications" />
    }

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img src="/images/logo.png" alt="logo" />

          <Typography color="textSecondary" component="h5" variant="h6">
            アカウント作成
          </Typography>

          {signUpError && (
            <Typography color="error" component="h1" variant="h6">
              {auth.signUpError}
            </Typography>
          )}

          <form className={classes.form} noValidate onSubmit={(e) => this.onSubmit(e)}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="名前"
              type="text"
              autoComplete="name"
              autoFocus
              value={name}
              onChange={({ target }) => this.onChangeValue('name', target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="メールアドレス"
              type="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={({ target }) => this.onChangeValue('email', target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="パスワード"
              autoComplete="password"
              type="password"
              value={password}
              onChange={({ target }) => this.onChangeValue('password', target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="パスワード（確認用）"
              autoComplete="confirmation_password"
              type="password"
              value={confirmationPassword}
              error={!isMatchPassword}
              helperText={!isMatchPassword && 'パスワードが一致しません'}
              onChange={({ target }) => this.onChangeValue('confirmationPassword', target.value)}
            />

            <Typography align="center" color="textSecondary" component="h5" variant="h6">
              ※ 名前は後から変更できません
            </Typography>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isEmpty || !isMatchPassword || signingUp}>
              {signingUp ? <CircularProgress size={20} /> : 'サインアップ'}
            </Button>
          </form>
          <Grid container>
            <Grid item xs />
            <Grid item>
              <Link to="/login" component={RouterLink}>
                アカウントをお持ちの方はこちら
              </Link>
            </Grid>
          </Grid>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    )
  }
}

export default withStyles(styles)(Signup)
