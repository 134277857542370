import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import User, { UserType } from 'redux/models/user'

// Actions
export const LOAD = 'lip-reading/users/LOAD'
export const SYNC_USERS = 'lip-reading/users/SYNC_USERS'
export const UPDATE_LIST_ITEMS = 'lip-reading/users/UPDATE_LIST_ITEMS'
export const UPDATE_LIST_ITEMS_COMPLETION = 'lip-reading/users/UPDATE_LIST_ITEMS_COMPLETION'
export const CHANGE_USER_TYPE = 'lip-reading/users/CHANGE_USER_TYPE'
export const CHANGE_USER_TYPE_COMPLETION = 'lip-reading/users/CHANGE_USER_TYPE_COMPLETION'
export const CHANGE_WORD_LIST = 'lip-reading/users/CHANGE_WORD_LIST'
export const CHANGE_WORD_LIST_COMPLETION = 'lip-reading/users/CHANGE_WORD_LIST_COMPLETION'
export const DELETE_USERS = 'lip-reading/users/DELETE_USERS'
export const DELETE_USERS_COMPLETION = 'lip-reading/users/DELETE_USERS_COMPLETION'

// interface
export type ListData = Pick<User, 'name' | 'email' | 'createdAt'>

// Action Creators
const actionCreator = actionCreatorFactory()

export type Order = 'asc' | 'desc'

export type UpdateListPayload = {
  order?: Order
  orderBy?: keyof ListData
  page?: number
  rowsPerPage?: number
}

export type UpdatedListPayload = {
  sortedItems: User[]
  listItems: User[]
}

export type ChangeUserTypePayload = {
  user: User
  type: UserType
}

export type ChangeWordListPayload = {
  user: User
  listId: string
}

export const actions = {
  load: actionCreator(LOAD),
  sync: actionCreator<User[]>(SYNC_USERS),
  updateList: actionCreator<UpdateListPayload>(UPDATE_LIST_ITEMS),
  completedToUpdateList: actionCreator<UpdatedListPayload>(UPDATE_LIST_ITEMS_COMPLETION),
  changeUserType: actionCreator<ChangeUserTypePayload>(CHANGE_USER_TYPE),
  completedToChangeUserType: actionCreator(CHANGE_USER_TYPE_COMPLETION),
  changeWordList: actionCreator<ChangeWordListPayload>(CHANGE_WORD_LIST),
  completedToChangeWordList: actionCreator(CHANGE_WORD_LIST_COMPLETION),
  deleteUsers: actionCreator<User[]>(DELETE_USERS),
  completedToDeleteUsers: actionCreator<boolean>(DELETE_USERS_COMPLETION),
}

// Reducer
export interface UsersState {
  loading: boolean
  loaded: boolean
  items: User[]
  list: {
    sortedItems: User[]
    listItems: User[]
    order: Order
    orderBy: keyof ListData
    page: number
    rowsPerPage: number
  }
  userTypeChanging: boolean
  wordListChanging: boolean
  deletion: {
    deleting: boolean
    isSucceeded: boolean
  }
}

const initialState: () => UsersState = () => ({
  loading: false,
  loaded: false,
  items: [],
  list: {
    sortedItems: [],
    listItems: [],
    order: 'asc',
    orderBy: 'name',
    page: 0,
    rowsPerPage: 10,
  },
  userTypeChanging: false,
  wordListChanging: false,
  deletion: {
    deleting: false,
    isSucceeded: false,
  },
})

export default reducerWithInitialState(initialState())
  .case(actions.load, state => ({
    ...state,
    loading: true,
  }))
  .case(actions.sync, (state, items) => ({
    ...state,
    loading: false,
    loaded: true,
    items,
  }))
  .case(actions.updateList, (state, payload) => {
    const list = state.list
    return {
      ...state,
      list: {
        ...list,
        ...payload,
      },
    }
  })
  .case(actions.completedToUpdateList, (state, payload) => {
    const list = state.list
    return {
      ...state,
      list: {
        ...list,
        sortedItems: payload.sortedItems,
        listItems: payload.listItems,
      },
    }
  })
  .case(actions.changeUserType, state => ({
    ...state,
    userTypeChanging: true,
  }))
  .case(actions.completedToChangeUserType, state => ({
    ...state,
    userTypeChanging: false,
  }))
  .case(actions.changeWordList, state => ({
    ...state,
    wordListChanging: true,
  }))
  .case(actions.completedToChangeWordList, state => ({
    ...state,
    wordListChanging: false,
  }))
  .case(actions.deleteUsers, state => ({
    ...state,
    deletion: {
      deleting: true,
      isSucceeded: false,
    },
  }))
  .case(actions.completedToDeleteUsers, (state, isSucceeded) => ({
    ...state,
    deletion: {
      deleting: false,
      isSucceeded,
    },
  }))
