import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { AuthState } from 'redux/modules/auth'
import {
  actions as notificationsActions,
  NotificationsState,
  Payload,
} from 'redux/modules/notifications'
import New from './New'

export interface NewActions {
  addItem: (payload: Payload) => Action<Payload>
}

export interface NewStoreStates {
  auth: AuthState
  notifications: NotificationsState
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): NewActions {
  return {
    addItem: payload => dispatch(notificationsActions.add(payload)),
  }
}

function mapStateToProps(appState: AppState): NewStoreStates {
  return { auth: appState.auth, notifications: appState.notifications }
}

export default connect(mapStateToProps, mapDispatchToProps)(New)
