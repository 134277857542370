import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

import { firebase as firebaseConstants } from 'utils/constant'

const config = {
  apiKey: firebaseConstants.apiKey,
  authDomain: firebaseConstants.authDomain,
  databaseURL: firebaseConstants.databaseURL,
  projectId: firebaseConstants.projectId,
  storageBucket: firebaseConstants.storageBucket,
  messagingSenderId: firebaseConstants.messagingSenderId,
  appId: firebaseConstants.appId,
  measurementId: firebaseConstants.measurementId,
}

export default firebase.initializeApp(config)

// firebase.functions().useFunctionsEmulator('http://localhost:5001')
