import { channel } from 'redux-saga'
import { put, takeEvery, take, SelectEffect, select } from 'redux-saga/effects'

import firebaseApp from 'utils/firebaseApp'
import { AppState } from 'redux/store'
import { AuthState } from 'redux/modules/auth'
import * as Inquiry from 'redux/modules/inquiry'

type SendInquiryAction = ReturnType<typeof Inquiry.actions.sendInquiry>

const redirectChannel = channel()

const selectState = <T>(selector: (s: AppState) => T): SelectEffect => {
  return select(selector)
}

const sendInquiry = function*(action: SendInquiryAction) {
  const auth: AuthState = yield selectState(s => s.auth)
  const user = auth.user
  if (!user) return

  const { title, content } = action.payload

  try {
    yield firebaseApp.functions().httpsCallable('sendInquiry')({ title, content })
    // 完了アクション
    yield put(Inquiry.actions.sentInquiry(true))
  } catch (e) {
    console.log(e)
    yield put(Inquiry.actions.sentInquiry(false))
  }
}

export default function* dataSaga() {
  yield takeEvery(Inquiry.SEND_INQUIRY, sendInquiry)

  while (true) {
    const action = yield take(redirectChannel)
    yield put(action)
  }
}
