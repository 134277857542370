import { channel } from 'redux-saga'
import { put, fork, take, takeEvery, SelectEffect, select } from 'redux-saga/effects'

import firebase from 'utils/firebaseApp'
import * as Users from 'redux/modules/users'
import User from 'redux/models/user'
import { AppState } from 'redux/store'

type UpdateListAction = ReturnType<typeof Users.actions.updateList>
type ChangeUserTypeAction = ReturnType<typeof Users.actions.changeUserType>
type ChangeWordListAction = ReturnType<typeof Users.actions.changeWordList>
type DeleteUsersAction = ReturnType<typeof Users.actions.deleteUsers>

const redirectChannel = channel()
const collectionRef = firebase.firestore().collection('users')
const userRef = (uid: string) => firebase.firestore().doc(`users/${uid}`)

const selectState = <T>(selector: (s: AppState) => T): SelectEffect => {
  return select(selector)
}

// users の同期
const load = function*() {
  yield take(Users.LOAD)

  collectionRef.onSnapshot(snapshot => {
    const items: User[] = []
    snapshot.docs.forEach(doc => {
      const item = User.load(doc.id, doc.data())
      item && !item.isDeleted && items.push(item)
    })

    redirectChannel.put(Users.actions.sync(items))
    redirectChannel.put(Users.actions.updateList({}))
  })
}

const updateList = function*(action: UpdateListAction) {
  const payload = action.payload
  const users: Users.UsersState = yield selectState(s => s.users)
  const { items, list } = users

  const order = payload.order || list.order
  const orderBy = payload.orderBy || list.orderBy
  const page = payload.page || list.page
  const rowsPerPage = payload.rowsPerPage || list.rowsPerPage

  const sortedItems = items.sort((a, b) => {
    return order === 'asc'
      ? ('' + a[orderBy]).localeCompare('' + b[orderBy])
      : ('' + b[orderBy]).localeCompare('' + a[orderBy])
  })

  const listItems = sortedItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  yield put(Users.actions.completedToUpdateList({ sortedItems, listItems }))
}

const changeUserType = function*(action: ChangeUserTypeAction) {
  const { user, type } = action.payload

  yield userRef(user.id).update({ type })

  // 連続防止
  setTimeout(() => redirectChannel.put(Users.actions.completedToChangeUserType()), 1000)
}

const changeWordList = function*(action: ChangeWordListAction) {
  const { user, listId } = action.payload

  yield userRef(user.id).update({ readingWordListId: listId })

  // 連続防止
  setTimeout(() => redirectChannel.put(Users.actions.completedToChangeWordList()), 1000)
}

const deleteUsers = function*(action: DeleteUsersAction) {
  try {
    const userIds = action.payload.map(user => user.id)
    yield firebase.functions().httpsCallable('deleteAccount')({ userIds })
    yield put(Users.actions.completedToDeleteUsers(true))
  } catch (error) {
    console.error(error)
    yield put(Users.actions.completedToDeleteUsers(false))
  }
}

export default function* dataSaga() {
  yield fork(load)
  yield takeEvery(Users.UPDATE_LIST_ITEMS, updateList)
  yield takeEvery(Users.CHANGE_USER_TYPE, changeUserType)
  yield takeEvery(Users.CHANGE_WORD_LIST, changeWordList)
  yield takeEvery(Users.DELETE_USERS, deleteUsers)

  while (true) {
    const action = yield take(redirectChannel)
    yield put(action)
  }
}
