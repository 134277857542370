import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { actions as wordsActions, ReadingWordsState } from 'redux/modules/readingWords'
import WordList from './WordList'

export interface WordListActions {
  loadList: () => Action<void>
  addList: (file: File) => Action<File>
}

export interface WordListStoreStates {
  readingWords: ReadingWordsState
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): WordListActions {
  return {
    loadList: () => dispatch(wordsActions.loadList()),
    addList: file => dispatch(wordsActions.addList(file)),
  }
}

function mapStateToProps(appState: AppState): WordListStoreStates {
  return { readingWords: appState.readingWords }
}

export default connect(mapStateToProps, mapDispatchToProps)(WordList)
