import moment from 'moment'
import { formatDateTime } from 'utils/moment'

export type NotificationFields = {
  userId: string
  title: string
  content: string
  createdAt: number
  updatedAt: number
}

export default class Notification {
  id: string
  userId: string
  title: string
  content: string
  createdAt: moment.Moment
  updatedAt: moment.Moment

  get createdDate(): string {
    return formatDateTime(this.createdAt)
  }

  get updatedDate(): string {
    return formatDateTime(this.updatedAt)
  }

  constructor(
    id: string,
    userId: string,
    title: string,
    content: string,
    createdAt: moment.Moment,
    updatedAt: moment.Moment,
  ) {
    this.id = id
    this.userId = userId
    this.title = title
    this.content = content
    this.createdAt = createdAt
    this.updatedAt = updatedAt
  }

  static load(id: string, data: any): Notification | null {
    const { userId, title, content, createdAt, updatedAt } = data || {}

    if (!userId || typeof userId !== 'string') {
      return null
    }

    if (!title || typeof title !== 'string') {
      return null
    }

    if (!content || typeof content !== 'string') {
      return null
    }

    if (!createdAt || typeof createdAt !== 'number') {
      return null
    }
    const createdDate = moment.unix(createdAt)

    if (!updatedAt || typeof updatedAt !== 'number') {
      return null
    }
    const updatedDate = moment.unix(updatedAt)

    return new Notification(id, userId, title, content, createdDate, updatedDate)
  }

  get documentData(): NotificationFields {
    return {
      userId: this.userId,
      title: this.title,
      content: this.content,
      createdAt: this.createdAt.unix(),
      updatedAt: this.updatedAt.unix(),
    }
  }
}
