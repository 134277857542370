import { ReadingWordField } from 'redux/models/readingWord'
import { WeightCounts, initialWeigtCounts, toWeight } from 'redux/models/readingWordList'

export type ReadingWordsOutput = {
  title: string
  fields: ReadingWordField[]
  weightCounts: WeightCounts
}

export default class CSVReader {
  private static readCSV(file: File): Promise<{ title: string; rows: string[][] }> {
    return new Promise((resolve, reject) => {
      const fileNameArray = file.name.split('.')
      fileNameArray.pop()
      const title = fileNameArray.join('.')

      if (title === '') return reject('File name is invalid')

      const fileReader = new FileReader()
      fileReader.onloadend = () => {
        const content = fileReader.result

        if (typeof content !== 'string') return reject('File format is invalid')

        const lines = content.split('\n').filter(line => line !== '')
        const rows = lines.map(line => line.split(','))
        resolve({ title, rows })
      }
      fileReader.onerror = () => {
        reject("File can't read")
      }
      fileReader.readAsText(file)
    })
  }

  static async readAsReadingWords(file: File): Promise<ReadingWordsOutput> {
    const { title, rows } = await this.readCSV(file)

    const fields: ReadingWordField[] = []
    const weightCounts = initialWeigtCounts()

    rows.forEach((row, index) => {
      if (row.length !== 2) throw new Error("This file isn't ReadingWord CSV")

      const text = row[0]
      const weight = toWeight(+row[1])
      const indexByWeight = weightCounts[weight]
      weightCounts[weight] += 1

      const field: ReadingWordField = {
        text,
        weight,
        index,
        indexByWeight,
        isEnabled: true,
      }

      fields.push(field)
    })

    return { title, fields, weightCounts }
  }
}
