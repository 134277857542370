import moment from 'moment'
import { formatDateTime } from 'utils/moment'

export type WeightCounts = {
  0.1: number
  0.2: number
  0.3: number
  0.4: number
  0.5: number
  0.6: number
  0.7: number
  0.8: number
  0.9: number
  1.0: number
}

export const initialWeigtCounts: () => WeightCounts = () => ({
  0.1: 0,
  0.2: 0,
  0.3: 0,
  0.4: 0,
  0.5: 0,
  0.6: 0,
  0.7: 0,
  0.8: 0,
  0.9: 0,
  1.0: 0,
})

export const toWeight = (value: number): keyof WeightCounts => {
  switch (value) {
    case 0.1:
      return 0.1
    case 0.2:
      return 0.2
    case 0.3:
      return 0.3
    case 0.4:
      return 0.4
    case 0.5:
      return 0.5
    case 0.6:
      return 0.6
    case 0.7:
      return 0.7
    case 0.8:
      return 0.8
    case 0.9:
      return 0.9
    case 1.0:
      return 1.0
    default:
      return 1.0
  }
}

export default class ReadingWordList {
  id: string
  title: string
  weigthCounts: WeightCounts
  isDefault: boolean
  canPredict: boolean
  updatedAt: moment.Moment

  get numberOfWords(): number {
    return Object.keys(this.weigthCounts).reduce(
      (num, key) => num + this.weigthCounts[toWeight(parseFloat(key))],
      0,
    )
  }

  get updatedDate(): string {
    return formatDateTime(this.updatedAt)
  }

  constructor(
    id: string,
    title: string,
    weigthCounts: WeightCounts,
    isDefault: boolean,
    canPredict: boolean,
    updatedAt: moment.Moment,
  ) {
    this.id = id
    this.title = title
    this.weigthCounts = weigthCounts
    this.isDefault = isDefault
    this.canPredict = canPredict
    this.updatedAt = updatedAt
  }

  static load(id: string, data: any): ReadingWordList | null {
    const { title, weightCounts, isDefault, canPredict, updatedAt } = data

    if (!title || typeof title !== 'string') {
      return null
    }

    if (!weightCounts || typeof weightCounts !== 'object') {
      return null
    }
    const counts = { ...initialWeigtCounts(), ...weightCounts }

    if (isDefault === undefined || typeof isDefault !== 'boolean') {
      return null
    }

    if (canPredict === undefined || typeof canPredict !== 'boolean') {
      return null
    }

    if (!updatedAt || typeof updatedAt !== 'number') {
      return null
    }
    const updatedDate = moment.unix(updatedAt)

    return new ReadingWordList(id, title, counts, isDefault, canPredict, updatedDate)
  }
}
