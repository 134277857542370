import { createStyles, Theme } from '@material-ui/core'

export default (theme: Theme) =>
  createStyles({
    paper: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1),
    },
    name: {
      fontWeight: 'bold',
    },
    identifier: {
      color: theme.palette.grey[500],
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    button: {
      margin: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
      width: '90%',
    },
  })
