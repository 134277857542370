import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  Container,
  CssBaseline,
  Typography,
  WithStyles,
  withStyles,
  Paper,
  Grid,
} from '@material-ui/core'

import { demonstrationImages } from 'utils/images'
import { DemonstrationManualActions, DemonstrationManualStoreStates } from '.'
import styles from './styles'

type OwnProps = RouteComponentProps & WithStyles<typeof styles>

type DemonstrationManualProps = OwnProps &
  DemonstrationManualStoreStates &
  DemonstrationManualActions

const DemonstrationManual = (props: DemonstrationManualProps) => {
  const { classes } = props

  const items = [
    {
      title: '1．撮影方法',
      sections: [
        [
          '読唇に適した撮影方法でない場合、顔の検出に失敗したり、誤認識の原因になります。以下の撮影方法に従って発話シーンを撮影してください。',
          '・発話開始の前後は口を閉じて下さい。録画開始になってから口を動かし始めてください。',
        ],
        [
          '・顔は画面中央を向いて発話してください。少し斜めを向く場合は認識できますが、横を向く場合は顔が検出できないため読唇できません。',
        ],
        [
          '・顔は画面中央に位置するようにして発話してください。カメラから離れすぎて顔が小さく写る場合、カメラに近すぎて顔が大きく写る場合は、顔が検出できないため読唇できません。',
        ],
        [
          '・撮影中はカメラを動かさないでください。',
          '・撮影を意識せずに、自然な発話速度、自然な口の動きで発話してください。早すぎる発話やゆっくりすぎる発話、口を大きく開けすぎる発話や口の開きが小さい発話は誤認識の原因になります。',
          '・撮影時間が短すぎる場合、あるいは長すぎる場合は処理できません。',
          '・認識対象の言葉以外を発話しないでください。',
        ],
      ],
    },
    {
      title: '２．利用方法',
      sections: [
        ['・声を出さなくても利用可能です。'],
        ['・音声を利用しませんので、騒音環境下でも利用可能です。'],
        ['・声を出さなくても認識できますので、静かな場所でも利用可能です。'],
        ['・他人撮りも可能です。'],
      ],
    },
  ]

  return (
    <Container>
      <CssBaseline />

      <Paper className={classes.paper}>
        <Grid container direction="column">
          {items.map(({ title, sections }, ii) => (
            <React.Fragment key={`item-${ii}`}>
              <Typography className={classes.title}>{title}</Typography>
              {sections.map((section, si) => {
                const index = ii * 4 + si
                return (
                  <React.Fragment key={`section-${index}`}>
                    {section.map((text, key) => (
                      <Typography key={`section-item-${key}`}>{text}</Typography>
                    ))}
                    <Grid container className={classes.imageContainer}>
                      <img
                        src={demonstrationImages[index]}
                        alt={`demo-${index}`}
                        className={classes.image}
                      />
                    </Grid>
                  </React.Fragment>
                )
              })}
            </React.Fragment>
          ))}

          <Typography align="left" className={classes.title}>
            ３．動画説明
          </Typography>
          <iframe
            title="demonstration-movie"
            className={classes.video}
            src="https://www.youtube.com/embed/Oz7e0ODi28A"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Grid>
      </Paper>
    </Container>
  )
}

export default withStyles(styles)(DemonstrationManual)
