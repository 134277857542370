import moment from 'moment'
import { channel } from 'redux-saga'
import {
  put,
  fork,
  takeEvery,
  take,
  SelectEffect,
  select,
  ChannelTakeEffect,
} from 'redux-saga/effects'
import axios, { AxiosResponse } from 'axios'
import firebase from 'firebase/app'

import { APP_URL } from 'utils/constant'
import firebaseApp from 'utils/firebaseApp'
import { AppState } from 'redux/store'
import { AuthState } from 'redux/modules/auth'
import * as Movies from 'redux/modules/movies'
import Movie from 'redux/models/movie'
import ReadingResult from 'redux/models/ReadingResult'

type VerifyAction = ReturnType<typeof Movies.actions.verify>
type ContributeAction = ReturnType<typeof Movies.actions.contribute>
type UploadDemonstrationMovieAction = ReturnType<typeof Movies.actions.uploadDemonstrationMovie>
type CancelUploadedMovieAction = ReturnType<typeof Movies.actions.cancelUploadedMovie>

const redirectChannel = channel()
const collectionRef = firebaseApp.firestore().collection('movies')

const selectState = <T>(selector: (s: AppState) => T): SelectEffect => {
  return select(selector)
}

// movies の同期
const load = function*() {
  yield take(Movies.LOAD)

  const auth: AuthState = yield selectState(s => s.auth)
  const authUser = auth.user
  if (!authUser) return

  collectionRef.onSnapshot(snapshot => {
    let items: Movie[] = []
    snapshot.docs.forEach(doc => {
      const item = Movie.load(doc.id, doc.data())
      if (item && (authUser.isEditor || item.userId === authUser.id)) items.push(item)
    })
    items = items.sort((a, b) => a.createdAt.unix() - b.createdAt.unix()) // 昇順にソート
    redirectChannel.put(Movies.actions.sync(items))
  })
}

const verifyMovie = function*(action: VerifyAction) {
  const { item, isValid } = action.payload
  const timestamp = moment().unix()

  try {
    yield collectionRef.doc(item.id).update({
      isVerified: true,
      isValid,
      verifiedAt: timestamp,
    })

    // ユーザーの方も更新
    yield firebaseApp
      .firestore()
      .doc(`users/${item.userId}`)
      .update({
        numberOfVerifiedMovies: firebase.firestore.FieldValue.increment(1),
      })

    yield put(Movies.actions.succeededToVerify())
  } catch (err) {
    console.error(err)
    yield put(Movies.actions.failedToVerify())
  }
}

const cancelVerification = function*() {
  const movies: Movies.MoviesState = yield selectState(s => s.movies)
  const { item } = movies.verification

  try {
    yield item &&
      collectionRef.doc(item.id).update({
        isVerified: false,
        isValid: null,
        verifiedAt: null,
      })
    yield put(Movies.actions.succeededToCancel())
  } catch (err) {
    console.error(err)
    yield put(Movies.actions.failedToCancel())
  }
}

const contribute = function*(action: ContributeAction) {
  const token: string = yield firebase.auth().currentUser?.getIdToken()

  if (!token) return

  const url = `${APP_URL}/api/contribute`

  const { list, word, file } = action.payload

  const params = new FormData()
  params.append('readingWordListId', list.id)
  params.append('wordId', word.id)
  params.append('movie', file)

  const headers = {
    Authorization: `Bearer ${token}`,
  }

  try {
    yield axios.post(url, params, { headers: headers })
    yield put(Movies.actions.succeededToContribute())
  } catch (err) {
    console.error(err)
    yield put(Movies.actions.failedToContribute('アップロードに失敗しました'))
  }
}

const uploadDemonstrationMovie = function*(action: UploadDemonstrationMovieAction) {
  const auth: AuthState = yield selectState(s => s.auth)
  const user = auth.user
  if (!user) return

  const params = new FormData()
  params.append('inputMovieFile', action.payload)
  params.append('userId', user.id)
  params.append('userName', user.name)

  try {
    const res: AxiosResponse = yield axios.post(
      'https://www.slab.ces.kyutech.ac.jp/VSR25w/VSR25w.php',
      params,
    )
    const result = res.data as ReadingResult
    yield put(Movies.actions.completedToUploadDemonstrationMovie(result))
  } catch (err) {
    console.error(err)
    const data = ReadingResult.makeError(500, '通信に失敗しました。')
    yield put(Movies.actions.completedToUploadDemonstrationMovie(data))
  }
}

const cancelUploadedMovie = function*(action: CancelUploadedMovieAction) {
  try {
    yield collectionRef.doc(action.payload.id).update({ isCancelled: true })
    yield put(Movies.actions.completedToCancelUploadedMovie(false))
  } catch (err) {
    console.error(err)
    yield put(Movies.actions.completedToCancelUploadedMovie(true))
  }
}

export default function* dataSaga() {
  yield fork(load)
  yield takeEvery(Movies.VERIFY_ITEM, verifyMovie)
  yield takeEvery(Movies.CANCEL_VERIFIED_ITEM, cancelVerification)
  yield takeEvery(Movies.CONTRIBUTE_MOVIE, contribute)
  yield takeEvery(Movies.UPLOAD_DEMONSTRATION_MOVIE, uploadDemonstrationMovie)
  yield takeEvery(Movies.CANCEL_UPLOADED_MOVIE, cancelUploadedMovie)

  while (true) {
    const action: ChannelTakeEffect<unknown> = yield take(redirectChannel)
    yield put(action)
  }
}
