import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  Button,
  Container,
  CssBaseline,
  TextField,
  Typography,
  WithStyles,
  withStyles,
  Paper,
} from '@material-ui/core'

import { ResetPasswordActions, ResetPasswordStoreStates } from '.'
import styles from './styles'

type OwnProps = RouteComponentProps & WithStyles<typeof styles>

type ResetPasswordProps = OwnProps & ResetPasswordStoreStates & ResetPasswordActions

interface ResetPasswordStates {
  email: string
  // validation
  isEmpty: boolean
}

class ResetPassword extends React.Component<ResetPasswordProps, ResetPasswordStates> {
  constructor(props: ResetPasswordProps) {
    super(props)
    this.state = {
      email: '',
      isEmpty: true,
    }
  }

  onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const { email, isEmpty } = this.state

    if (isEmpty) return

    this.props.resetPassword(email)
  }

  render = (): React.ReactNode => {
    const { classes, auth } = this.props
    const { resettingPassword } = auth
    const { email, isEmpty } = this.state

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Paper className={classes.paper}>
          <Typography align="center" color="textPrimary" variant="h5">
            パスワードのリセット
          </Typography>

          <Typography align="center" color="textPrimary" variant="subtitle1">
            登録されたメールアドレスに
            <br />
            パスワードリセット用のメールを送信します。
          </Typography>

          <form className={classes.form} noValidate onSubmit={e => this.onSubmit(e)}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="メールアドレス"
              type="email"
              value={email}
              onChange={({ target }) =>
                this.setState({
                  email: target.value,
                  isEmpty: target.value === '',
                })
              }
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isEmpty || resettingPassword}>
              送信
            </Button>
            {resettingPassword && (
              <Typography align="center" color="secondary" component="p">
                メールを送信しました。
              </Typography>
            )}
          </form>
        </Paper>
      </Container>
    )
  }
}

export default withStyles(styles)(ResetPassword)
