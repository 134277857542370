import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import Terms from 'redux/models/terms'
import PrivacyPolicy from 'redux/models/privacyPolicy'

// Actions
export const LOAD_TERMS = 'kuchiyomi/application/LOAD_TERMS'
export const SET_TERMS = 'kuchiyomi/application/SET_TERMS'
export const LOAD_PRIVACY_POLICY = 'kuchiyomi/application/LOAD_PRIVACY_POLICY'
export const SET_PRIVACY_POLICY = 'kuchiyomi/application/SET_PRIVACY_POLICY'

// Action Creators
const actionCreator = actionCreatorFactory()

export const actions = {
  loadTerms: actionCreator(LOAD_TERMS),
  setTerms: actionCreator<Terms>(SET_TERMS),
  loadPrivacyPolicy: actionCreator(LOAD_PRIVACY_POLICY),
  setPrivacyPolicy: actionCreator<PrivacyPolicy>(SET_PRIVACY_POLICY),
}

// Reducer
export interface ApplicationState {
  terms: Terms | null
  privacyPolicy: PrivacyPolicy | null
}

const initialState: () => ApplicationState = () => ({
  terms: null,
  privacyPolicy: null,
})

export default reducerWithInitialState(initialState())
  .case(actions.setTerms, (state, terms) => ({
    ...state,
    terms,
  }))
  .case(actions.setPrivacyPolicy, (state, privacyPolicy) => ({
    ...state,
    privacyPolicy,
  }))
