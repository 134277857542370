import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  Container,
  CssBaseline,
  Typography,
  WithStyles,
  withStyles,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Toolbar,
  Button,
  Grid,
  Tooltip,
  Chip,
  CircularProgress,
} from '@material-ui/core'

import { WordListActions, WordListStoreStates } from '.'
import styles from './styles'

type OwnProps = RouteComponentProps & WithStyles<typeof styles>

type WordListProps = OwnProps & WordListStoreStates & WordListActions

class WordList extends React.Component<WordListProps> {
  constructor(props: WordListProps) {
    super(props)

    // 発話ワードリストの読み込み
    const { list } = props.readingWords
    if (!list.loading && !list.loaded) props.loadList()
  }

  onInput(file: File | null | undefined) {
    if (!file) return
    this.props.addList(file)
  }

  render = (): React.ReactNode => {
    const { history, classes, readingWords } = this.props
    const { list, add } = readingWords

    if (list.loading || !list.loaded) return <></>

    return (
      <Container>
        <CssBaseline />

        <Paper className={classes.paper}>
          <Toolbar>
            <Grid item xs>
              <Typography className={classes.title} variant="h6">
                発話リスト一覧
              </Typography>
            </Grid>
            {add.isSucceeded && (
              <Typography color="primary" variant="subtitle1">
                新規リストを追加しました
              </Typography>
            )}
            {add.isSucceeded === false && (
              <Typography color="secondary" variant="subtitle1">
                エラーが発生しました
              </Typography>
            )}
            <Tooltip title="CSVファイルから新規リストを作成します">
              <Button
                variant="contained"
                component="label"
                color="primary"
                disabled={add.processing}>
                {add.processing ? <CircularProgress size={20} /> : 'リストを追加'}
                <input
                  type="file"
                  accept=".csv"
                  style={{ display: 'none' }}
                  onChange={e => this.onInput(e.target.files?.item(0))}
                />
              </Button>
            </Tooltip>
          </Toolbar>
          <TableContainer>
            <Table aria-label="word list">
              <TableHead>
                <TableRow>
                  <TableCell>タイトル</TableCell>
                  <TableCell align="right">最終更新日時</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.items.map(row => (
                  <TableRow
                    key={row.id}
                    hover
                    tabIndex={-1}
                    onClick={() => {
                      history.push(`/lip-reading/words/${row.id}`)
                    }}>
                    <TableCell component="th" scope="row">
                      {row.title}
                      {row.isDefault && (
                        <Chip size="small" label="Default" className={classes.chipDefault} />
                      )}
                      {row.canPredict && (
                        <Chip size="small" label="デモ" className={classes.chipDemo} />
                      )}
                    </TableCell>
                    <TableCell align="right">{row.updatedDate}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Container>
    )
  }
}

export default withStyles(styles)(WordList)
