import React, { useEffect } from 'react'
import { Typography, WithStyles, withStyles, Grid } from '@material-ui/core'

import { PublicTemplate } from 'components'

import { PrivacyPolicyActions, PrivacyPolicyStoreStates } from '.'
import styles from './styles'

type PrivacyPolicyScreenProps = WithStyles<typeof styles> &
  PrivacyPolicyActions &
  PrivacyPolicyStoreStates

function PrivacyPolicy(props: PrivacyPolicyScreenProps) {
  const { classes, application, load } = props
  const { privacyPolicy } = application

  // mounted actions
  useEffect(() => {
    load()
  }, [load])

  if (!privacyPolicy) {
    return <></>
  }

  return (
    <PublicTemplate>
      <Grid container direction="column" spacing={1} className={classes.container}>
        <Grid item>
          <Typography variant="h5" align="center" className={classes.title}>
            プライバシーポリシー
          </Typography>
        </Grid>
        <Grid item>
          <ol className={classes.list}>
            {privacyPolicy.items.map((item, index) => (
              <li key={index}>
                {item.title}
                <Typography variant="body2" component="p" className={classes.listItem}>
                  {item.content.replace('@', '【アット】')}
                </Typography>
              </li>
            ))}
          </ol>
        </Grid>
        <Grid item>
          <Typography variant="h6" component="p">
            {privacyPolicy.dateStr}
          </Typography>
        </Grid>
      </Grid>
    </PublicTemplate>
  )
}

export default withStyles(styles)(PrivacyPolicy)
