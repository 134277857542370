import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Typography, WithStyles, withStyles, Grid, Button } from '@material-ui/core'

import { LandingStoreStates } from '.'
import styles from './styles'
import { PublicTemplate } from 'components'

type OwnProps = RouteComponentProps & WithStyles<typeof styles>

type LandingProps = OwnProps & LandingStoreStates

class Landing extends React.Component<LandingProps> {
  render = (): React.ReactNode => {
    const { history, classes, auth } = this.props
    const isLoggedIn = auth.user != null

    return (
      <PublicTemplate showLinks>
        <Grid container direction="column" alignItems="center" spacing={5}>
          <Grid item className={classes.logoContainer}>
            <img className={classes.logo} src="/images/logo_large.png" alt="クチヨミ" />
          </Grid>
          <Grid item>
            <Typography variant="h6" align="center" className={classes.text}>
              国立大学法人九州工業大学 齊藤剛史研究室が提供する読唇技術に関するシステムです。
            </Typography>
            <Typography variant="h6" align="center" className={classes.text}>
              読唇技術の性能評価・性能向上のために発話シーンを投稿するサービスおよび読唇技術のデモを体験するサービスを含みます。
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="large"
              className={classes.button}
              onClick={() => history.push(isLoggedIn ? '/notifications' : '/login')}>
              {isLoggedIn ? 'ホームへ' : '使ってみる'}
            </Button>
          </Grid>
        </Grid>
      </PublicTemplate>
    )
  }
}

export default withStyles(styles)(Landing)
