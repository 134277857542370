import { createStyles, Theme } from '@material-ui/core'

export default (theme: Theme) =>
  createStyles({
    paper: {
      marginBottom: theme.spacing(2),
      padding: theme.spacing(1),
    },
    listTitle: {
      fontWeight: 'bold',
      maxWidth: 600,
      marginTop: theme.spacing(2),
    },
    list: {
      maxWidth: 600,
    },
    button: {
      margin: theme.spacing(2),
    },
    warning: {
      marginTop: theme.spacing(2),
    },
    image: {
      marginLeft: '5%',
      maxWidth: '90%',
    },
    wordList: {
      maxWidth: 600,
      padding: theme.spacing(2),
      paddingTop: 0,
    },
    audio: {
      height: 20,
      maxWidth: '100%',
    },
    audioMargin: {
      height: 20,
      maxWidth: '100%',
      marginTop: 10,
    },
    resultImage: {
      maxHeight: 300,
      objectFit: 'contain',
    },
  })
