import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { actions as inquiryActions, InquiryState, SendInquiryPayload } from 'redux/modules/inquiry'
import Inquiry from './Inquiry'

export interface InquiryActions {
  sendInquiry: (payload: SendInquiryPayload) => Action<SendInquiryPayload>
}

export interface InquiryStoreStates {
  inquiry: InquiryState
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): InquiryActions {
  return {
    sendInquiry: payload => dispatch(inquiryActions.sendInquiry(payload)),
  }
}

function mapStateToProps(appState: AppState): InquiryStoreStates {
  return { inquiry: appState.inquiry }
}

export default connect(mapStateToProps, mapDispatchToProps)(Inquiry)
