import {
  Store as rStore,
  createStore as _createStore,
  combineReducers,
  applyMiddleware,
} from 'redux'
import createSagaMiddleware from 'redux-saga'

import { ApplicationState } from 'redux/modules/application'
import { AuthState } from 'redux/modules/auth'
import { InquiryState } from 'redux/modules/inquiry'
import { MoviesState } from './modules/movies'
import { NotificationsState } from './modules/notifications'
import { ReadingWordsState } from './modules/readingWords'
import { UsersState } from './modules/users'
import sagas from 'redux/sagas'
import reducers from 'redux/modules'

export type AppState = {
  application: ApplicationState
  auth: AuthState
  inquiry: InquiryState
  movies: MoviesState
  notifications: NotificationsState
  readingWords: ReadingWordsState
  users: UsersState
}

export type Store = rStore<AppState>

const saga = createSagaMiddleware()
const middlewares = [saga]
const createStore = applyMiddleware(...middlewares)(_createStore)
const store = createStore(combineReducers<AppState>(reducers))

saga.run(sagas)

export default store
