import React from 'react'
import clsx from 'clsx'
import { RouteComponentProps } from 'react-router-dom'
import {
  Container,
  CssBaseline,
  Typography,
  WithStyles,
  withStyles,
  Paper,
  Button,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Hidden,
} from '@material-ui/core'
import * as Device from 'react-device-detect'

import images from 'utils/images'
import VideoRecorder from 'components/VideoRecorder'
import { DemonstrationActions, DemonstrationStoreStates } from '.'
import styles from './styles'

type OwnProps = RouteComponentProps & WithStyles<typeof styles>

type DemonstrationProps = OwnProps & DemonstrationStoreStates & DemonstrationActions

type DemonstrationStates = {
  recording: boolean
  converting: boolean
  isUploaded: boolean
}

class Demonstration extends React.Component<DemonstrationProps, DemonstrationStates> {
  constructor(props: DemonstrationProps) {
    super(props)
    this.state = {
      recording: false,
      converting: false,
      isUploaded: false,
    }

    // ワードの読み込み
    props.loadWords()
  }

  componentDidUpdate(prevProps: DemonstrationProps) {
    if (prevProps.movies.demonstration.uploading === this.props.movies.demonstration.uploading)
      return

    setTimeout(() => {
      this.setState({
        isUploaded:
          prevProps.movies.demonstration.uploading && !this.props.movies.demonstration.uploading,
      })
    }, 200)
  }

  async contributeFile(data: File | Blob | null | undefined) {
    if (!data) return
    this.props.upload(data)
  }

  render = (): React.ReactNode => {
    const { recording, converting, isUploaded } = this.state
    const { classes, movies, readingWords } = this.props
    const { items, loading } = readingWords.demonstration
    const { uploading, result } = movies.demonstration

    const isBrowser = Device.isBrowser && !Device.isIOS13 // iPadOS 対策
    const isMobile = Device.isMobile || Device.isIOS13 // iPadOS 対策

    const cols = isMobile ? 1 : 5
    const listItems = Array(Math.ceil(items.length / cols))
      .fill(0)
      .map((_, i) => items.slice(cols * i, cols * (i + 1)))

    return (
      <Container>
        <CssBaseline />

        <Paper className={classes.paper}>
          <Grid container direction="column" alignItems="center">
            <Typography align="left" className={classes.listTitle}>
              はじめに
            </Typography>
            <Grid item>
              <ul className={classes.list}>
                <li>本デモは日本語25単語を対象とした単語読唇技術のデモソフトウェアです。</li>
                <li>
                  発話シーンのビデオファイルをアップロードしていただきます。ネットワークの通信速度によって、ビデオファイルをアップロードしてから結果が表示されるまで10秒以上かかることがあります。
                </li>
                <li>
                  アップロードできるビデオのファイル容量は最大8MBです。これ以上の容量のファイルをアップロードすることはできません。
                </li>
                <li>
                  ビデオファイルの再生時間が1秒未満あるいは10秒以上の場合はエラーメッセージが表示されます。
                </li>
                <li>顔が検出できない場合はエラーメッセージが表示されます。</li>
                <li>
                  学習データの多くは若年者です。子供や高齢者の方は誤認識になりやすいです。ご了承ください。
                </li>
                <li>
                  アップロードしていただいたビデオファイル、利用時間および認識結果（利用者データと定義します）は、本研究室が管理しているサーバに記録されます。
                </li>
                <li>
                  利用者データは読唇技術の性能向上・改良の目的のために様々な形で利用させていただきます。
                </li>
                <li>
                  本技術は、第11回バイオメトリクスと認識・認証シンポジウム（SBRA2021）で発表した内容に基づいていますが、予告なしに変更する場合があります。大幅な変更に関しては、本サイトで明記します。
                </li>
              </ul>
            </Grid>
          </Grid>
          <Grid container direction="column" alignItems="center">
            <Typography align="left" className={classes.listTitle}>
              認識対象
            </Typography>
            {!loading && listItems.length === 0 && (
              <Typography>現在認識できるワードはありません</Typography>
            )}
            {listItems.length > 0 && (
              <TableContainer className={classes.wordList}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      {Array(listItems[0].length)
                        .fill(0)
                        .map((_, i) => (
                          <TableCell key={i}></TableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listItems.map((words, index) => (
                      <TableRow key={index} tabIndex={-1}>
                        {words.map(word => (
                          <TableCell key={word.id}>{word.text}</TableCell>
                        ))}
                        {Array(5 - words.length)
                          .fill(0)
                          .map((_, index) => (
                            <TableCell key={index}></TableCell>
                          ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
          <Grid container direction="column" alignItems="center">
            <Typography align="left" className={classes.listTitle}>
              試す
            </Typography>
            {isBrowser && !recording && (
              <Grid container item direction="row" className={classes.list} justify="center">
                <Grid container item xs justify="flex-end">
                  <Button
                    variant="outlined"
                    component="label"
                    color="primary"
                    disabled={uploading || converting}
                    onClick={() => this.setState({ recording: true })}
                    className={classes.button}>
                    動画像を撮影する
                  </Button>
                </Grid>
                <Grid container item xs justify="flex-start">
                  <Button
                    variant="outlined"
                    component="label"
                    color="primary"
                    disabled={uploading || converting}
                    className={classes.button}>
                    ファイルから選択する
                    <input
                      type="file"
                      accept="video/*"
                      style={{ display: 'none' }}
                      onChange={async e => {
                        this.contributeFile(e.target.files?.item(0))
                      }}
                    />
                  </Button>
                </Grid>
              </Grid>
            )}
            {isBrowser && recording && (
              <VideoRecorder
                display={recording}
                onPost={data => {
                  this.setState({ recording: false })
                  this.contributeFile(data)
                }}
              />
            )}
            {isMobile && (
              <Grid item>
                <Button
                  variant="outlined"
                  component="label"
                  color="primary"
                  disabled={uploading || converting}
                  className={classes.button}>
                  アップロードする
                  <input
                    type="file"
                    accept="video/*"
                    style={{ display: 'none' }}
                    onChange={async e => this.contributeFile(e.target.files?.item(0))}
                  />
                </Button>
              </Grid>
            )}
            {converting && (
              <Grid item>
                <Typography color="primary">変換中...</Typography>
              </Grid>
            )}
            {uploading && (
              <Grid item>
                <Typography color="primary">アップロード中...</Typography>
              </Grid>
            )}
            {isUploaded && result && result.error && (
              <Grid item>
                <Typography color="secondary">{result.error}</Typography>
              </Grid>
            )}
            {isUploaded && result && result.results && (
              <Grid item>
                <Typography align="center" className={classes.listTitle}>
                  認識結果
                </Typography>
                <TableContainer className={classes.wordList}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <Hidden mdUp>
                          <TableCell>ワード</TableCell>
                        </Hidden>
                        <Hidden smDown>
                          <TableCell>ワード</TableCell>
                          <TableCell>精度</TableCell>
                          <TableCell>音声</TableCell>
                        </Hidden>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {result.results.map((item, index) => (
                        <TableRow key={index} tabIndex={-1}>
                          <Hidden mdUp>
                            <TableCell>
                              {item.word} ({item.accuracy * 100} %)
                              <audio
                                className={clsx(classes.audio, classes.audioMargin)}
                                controls
                                controlsList="nodownload"
                                src={item.sound}
                              />
                            </TableCell>
                          </Hidden>
                          <Hidden smDown>
                            <TableCell>{item.word}</TableCell>
                            <TableCell>{item.accuracy * 100} %</TableCell>
                            <TableCell>
                              <audio
                                className={classes.audio}
                                controls
                                controlsList="nodownload"
                                src={item.sound}
                              />
                            </TableCell>
                          </Hidden>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Grid container alignItems="center" alignContent="center" justify="center">
                  <img src={result.image} alt="result" className={classes.resultImage} />
                </Grid>
              </Grid>
            )}
            <Typography align="left" className={classes.listTitle}>
              使い方
            </Typography>
            <Grid item container direction="column" alignItems="center" className={classes.warning}>
              <Typography>以下の撮影例を参考にしてください。</Typography>
              <Grid item>
                <img src={images.contributionSample} alt="sample" className={classes.image} />
              </Grid>
            </Grid>
            <Grid item>
              <ul className={classes.list}>
                <li>撮影中はカメラを動かさないでください。</li>
                <li>録画開始になってから口を動かし始めてください。</li>
                <li>発話開始の前後は口を閉じてください。</li>
                <li>顔は画面中央に位置するようにして発話してください。</li>
                <li>顔は画面中央を向いて発話してください。</li>
                <li>上の言葉を自然な発話速度・口の動きで発話してください。</li>
                <li>
                  早すぎる発話／ゆっくりすぎる発話、口を大きく開けすぎる発話／口の開きが小さい発話は誤認識の原因になります。
                </li>
              </ul>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    )
  }
}

export default withStyles(styles)(Demonstration)
