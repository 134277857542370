import moment from 'moment'
import { formatDate } from 'utils/moment'

export interface PrivacyPolicyItem {
  title: string
  content: string
}

export default class PrivacyPolicy {
  id: string
  date: moment.Moment
  items: PrivacyPolicyItem[]

  get dateStr(): string {
    return formatDate(this.date)
  }

  constructor(id: string, date: moment.Moment, items: PrivacyPolicyItem[]) {
    this.id = id
    this.date = date
    this.items = items
  }

  static load(id: string, data: any): PrivacyPolicy | null {
    const { date, items } = data || {}

    if (!date || typeof date !== 'number') {
      return null
    }
    const momentDate = moment.unix(date)

    if (!items || typeof items !== 'object') {
      return null
    }

    return new PrivacyPolicy(id, momentDate, items)
  }
}
