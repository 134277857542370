import { createStyles, Theme } from '@material-ui/core'

export default (theme: Theme) =>
  createStyles({
    paper: {
      marginBottom: theme.spacing(2),
      backgroundColor: theme.palette.grey[50],
    },
    date: {
      width: 200,
    },
    title: {
      fontWeight: 'bold',
    },
  })
