import React from 'react'
import { WithStyles, withStyles, TextField, Button, Theme, createStyles } from '@material-ui/core'

const styles = (theme: Theme) =>
  createStyles({
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  })

export type FormInputs = {
  title: string
  content: string
}

type FormProps = WithStyles<typeof styles> &
  FormInputs & {
    submitTitle: string
    onSubmit: (inputs: FormInputs) => void
  }

function Form(props: FormProps) {
  const [title, setTitle] = React.useState<string>(props.title)
  const [content, setContent] = React.useState<string>(props.content)

  const { classes, submitTitle } = props
  const isValid = ![title, content].includes('')

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    props.onSubmit({ title, content })
  }

  return (
    <form className={classes.form} noValidate onSubmit={e => onSubmit(e)}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        label="タイトル"
        type="text"
        autoFocus
        value={title}
        onChange={({ target }) => setTitle(target.value)}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        multiline
        rowsMax="20"
        rows="5"
        label="内容"
        type="text"
        value={content}
        onChange={({ target }) => setContent(target.value)}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={!isValid}>
        {submitTitle}
      </Button>
    </form>
  )
}

export default withStyles(styles)(Form)
