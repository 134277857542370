import React from 'react'
import { RouteComponentProps, Redirect, withRouter } from 'react-router-dom'
import {
  Container,
  CssBaseline,
  WithStyles,
  withStyles,
  Paper,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core'

import { UserType } from 'redux/models/user'
import { EditActions, EditStoreStates } from '.'
import styles from './styles'
import Form, { FormInputs } from '../Form'

type OwnProps = RouteComponentProps<{ notificationId: string }> & WithStyles<typeof styles>

type EditProps = OwnProps & EditStoreStates & EditActions

type EditStates = {
  isOpenDialog: boolean
}

class Edit extends React.Component<EditProps, EditStates> {
  constructor(props: EditProps) {
    super(props)

    this.state = { isOpenDialog: false }

    const { match, loadItem } = props

    // お知らせの読み込み
    loadItem(match.params.notificationId)
  }

  onSubmit(inputs: FormInputs) {
    const { history, updateItem } = this.props
    updateItem(inputs)
    history.goBack()
  }

  onDelete() {
    const { history, deleteItem } = this.props
    deleteItem()
    history.goBack()
  }

  render = (): React.ReactNode => {
    const { isOpenDialog } = this.state
    const { classes, auth, notifications } = this.props
    const { loading, loaded, item } = notifications.edit

    // 読み込みが終わるまで待機
    if (loading || !loaded) return <></>

    // 権限なしはリダイレクト
    if (!auth.user || auth.user.type === UserType.User) return <Redirect to="/notifications" />

    // お知らせが存在しなければリダイレクト
    if (!item) return <Redirect to="/notifications" />

    return (
      <Container>
        <CssBaseline />

        <Paper className={classes.paper}>
          <Typography variant="h4">「{item.title}」の編集</Typography>

          <Form
            submitTitle="更新"
            title={item.title}
            content={item.content}
            onSubmit={inputs => this.onSubmit(inputs)}
          />
        </Paper>

        <Paper className={classes.paper}>
          <Button
            fullWidth
            variant="contained"
            className={classes.deleteButton}
            onClick={() => this.setState({ isOpenDialog: true })}>
            お知らせを削除
          </Button>
          <Dialog
            open={isOpenDialog}
            onClose={() => this.setState({ isOpenDialog: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{'確認'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                このお知らせを削除しますか？
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({ isOpenDialog: false })} color="primary">
                キャンセル
              </Button>
              <Button onClick={() => this.onDelete()} className={classes.deleteButton}>
                削除
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Container>
    )
  }
}

export default withRouter(withStyles(styles)(Edit))
