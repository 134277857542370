import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { actions as authActions, AuthState } from 'redux/modules/auth'
import ResetPassword from './ResetPassword'

export interface ResetPasswordActions {
  resetPassword: (email: string) => Action<string>
}

export interface ResetPasswordStoreStates {
  auth: AuthState
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): ResetPasswordActions {
  return {
    resetPassword: email => dispatch(authActions.resetPassword(email)),
  }
}

function mapStateToProps(appState: AppState): ResetPasswordStoreStates {
  return { auth: appState.auth }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
