interface Result {
  word: string
  accuracy: number
  sound: string
}

export default class ReadingResult {
  status: number
  error?: string
  results?: Result[]
  image?: string

  constructor(status: number, error?: string, results?: Result[], image?: string) {
    this.status = status
    this.error = error
    this.results = results
    this.image = image
  }

  static makeError(status: number, error: string): ReadingResult {
    return new ReadingResult(status, error)
  }
}
