import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { actions as applicationActions, ApplicationState } from 'redux/modules/application'
import Screen from './Terms'

export interface TermsActions {
  load: () => Action<void>
}

export interface TermsStoreStates {
  application: ApplicationState
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): TermsActions {
  return {
    load: () => dispatch(applicationActions.loadTerms()),
  }
}

function mapStateToProps(appState: AppState): TermsStoreStates {
  return { application: appState.application }
}

export default connect(mapStateToProps, mapDispatchToProps)(Screen)
