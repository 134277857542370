import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import User from 'redux/models/user'

// Actions
export const LOAD = 'lip-reading/auth/LOAD'
export const SYNC_AUTH = 'lip-reading/auth/SYNC_AUTH'
export const LOGIN = 'lip-reading/auth/LOGIN'
export const LOGIN_SUCCESS = 'lip-reading/auth/LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'lip-reading/auth/LOGIN_FAILURE'
export const LOGOUT = 'lip-reading/auth/LOGOUT'
export const LOGOUT_SUCCESS = 'lip-reading/auth/LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'lip-reading/auth/LOGOUT_FAILURE'
export const SIGNUP = 'lip-reading/auth/SIGNUP'
export const SIGNUP_SUCCESS = 'lip-reading/auth/SIGNUP_SUCCESS'
export const SIGNUP_FAILURE = 'lip-reading/auth/SIGNUP_FAILURE'
export const SEND_VARIFICATION_EMAIL = 'lip-reading/auth/SEND_VARIFICATION_EMAIL'
export const SENT_VARIFICATION_EMAIL = 'lip-reading/auth/SENT_VARIFICATION_EMAIL'
export const CHANGE_EMAIL = 'lip-reading/auth/CHANGE_EMAIL'
export const CHANGE_EMAIL_SUCCESS = 'lip-reading/auth/CHANGE_EMAIL_SUCCESS'
export const CHANGE_EMAIL_FAILURE = 'lip-reading/auth/CHANGE_EMAIL_FAILURE'
export const CHANGE_PASSWORD = 'lip-reading/auth/CHANGE_PASSWORD'
export const CHANGE_PASSWORD_SUCCESS = 'lip-reading/auth/CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE = 'lip-reading/auth/CHANGE_PASSWORD_FAILURE'
export const RESET_PASSWORD = 'lip-reading/auth/RESET_PASSWORD'
export const RESET_PASSWORD_COMPLETION = 'lip-reading/auth/RESET_PASSWORD_COMPLETION'
export const DELETE_ACCOUNT = 'lip-reading/auth/DELETE_ACCOUNT'
export const DELETE_ACCOUNT_COMPLETION = 'lip-reading/auth/DELETE_ACCOUNT_COMPLETION'

// Action Creators
const actionCreator = actionCreatorFactory()

export interface LogInPayload {
  email: string
  password: string
}

export interface SignUpPayload {
  name: string
  email: string
  password: string
}

export interface ChangeEmailPayload {
  currentPassword: string
  newEmail: string
}

export interface ChangePasswordPayload {
  currentPassword: string
  newPassword: string
}

export const actions = {
  load: actionCreator(LOAD),
  syncAuth: actionCreator<User | null>(SYNC_AUTH),
  logIn: actionCreator<LogInPayload>(LOGIN),
  succeededToLogIn: actionCreator<User>(LOGIN_SUCCESS),
  failedToLogIn: actionCreator<string>(LOGIN_FAILURE),
  logOut: actionCreator<void>(LOGOUT),
  succeededToLogOut: actionCreator<void>(LOGOUT_SUCCESS),
  failedToLogOut: actionCreator<string>(LOGOUT_FAILURE),
  signUp: actionCreator<SignUpPayload>(SIGNUP),
  succeededToSignUp: actionCreator<User>(SIGNUP_SUCCESS),
  failedToSignUp: actionCreator<string>(SIGNUP_FAILURE),
  sendVerificationEmail: actionCreator<void>(SEND_VARIFICATION_EMAIL),
  sentVerificationEmail: actionCreator<void>(SENT_VARIFICATION_EMAIL),
  changeEmail: actionCreator<ChangeEmailPayload>(CHANGE_EMAIL),
  succeededToChangeEmail: actionCreator<void>(CHANGE_EMAIL_SUCCESS),
  failedToChangeEmail: actionCreator<string>(CHANGE_EMAIL_FAILURE),
  changePassword: actionCreator<ChangePasswordPayload>(CHANGE_PASSWORD),
  succeededToChangePassword: actionCreator<void>(CHANGE_PASSWORD_SUCCESS),
  failedToChangePassword: actionCreator<string>(CHANGE_PASSWORD_FAILURE),
  resetPassword: actionCreator<string>(RESET_PASSWORD),
  completedToResetPassword: actionCreator(RESET_PASSWORD_COMPLETION),
  deleteAccount: actionCreator<string>(DELETE_ACCOUNT),
  completedToDeleteAccount: actionCreator<string | null>(DELETE_ACCOUNT_COMPLETION),
}

// Reducer
export interface AuthState {
  user: User | null
  loading: boolean
  loaded: boolean
  loggingIn: boolean
  logInError: string | null
  loggingOut: boolean
  logOutError: string | null
  signingUp: boolean
  signUpError: string | null
  sendingEmail: boolean
  changingEmail: boolean
  changeEmailError: string | null
  changingPassword: boolean
  changePasswordError: string | null
  resettingPassword: boolean
  accountDeleting: boolean
  deleteAccountError: string | null
}

const initialState: () => AuthState = () => ({
  user: null,
  loading: false,
  loaded: false,
  loggingIn: false,
  logInError: null,
  loggingOut: false,
  logOutError: null,
  signingUp: false,
  signUpError: null,
  sendingEmail: false,
  changingEmail: false,
  changeEmailError: null,
  changingPassword: false,
  changePasswordError: null,
  resettingPassword: false,
  accountDeleting: false,
  deleteAccountError: null,
})

export default reducerWithInitialState(initialState())
  .case(actions.load, (state) => ({
    ...state,
    loading: true,
  }))
  .case(actions.syncAuth, (state, user) => ({
    ...state,
    loading: false,
    loaded: true,
    user,
  }))
  .case(actions.logIn, (state) => ({
    ...state,
    loggingIn: true,
  }))
  .case(actions.succeededToLogIn, (state, user) => ({
    ...state,
    loggingIn: false,
    user,
  }))
  .case(actions.failedToLogIn, (state, error) => ({
    ...state,
    loggingIn: false,
    logInError: error,
  }))
  .case(actions.logOut, (state) => ({
    ...state,
    loggingOut: true,
  }))
  .case(actions.succeededToLogOut, (state) => ({
    ...state,
    loggingOut: false,
    user: null,
  }))
  .case(actions.failedToLogOut, (state, error) => ({
    ...state,
    loggingOut: false,
    logOutError: error,
  }))
  .case(actions.signUp, (state) => ({
    ...state,
    signingUp: true,
    signUpError: null,
  }))
  .case(actions.succeededToSignUp, (state, user) => ({
    ...state,
    signingUp: false,
    signUpError: null,
    user,
  }))
  .case(actions.failedToSignUp, (state, error) => ({
    ...state,
    signingUp: false,
    signUpError: error,
  }))
  .case(actions.sendVerificationEmail, (state) => ({
    ...state,
    sendingEmail: true,
  }))
  .case(actions.sentVerificationEmail, (state) => ({
    ...state,
    sendingEmail: false,
  }))
  .case(actions.changeEmail, (state) => ({
    ...state,
    changingEmail: true,
  }))
  .case(actions.succeededToChangeEmail, (state) => ({
    ...state,
    changingEmail: false,
    changeEmailError: null,
  }))
  .case(actions.failedToChangeEmail, (state, error) => ({
    ...state,
    changingEmail: false,
    changeEmailError: error,
  }))
  .case(actions.changePassword, (state) => ({
    ...state,
    changingPassword: true,
  }))
  .case(actions.succeededToChangePassword, (state) => ({
    ...state,
    changingPassword: false,
    changePasswordError: null,
  }))
  .case(actions.failedToChangePassword, (state, error) => ({
    ...state,
    changingPassword: false,
    changePasswordError: error,
  }))
  .case(actions.resetPassword, (state) => ({
    ...state,
    resettingPassword: true,
  }))
  .case(actions.completedToResetPassword, (state) => ({
    ...state,
    resettingPassword: false,
  }))
  .case(actions.deleteAccount, (state) => ({
    ...state,
    accountDeleting: true,
    deleteAccountError: null,
  }))
  .case(actions.completedToDeleteAccount, (state, error) => ({
    ...state,
    accountDeleting: false,
    deleteAccountError: error,
  }))
