import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import User from 'redux/models/user'
import { AuthState } from 'redux/modules/auth'
import { actions as usersActions, UsersState, UpdateListPayload } from 'redux/modules/users'
import List from './List'

export interface ListActions {
  load: () => Action<void>
  updateList: (payload: UpdateListPayload) => Action<UpdateListPayload>
  deleteUsers: (users: User[]) => Action<User[]>
}

export interface ListStoreStates {
  auth: AuthState
  users: UsersState
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): ListActions {
  return {
    load: () => dispatch(usersActions.load()),
    updateList: payload => dispatch(usersActions.updateList(payload)),
    deleteUsers: users => dispatch(usersActions.deleteUsers(users)),
  }
}

function mapStateToProps(appState: AppState): ListStoreStates {
  return { auth: appState.auth, users: appState.users }
}

export default connect(mapStateToProps, mapDispatchToProps)(List)
