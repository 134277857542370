import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import User from 'redux/models/user'
import {
  actions as usersActions,
  UsersState,
  ChangeUserTypePayload,
  ChangeWordListPayload,
} from 'redux/modules/users'
import { actions as wordsActions, ReadingWordsState } from 'redux/modules/readingWords'
import Detail from './Detail'

export interface DetailActions {
  loadUsers: () => Action<void>
  loadWordLists: () => Action<void>
  changeUserType: (payload: ChangeUserTypePayload) => Action<ChangeUserTypePayload>
  changeWordList: (payload: ChangeWordListPayload) => Action<ChangeWordListPayload>
  deleteUser: (user: User) => Action<User[]>
}

export interface DetailStoreStates {
  readingWords: ReadingWordsState
  users: UsersState
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): DetailActions {
  return {
    loadUsers: () => dispatch(usersActions.load()),
    loadWordLists: () => dispatch(wordsActions.loadList()),
    changeUserType: payload => dispatch(usersActions.changeUserType(payload)),
    changeWordList: payload => dispatch(usersActions.changeWordList(payload)),
    deleteUser: user => dispatch(usersActions.deleteUsers([user])),
  }
}

function mapStateToProps(appState: AppState): DetailStoreStates {
  return {
    readingWords: appState.readingWords,
    users: appState.users,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Detail)
