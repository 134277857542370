import React from 'react'
import {
  People,
  Person,
  Notifications,
  RecordVoiceOver,
  Videocam,
  Movie,
  Description,
  Info,
  ContactSupport,
} from '@material-ui/icons'
import { APP_NAME } from 'utils/constant'

const items = [
  {
    id: 'メインメニュー',
    editorOnly: false,
    children: [
      {
        id: 'ホーム',
        icon: <Notifications />,
        path: '/notifications',
        pattern: /^\/notifications/,
      },
      {
        id: '読唇デモ（日本語25単語）',
        icon: <RecordVoiceOver />,
        path: '/lip-reading/demonstration',
        pattern: /^\/lip-reading\/demonstration$/,
      },
      {
        id: '読唇デモの使い方',
        icon: <RecordVoiceOver />,
        path: '/lip-reading/demonstration/manual',
        pattern: /^\/lip-reading\/demonstration\/manual$/,
      },
      {
        id: '発話シーン投稿',
        icon: <Videocam />,
        path: '/lip-reading/contribution',
        pattern: /^\/lip-reading\/contribution$/,
      },
      { id: 'マイページ', icon: <Person />, path: '/mypage', pattern: /^\/mypage/ },
    ],
  },
  {
    id: 'データ管理',
    editorOnly: true,
    children: [
      { id: 'ユーザー管理', icon: <People />, path: '/users', pattern: /^\/users/ },
      {
        id: '発話シーン管理',
        icon: <Movie />,
        path: '/lip-reading/verification',
        pattern: /^\/lip-reading\/verification/,
      },
      {
        id: '発話リスト管理',
        icon: <Description />,
        path: '/lip-reading/words',
        pattern: /^\/lip-reading\/words/,
      },
    ],
  },
  {
    id: `${APP_NAME}について`,
    editorOnly: false,
    children: [
      { id: '利用規約', icon: <Info />, path: '/terms', pattern: /^\/terms$/ },
      {
        id: 'プライバシーポリシー',
        icon: <Info />,
        path: '/privacy_policy',
        pattern: /^\/privacy_policy$/,
      },
      { id: 'お問い合わせ', icon: <ContactSupport />, path: '/inquiry', pattern: /^\/inquiry$/ },
    ],
  },
]

export default items
