import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { actions as wordsActions, ReadingWordsState } from 'redux/modules/readingWords'
import { actions as moviesActions, MoviesState } from 'redux/modules/movies'
import Demonstration from './Demonstration'

export interface DemonstrationActions {
  loadWords: () => Action<void>
  upload: (file: File | Blob) => Action<File | Blob>
}

export interface DemonstrationStoreStates {
  movies: MoviesState
  readingWords: ReadingWordsState
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): DemonstrationActions {
  return {
    loadWords: () => dispatch(wordsActions.loadDemonstrationWords()),
    upload: file => dispatch(moviesActions.uploadDemonstrationMovie(file)),
  }
}

function mapStateToProps(appState: AppState): DemonstrationStoreStates {
  return {
    movies: appState.movies,
    readingWords: appState.readingWords,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Demonstration)
