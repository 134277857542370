export type ReadingWordField = Omit<ReadingWord, 'id' | 'documentData'>

export default class ReadingWord {
  id: string
  text: string
  weight: number
  index: number
  indexByWeight: number
  isEnabled: boolean

  get documentData(): ReadingWordField {
    return {
      text: this.text,
      weight: this.weight,
      index: this.index,
      indexByWeight: this.indexByWeight,
      isEnabled: this.isEnabled,
    }
  }

  constructor(
    id: string,
    text: string,
    weight: number,
    index: number,
    indexByWeight: number,
    isEnabled: boolean,
  ) {
    this.id = id
    this.text = text
    this.weight = weight
    this.index = index
    this.indexByWeight = indexByWeight
    this.isEnabled = isEnabled
  }

  static load(id: string, data: any): ReadingWord | null {
    const { text, weight, index, indexByWeight, isEnabled } = data

    if (!text || typeof text !== 'string') {
      return null
    }

    if (weight == null || typeof weight !== 'number') {
      return null
    }

    if (index == null || typeof index !== 'number') {
      return null
    }

    if (indexByWeight == null || typeof indexByWeight !== 'number') {
      return null
    }

    if (isEnabled == null || typeof isEnabled !== 'boolean') {
      return null
    }

    return new ReadingWord(id, text, weight, index, indexByWeight, isEnabled)
  }
}
