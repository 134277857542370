import React from 'react'
import { RouteComponentProps, Redirect, withRouter } from 'react-router-dom'
import {
  Container,
  CssBaseline,
  WithStyles,
  withStyles,
  Paper,
  Typography,
} from '@material-ui/core'

import { UserType } from 'redux/models/user'
import { NewActions, NewStoreStates } from '.'
import styles from './styles'
import Form, { FormInputs } from '../Form'

type OwnProps = RouteComponentProps<{ notificationId: string }> & WithStyles<typeof styles>

type NewProps = OwnProps & NewStoreStates & NewActions

class New extends React.Component<NewProps> {
  onSubmit(inputs: FormInputs) {
    const { history, addItem } = this.props
    addItem(inputs)
    history.goBack()
  }

  render = (): React.ReactNode => {
    const { classes, auth } = this.props

    // 権限なしはリダイレクト
    if (!auth.user || auth.user.type === UserType.User) return <Redirect to="/notifications" />

    return (
      <Container>
        <CssBaseline />

        <Paper className={classes.paper}>
          <Typography variant="h4">お知らせの新規作成</Typography>

          <Form submitTitle="作成" title="" content="" onSubmit={inputs => this.onSubmit(inputs)} />
        </Paper>
      </Container>
    )
  }
}

export default withRouter(withStyles(styles)(New))
