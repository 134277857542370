import application from './application'
import auth from './auth'
import inquiry from './inquiry'
import movies from './movies'
import notifications from './notifications'
import readingWords from './readingWords'
import users from './users'

export default {
  application,
  auth,
  inquiry,
  movies,
  notifications,
  readingWords,
  users,
}
