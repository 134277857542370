import { fork } from 'redux-saga/effects'
import { default as application } from './application'
import { default as auth } from './auth'
import { default as inquiry } from './inquiry'
import { default as movies } from './movies'
import { default as notifications } from './notifications'
import { default as readingWords } from './readingWords'
import { default as users } from './users'

export default function*() {
  yield fork(application)
  yield fork(auth)
  yield fork(inquiry)
  yield fork(movies)
  yield fork(notifications)
  yield fork(readingWords)
  yield fork(users)
}
