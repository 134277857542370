import { channel } from 'redux-saga'
import { put, takeEvery, take } from 'redux-saga/effects'
import firebase from 'firebase/app'

import firebaseApp from 'utils/firebaseApp'
import * as Application from 'redux/modules/application'
import PrivacyPolicy from 'redux/models/privacyPolicy'
import Terms from 'redux/models/terms'

const redirectChannel = channel()

const loadTerms = function*() {
  const doc: firebase.firestore.DocumentSnapshot = yield firebaseApp
    .firestore()
    .doc('constants/terms')
    .get()
  const terms = Terms.load(doc.id, doc.data())

  if (terms) {
    yield put(Application.actions.setTerms(terms))
  }
}

const loadPrivacyPolicy = function*() {
  const doc: firebase.firestore.DocumentSnapshot = yield firebaseApp
    .firestore()
    .doc('constants/privacyPolicy')
    .get()
  const privacyPolicy = PrivacyPolicy.load(doc.id, doc.data())

  if (privacyPolicy) {
    yield put(Application.actions.setPrivacyPolicy(privacyPolicy))
  }
}

export default function* dataSaga() {
  yield takeEvery(Application.LOAD_TERMS, loadTerms)
  yield takeEvery(Application.LOAD_PRIVACY_POLICY, loadPrivacyPolicy)

  while (true) {
    const action = yield take(redirectChannel)
    yield put(action)
  }
}
