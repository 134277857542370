import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import Notification from 'redux/models/notification'

// Actions
export const LOAD = 'lip-reading/notifications/LOAD'
export const SYNC_NOTIFICAITONS = 'lip-reading/notifications/SYNC_NOTIFICAITONS'
export const ADD_ITEM = 'lip-reading/notifications/ADD_ITEM'
export const LOAD_EDIT_ITEM = 'lip-reading/notifications/LOAD_EDIT_ITEM'
export const LOAD_EDIT_ITEM_SUCCESS = 'lip-reading/notifications/LOAD_EDIT_ITEM_SUCCESS'
export const LOAD_EDIT_ITEM_FAILURE = 'lip-reading/notifications/LOAD_EDIT_ITEM_FAILURE'
export const UPDATE_ITEM = 'lip-reading/notifications/UPDATE_ITEM'
export const UPDATE_ITEM_COMPLETION = 'lip-reading/notifications/UPDATE_ITEM_COMPLETION'
export const DELETE_ITEM = 'lip-reading/notifications/DELETE_ITEM'
export const DELETE_ITEM_COMPLETION = 'lip-reading/notifications/DELETE_ITEM_COMPLETION'

// Action Creators
const actionCreator = actionCreatorFactory()

export type Payload = {
  title: string
  content: string
}

export const actions = {
  load: actionCreator(LOAD),
  sync: actionCreator<Notification[]>(SYNC_NOTIFICAITONS),
  add: actionCreator<Payload>(ADD_ITEM),
  loadEditItem: actionCreator<string>(LOAD_EDIT_ITEM),
  succeededToLoadEditItem: actionCreator<Notification>(LOAD_EDIT_ITEM_SUCCESS),
  failedToLoadEditItem: actionCreator<void>(LOAD_EDIT_ITEM_FAILURE),
  update: actionCreator<Payload>(UPDATE_ITEM),
  updated: actionCreator<void>(UPDATE_ITEM_COMPLETION),
  delete: actionCreator<void>(DELETE_ITEM),
  deleted: actionCreator<void>(DELETE_ITEM_COMPLETION),
}

// Reducer
export interface NotificationsState {
  loading: boolean
  loaded: boolean
  items: Notification[]
  edit: {
    loading: boolean
    loaded: boolean
    item: Notification | null
  }
}

const initialState: () => NotificationsState = () => ({
  loading: false,
  loaded: false,
  items: [],
  edit: {
    loading: false,
    loaded: false,
    item: null,
  },
})

export default reducerWithInitialState(initialState())
  .case(actions.load, state => ({
    ...state,
    loading: true,
  }))
  .case(actions.sync, (state, items) => ({
    ...state,
    loading: false,
    loaded: true,
    items,
  }))
  .case(actions.loadEditItem, state => ({
    ...state,
    edit: {
      loading: true,
      loaded: false,
      item: null,
    },
  }))
  .case(actions.succeededToLoadEditItem, (state, item) => ({
    ...state,
    edit: {
      loading: false,
      loaded: true,
      item,
    },
  }))
  .case(actions.failedToLoadEditItem, state => ({
    ...state,
    edit: {
      loading: false,
      loaded: true,
      item: null,
    },
  }))
  .case(actions.updated, state => ({
    ...state,
    edit: {
      loading: false,
      loaded: false,
      item: null,
    },
  }))
  .case(actions.deleted, state => ({
    ...state,
    edit: {
      loading: false,
      loaded: false,
      item: null,
    },
  }))
