import React, { useEffect } from 'react'
import { Typography, WithStyles, withStyles, Grid } from '@material-ui/core'

import { PublicTemplate } from 'components'

import { TermsActions, TermsStoreStates } from '.'
import styles from './styles'

type TermsScreenProps = WithStyles<typeof styles> & TermsActions & TermsStoreStates

function Terms(props: TermsScreenProps) {
  const { classes, application, load } = props
  const { terms } = application

  // mounted actions
  useEffect(() => {
    load()
  }, [load])

  if (!terms) {
    return <></>
  }

  return (
    <PublicTemplate>
      <Grid container direction="column" spacing={1} className={classes.container}>
        <Grid item>
          <Typography variant="h3" align="center" className={classes.title}>
            利用規約
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6">{terms.header}</Typography>
        </Grid>
        <Grid item>
          <ol className={classes.list}>
            {terms.items.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ol>
        </Grid>
        <Grid item>
          <Typography variant="h6">{terms.dateStr}</Typography>
        </Grid>
      </Grid>
    </PublicTemplate>
  )
}

export default withStyles(styles)(Terms)
