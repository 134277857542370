import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from 'redux/store'
import { actions as authActions, AuthState } from 'redux/modules/auth'
import AppTemplate from './AppTemplate'

export interface AppTemplateActions {
  logOut: () => Action<void>
  sendVerificationEmail: () => Action<void>
}

export interface AppTemplateStoreStates {
  auth: AuthState
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>): AppTemplateActions {
  return {
    logOut: () => dispatch(authActions.logOut()),
    sendVerificationEmail: () => dispatch(authActions.sendVerificationEmail()),
  }
}

function mapStateToProps(appState: AppState): AppTemplateStoreStates {
  return { auth: appState.auth }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppTemplate)
