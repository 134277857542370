import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import ReadingWordList from 'redux/models/readingWordList'
import ReadingWord from 'redux/models/readingWord'

// Actions
export const LOAD_LIST = 'lip-reading/readingWords/LOAD_LIST'
export const SYNC_WORD_LISTS = 'lip-reading/readingWords/SYNC_WORD_LISTS'
export const LOAD_WORDS = 'lip-reading/readingWords/LOAD_WORDS'
export const SYNC_WORDS = 'lip-reading/readingWords/SYNC_WORDS'
export const LOAD_DEMONSTRATION_WORDS = 'lip-reading/readingWords/LOAD_DEMONSTRATION_WORDS'
export const SYNC_DEMONSTRATION_WORDS = 'lip-reading/readingWords/SYNC_DEMONSTRATION_WORDS'
export const UNLOAD_WORDS = 'lip-reading/readingWords/UNLOAD_WORDS'
export const ADD_LIST = 'lip-reading/readingWords/ADD_LIST'
export const ADD_LIST_SUCCESS = 'lip-reading/readingWords/ADD_LIST_SUCCESS'
export const ADD_LIST_FAILURE = 'lip-reading/readingWords/ADD_LIST_FAILURE'
export const UPDATE_LIST = 'lip-reading/readingWords/UPDATE_LIST'
export const UPDATE_LIST_SUCCESS = 'lip-reading/readingWords/UPDATE_LIST_SUCCESS'
export const UPDATE_LIST_FAILURE = 'lip-reading/readingWords/UPDATE_LIST_FAILURE'
export const SET_DEFAULT_LIST = 'lip-reading/readingWords/SET_DEFAULT_LIST'
export const SET_DEFAULT_LIST_COMPLETION = 'lip-reading/readingWords/SET_DEFAULT_LIST_COMPLETION'
export const SET_DEMO_LIST = 'lip-reading/readingWords/SET_DEMO_LIST'
export const SET_DEMO_LIST_COMPLETION = 'lip-reading/readingWords/SET_DEMO_LIST_COMPLETION'
export const LOAD_CONTRIBUTION_WORD = 'lip-reading/readingWords/LOAD_CONTRIBUTION_WORD'
export const LOAD_CONTRIBUTION_WORD_COMPLETION =
  'lip-reading/readingWords/LOAD_CONTRIBUTION_WORD_COMPLETION'

export type ContributionData = {
  list: ReadingWordList
  index: number
  word: ReadingWord
}

// Action Creators
const actionCreator = actionCreatorFactory()

export type UpdateListPayload = {
  list: ReadingWordList
  file: File
}

export type SyncWordsPayload = {
  list: ReadingWordList
  items: ReadingWord[]
}

export type LoadContributionWordPayload = 'next' | 'previous'

export const actions = {
  loadList: actionCreator(LOAD_LIST),
  syncLists: actionCreator<ReadingWordList[]>(SYNC_WORD_LISTS),
  loadWords: actionCreator<string | null>(LOAD_WORDS),
  syncWords: actionCreator<SyncWordsPayload | null>(SYNC_WORDS),
  loadDemonstrationWords: actionCreator(LOAD_DEMONSTRATION_WORDS),
  syncDemonstrationWords: actionCreator<ReadingWord[]>(SYNC_DEMONSTRATION_WORDS),
  unloadWords: actionCreator(UNLOAD_WORDS),
  addList: actionCreator<File>(ADD_LIST),
  succeededToAddList: actionCreator(ADD_LIST_SUCCESS),
  failedToAddList: actionCreator(ADD_LIST_FAILURE),
  updateList: actionCreator<UpdateListPayload>(UPDATE_LIST),
  succeededToUpdateList: actionCreator(UPDATE_LIST_SUCCESS),
  failedToUpdateList: actionCreator(UPDATE_LIST_FAILURE),
  setDefaultList: actionCreator<ReadingWordList>(SET_DEFAULT_LIST),
  completedToSetDefaultList: actionCreator(SET_DEFAULT_LIST_COMPLETION),
  setDemoList: actionCreator<ReadingWordList>(SET_DEMO_LIST),
  completedToSetDemoList: actionCreator(SET_DEMO_LIST_COMPLETION),
  loadContributionWord: actionCreator<LoadContributionWordPayload>(LOAD_CONTRIBUTION_WORD),
  completedToLoadContributionWord: actionCreator<ContributionData | null>(
    LOAD_CONTRIBUTION_WORD_COMPLETION,
  ),
}

// Reducer
export interface ReadingWordsState {
  list: {
    items: ReadingWordList[]
    loading: boolean
    loaded: boolean
  }
  words: null | {
    list: ReadingWordList | null
    items: ReadingWord[]
    loading: boolean
    isSucceeded: boolean | null
  }
  demonstration: {
    items: ReadingWord[]
    loading: boolean
  }
  add: {
    processing: boolean
    isSucceeded: boolean | null
  }
  update: {
    processing: boolean
    isSucceeded: boolean | null
  }
  contribution: {
    loaded: boolean
    data: null | {
      list: ReadingWordList
      index: number
      word: ReadingWord
    }
  }
  settingToDefaultList: boolean
  settingToDemoList: boolean
}

const initialState: () => ReadingWordsState = () => ({
  list: {
    items: [],
    loading: false,
    loaded: false,
  },
  words: null,
  demonstration: {
    items: [],
    loading: false,
  },
  add: {
    processing: false,
    isSucceeded: null,
  },
  update: {
    processing: false,
    isSucceeded: null,
  },
  contribution: {
    loaded: false,
    data: null,
  },
  settingToDefaultList: false,
  settingToDemoList: false,
})

export default reducerWithInitialState(initialState())
  .case(actions.loadList, state => {
    const list = state.list
    return {
      ...state,
      list: {
        ...list,
        loading: true,
      },
    }
  })
  .case(actions.syncLists, (state, items) => ({
    ...state,
    list: {
      items,
      loading: false,
      loaded: true,
    },
  }))
  .case(actions.loadWords, state => ({
    ...state,
    words: {
      list: null,
      items: [],
      loading: true,
      isSucceeded: null,
    },
  }))
  .case(actions.syncWords, (state, payload) => ({
    ...state,
    words: {
      list: payload?.list || null,
      items: payload?.items || [],
      loading: false,
      isSucceeded: payload != null,
    },
  }))
  .case(actions.loadDemonstrationWords, state => ({
    ...state,
    demonstration: {
      items: [],
      loading: true,
    },
  }))
  .case(actions.syncDemonstrationWords, (state, items) => ({
    ...state,
    demonstration: {
      items: items || [],
      loading: false,
    },
  }))
  .case(actions.unloadWords, state => ({
    ...state,
    words: null,
    update: {
      processing: false,
      isSucceeded: null,
    },
  }))
  .case(actions.addList, state => ({
    ...state,
    add: {
      processing: true,
      isSucceeded: null,
    },
  }))
  .case(actions.succeededToAddList, state => ({
    ...state,
    add: {
      processing: false,
      isSucceeded: true,
    },
  }))
  .case(actions.failedToAddList, state => ({
    ...state,
    add: {
      processing: false,
      isSucceeded: false,
    },
  }))
  .case(actions.updateList, state => ({
    ...state,
    update: {
      processing: true,
      isSucceeded: null,
    },
  }))
  .case(actions.succeededToUpdateList, state => ({
    ...state,
    update: {
      processing: false,
      isSucceeded: true,
    },
  }))
  .case(actions.failedToUpdateList, state => ({
    ...state,
    update: {
      processing: false,
      isSucceeded: false,
    },
  }))
  .case(actions.setDefaultList, state => ({
    ...state,
    settingToDefaultList: true,
  }))
  .case(actions.completedToSetDefaultList, state => ({
    ...state,
    settingToDefaultList: false,
  }))
  .case(actions.setDemoList, state => ({
    ...state,
    settingToDemoList: true,
  }))
  .case(actions.completedToSetDemoList, state => ({
    ...state,
    settingToDemoList: false,
  }))
  .case(actions.loadContributionWord, state => ({
    ...state,
    contribution: {
      ...state.contribution,
      loaded: false,
    },
  }))
  .case(actions.completedToLoadContributionWord, (state, data) => ({
    ...state,
    contribution: {
      loaded: true,
      data: data || state.contribution.data,
    },
  }))
