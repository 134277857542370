import moment from 'moment'
import { formatDate } from 'utils/moment'

export default class Terms {
  id: string
  date: moment.Moment
  header: string
  items: string[]

  get dateStr(): string {
    return formatDate(this.date)
  }

  constructor(id: string, date: moment.Moment, header: string, items: string[]) {
    this.id = id
    this.date = date
    this.header = header
    this.items = items
  }

  static load(id: string, data: any): Terms | null {
    const { date, header, items } = data || {}

    if (!date || typeof date !== 'number') {
      return null
    }
    const momentDate = moment.unix(date)

    if (!header || typeof header !== 'string') {
      return null
    }

    if (!items || typeof items !== 'object') {
      return null
    }

    return new Terms(id, momentDate, header, items)
  }
}
