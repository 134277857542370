import React from 'react'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { Action } from 'typescript-fsa'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import { AppState } from 'redux/store'
import { AuthState, actions } from 'redux/modules/auth'
import User from 'redux/models/user'
import {
  AppTemplate,
  Landing,
  Terms,
  PrivacyPolicy,
  Login,
  Signup,
  LipReadingContribution,
  LipReadingDemonstration,
  LipReadingDemonstrationManual,
  LipReadingVerification,
  LipReadingWordList,
  LipReadingWords,
  MyPageInfo,
  MyPageChangeEmail,
  MyPageChangePassword,
  NotificationsList,
  NotificationsEdit,
  NotificationsNew,
  ResetPassword,
  UsersList,
  UsersDetail,
  Inquiry,
} from 'components/screens'

interface RouterActions {
  load: () => Action<void>
}

interface RouterProps {
  auth: AuthState
}

const Auth = (props: React.PropsWithChildren<{ user: User | null }>) => {
  return props.user != null ? <>{props.children}</> : <Redirect to="/login" />
}

class Router extends React.Component<RouterProps & RouterActions> {
  componentDidMount(): void {
    this.props.load()
  }

  render = (): React.ReactNode => {
    const { user, loading, loaded } = this.props.auth

    // ユーザー読み込み待ち
    if (loading || !loaded) {
      return <div></div>
    }

    return (
      <div>
        <BrowserRouter>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/signup" component={Signup} />
            <Route exact path="/password_reset" component={ResetPassword} />

            <Route exact path="/" component={Landing} />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/privacy_policy" component={PrivacyPolicy} />

            <Auth user={user}>
              <AppTemplate>
                <Switch>
                  <Route exact path="/notifications" component={NotificationsList} />
                  <Route
                    exact
                    path="/lip-reading/demonstration"
                    component={LipReadingDemonstration}
                  />
                  <Route
                    exact
                    path="/lip-reading/demonstration/manual"
                    component={LipReadingDemonstrationManual}
                  />
                  <Route
                    exact
                    path="/lip-reading/contribution"
                    component={LipReadingContribution}
                  />
                  <Route exact path="/mypage" component={MyPageInfo} />
                  <Route exact path="/mypage/changeEmail" component={MyPageChangeEmail} />
                  <Route exact path="/mypage/changePassword" component={MyPageChangePassword} />
                  <Route exact path="/inquiry" component={Inquiry} />

                  {user?.isEditor && (
                    <Switch>
                      <Route exact path="/notifications/new" component={NotificationsNew} />
                      <Route
                        exact
                        path="/notifications/:notificationId/edit"
                        component={NotificationsEdit}
                      />
                      <Route
                        exact
                        path="/lip-reading/verification"
                        component={LipReadingVerification}
                      />
                      <Route exact path="/lip-reading/words" component={LipReadingWordList} />
                      <Route exact path="/lip-reading/words/:listId" component={LipReadingWords} />
                      <Route exact path="/users" component={UsersList} />
                      <Route exact path="/users/:userId" component={UsersDetail} />
                      <Redirect to="/lip-reading/contribution" />
                    </Switch>
                  )}
                  <Redirect to="/notifications" />
                </Switch>
              </AppTemplate>
            </Auth>
          </Switch>
        </BrowserRouter>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>): RouterActions => {
  return {
    load: (): Action<void> => dispatch(actions.load()),
  }
}

const mapStateToProps = (state: AppState): RouterProps => ({
  auth: state.auth,
})

export default connect(mapStateToProps, mapDispatchToProps)(Router)
