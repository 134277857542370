import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import {
  Button,
  Container,
  CssBaseline,
  TextField,
  Typography,
  WithStyles,
  withStyles,
  Paper,
  CircularProgress,
} from '@material-ui/core'

import { ChangeEmailActions, ChangeEmailStoreStates } from '.'
import styles from './styles'

type OwnProps = RouteComponentProps & WithStyles<typeof styles>

type ChangeEmailProps = OwnProps & ChangeEmailStoreStates & ChangeEmailActions

interface ChangeEmailStates {
  currentPassword: string
  newEmail: string
  // validation
  isEmpty: boolean
}

type ChangeEmailItem = Pick<ChangeEmailStates, 'currentPassword' | 'newEmail'>

class ChangeEmail extends React.Component<ChangeEmailProps, ChangeEmailStates> {
  constructor(props: ChangeEmailProps) {
    super(props)
    this.state = {
      currentPassword: '',
      newEmail: '',
      isEmpty: true,
    }
  }

  onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const { newEmail, currentPassword, isEmpty } = this.state

    if (isEmpty) return

    this.props.changeEmail({ newEmail, currentPassword })
  }

  onChangeValue(key: keyof ChangeEmailItem, value: string) {
    const state = { ...this.state }
    state[key] = value
    state.isEmpty = Object.values(state).includes('')
    this.setState(state)
  }

  render = (): React.ReactNode => {
    const { classes, auth } = this.props
    const { changingEmail, changeEmailError, user } = auth
    const { newEmail, currentPassword, isEmpty } = this.state

    if (!user) return <></>

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Paper className={classes.paper}>
          <Typography align="center" color="textPrimary" variant="h5">
            メールアドレスの変更
          </Typography>

          <Typography align="center" color="textSecondary" component="p" variant="subtitle1">
            現在のメールアドレス: {user.email}
          </Typography>

          {changeEmailError && (
            <Typography align="center" color="error" component="h1" variant="h6">
              {changeEmailError}
            </Typography>
          )}

          <form className={classes.form} noValidate onSubmit={e => this.onSubmit(e)}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="現在のパスワード"
              autoComplete="password"
              type="password"
              autoFocus
              value={currentPassword}
              onChange={({ target }) => this.onChangeValue('currentPassword', target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="新しいメールアドレス"
              type="email"
              value={newEmail}
              onChange={({ target }) => this.onChangeValue('newEmail', target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isEmpty || changingEmail}>
              {changingEmail ? <CircularProgress size={20} /> : '変更する'}
            </Button>
          </form>
        </Paper>
      </Container>
    )
  }
}

export default withStyles(styles)(ChangeEmail)
